import React from 'react'
import { Link } from "react-router-dom"
import { companyList } from '../../../../api/requests/company/CompanyRequest'
import ActionBadge from '../../../helpers/ActionBadge'
import LoadingOverlay from 'react-loading-overlay'
function CompanyList(){
    document.title = "Company - List"
    let [loading, setLoading] = React.useState(false);
    const [company, setCompany] = React.useState([])
    React.useEffect(() => {
        const data = async () => {
            setLoading(true)
            const result = await companyList()
            if (result) {
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            }
            setCompany(result.data)
        }
        data()
    }, [])

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Company ... ...'>
                <div style={{minHeight:90+'vh'}}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Company List</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="icon dripicons-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/company/list">
                                                <u>Company List</u>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/company/search">
                                                Company Search
                                            </Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <h5 className="card-header">Company List</h5>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>ADV Code</th>
                                                        <th>Created</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {company ? company.map((data) => (
                                                    <tr key={data._id}>
                                                        <td>{data.company_id}</td>
                                                        <td>{data.company_name}</td>
                                                        <td>{data.adv_code}</td>
                                                        <td>{data.created_on}</td>
                                                        <td>
                                                            {data.deleted == 0 ? (
                                                                <span class="badge badge-pill badge-success">Active</span>
                                                            ) : (
                                                                <span class="badge badge-pill badge-danger">Inactive</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <ActionBadge type="view" page="Company" />
                                                            <ActionBadge type="remove" page="Company" gap="true" />
                                                            <ActionBadge type="edit" page="Company" />
                                                        </td>
                                                    </tr>
                                                )) : 'Alert Not Found'}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default CompanyList