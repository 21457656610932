import React from 'react'
import Modal from 'react-modal'
import { Link } from "react-router-dom"
import { ReadableDateTime } from '../../../../helpers/DateTime'
import LoadingOverlay from 'react-loading-overlay'
import ActionBadge from '../../../helpers/ActionBadge'
import { adminList } from '../../../../api/requests/streaming/StreamingRequest'
document.title = "Streaming - Admin List"
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};
function StreamAdminList(){
    let [loading, setLoading] = React.useState(false);
    const [visible, setVisible] = React.useState(false)
    const [subUserVisible, setSubUserVisible] = React.useState(false)
    const [admin, setAdmin] = React.useState([])
    React.useEffect(() => {
        const data = async () => {
          setLoading(true)
          const result = await adminList()
          if (result) {
            setTimeout(() => {
                setLoading(false)
            }, 800)
          }
          setAdmin(result.data)
        }
        data()
    }, [])

    const childFunc = React.useRef(null)

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Data ... ...'>
                <div style={{minHeight:90+'vh'}}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">
                                    Streaming Admin Id's
                                </h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="icon dripicons-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/streaming/admin-add">
                                                Admin Add
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/streaming/admin-list">
                                                Admin List
                                            </Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <h5 className="card-header">All Admin Id's</h5>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Created</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {admin ? admin.map((data) => (
                                                        <tr key={data._id}>
                                                            <td>{data.admin_id}</td>
                                                            <td>{`${data.first_name} ${data.last_name}`}</td>
                                                            <td>{data.email}</td>
                                                            <td>
                                                                {ReadableDateTime(data.created_at)}
                                                            </td>
                                                            <td>
                                                                {data.status == 1 ?
                                                                    (<span className="badge badge-pill badge-success">Active</span>) :
                                                                    (<span className="badge badge-pill badge-danger">Inactive</span>)
                                                                }
                                                            </td>
                                                            <td>
                                                                <ActionBadge type="view" page="sub-user"/>
                                                                <ActionBadge type="edit" page="sub-user" gap="true"/>
                                                            </td>
                                                        </tr>
                                                    )) : 'IDs Not Found'}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default StreamAdminList
