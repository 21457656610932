import React from 'react'

const Notification = (data) => {
  return (
    <div className={`alert alert-${data.flag} alert-dismissible fade show`} role="alert">
      {data.msg}
    </div>
  )
}

export default Notification
