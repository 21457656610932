import React, {useState, useEffect} from "react"
import { Route, Routes, Navigate, Link } from "react-router-dom"
import Cookies from 'universal-cookie'
import Authapi from "../../context/Authapi"
import ProtectedRoutes from "../../route/ProtectedRoutes"
import LoginComponent from "../auth/Loginpage"
import HomeComponent from  "../admin/home/Homepage"
import CompanyListComponent from  "../admin/company/company-list/CompanyList"
import CompanyAdd from "../admin/company/company-add/CompanyAdd"
import UserAdd from "../admin/user/user-add/UserAdd"
import UserList from "../admin/user/user-list/UserList"
import ChildUserAddForm from "../admin/user/user-child/AddChildUser"
import ChildUser2AddForm from "../admin/user/user-child-2/AddChildUser2"
import EditChildUser from "../admin/user/user-child/EditChildUser"
import UserActivitiesHistory from "../admin/user/user-child/UserActivitiesHistory"
import UserServiceAccess from "../admin/user/user-child/ServiceAccess"
import SubscriptionList from "../admin/subscription/SubscriptionList"
import SubscriberList from "../admin/subscriber/subscriber-list/SubscriberList"
import SubscriberSummary from "../admin/subscriber/subscriber-summary/SubscriberSummary"
import SetSubscriber from "../admin/subscriber/subscriber-set/SetSubscriber"
import AddMoreSubscriber from "../admin/subscriber/add-more-subscriber/AddMoreSubscriber"
import AlertServiceAdd from "../admin/alert-service/alert-service-add/AlertServiceAdd"
import AlertServiceList from "../admin/alert-service/alert-service-list/AlertServiceList"
import InstantAlertAdd from "../admin/alert-service/instant-alert-add/InstantAlertAdd"
import AdminProfile from "../admin/admin/admin-profile/AdminProfile"
import NotificationsComponent from "../admin/notification/NotificationPage"
import BrandList from "../admin/brand/BrandList"
import PermissionAdd from "../admin/permission/permission-add/PermissionAdd";
import PermissionAddAdvanced from "../admin/permission/permission-add/PermissionAddAdvanced";
import PermissionEditAdvanced from "../admin/permission/permission-edit/PermissionEditAdvanced";
import PermissionAdvancedList from "../admin/permission/permission-list/PermissionListAdvanced";
import PermissionListAdvancedAll from "../admin/permission/permission-list/PermissionListAdvancedAll";
import PermissionList from "../admin/permission/permission-list/PermissionList";
import PermissionDetails from "../admin/permission/permission-details/PermissionDetails";
import StreamAdminAdd from "../admin/streaming/stream-admin-add/StreamAdminAdd"
import StreamAdminList from "../admin/streaming/stream-admin-list/StreamAdminList"
import StreamVideoList from "../admin/permission/permission-add/PermissionAdd"
import AlertList from "../admin/inbox/AlertList"
import {clearAdminCookies} from "../../helpers/Cookies"
import BrandListTVC from "../admin/brand/BrandListTVC"
import BrandListRDC from "../admin/brand/BrandListRDC"
import CompanyListTVCComponent from "../admin/company/company-list/CompanyListTVC"
import CompanyListRDCComponent from "../admin/company/company-list/CompanyListRDC"
import CompanyListPrintOnlineComponent from "../admin/company/company-list/CompanyListPrintOnline"
import PermissionEdit from "../admin/permission/permission-edit/PermissionEdit"
import CheckRole from "../../route/CheckRole"
import OtherServiceAdd from "../admin/other-service/other-service-add/OtherServiceAdd"
const cookies = new Cookies();
function ContentComponent(){
	let auth_status;
    const session_data = cookies.get('adata')
	const [adminName, setAdminName] = useState("")
	const [adminEmail, setAdminEmail] = useState("")
    if (session_data) {
        if( session_data.id &&
            session_data.uid &&
            session_data.userName &&
            session_data.email &&
            session_data.token )
        {
            auth_status = true
        } else {
            auth_status = false
        }
    } else {
        auth_status = false
    }
    const [auth, setAuth] = useState(auth_status)
    const authControl = () => setAuth(auth ? true : false)
	useEffect(() => {
		setAdminName(session_data.userName)
		setAdminEmail(session_data.email)
	}, [])

	const define_routes = () => {
		return(
			<Authapi.Provider value={{ auth, authControl }}>
				<Routes>
					<Route path="/" element={auth ? <Navigate to="/home"/> : <LoginComponent/>} />
					<Route path="/" element={<ProtectedRoutes/>} >
						<Route path="home" element={<HomeComponent/>} />
						<Route path="notification" element={<NotificationsComponent/>} />
						<Route path="alert/add" element={<AlertServiceAdd/>} />
						<Route path="alert/multi" element={<SetSubscriber/>} />
						<Route path="alert/list" element={<AlertServiceList/>} />
						<Route path="alert/instant-add" element={<InstantAlertAdd/>} />
						<Route path="alert/other/add" element={<OtherServiceAdd/>} />
						<Route path="/" element={<CheckRole roles={[parseInt(process.env.REACT_APP_ROLE_ADMIN)]} />}>
						<Route path="subscription/list" element={<SubscriptionList/>} />
						<Route path="company/add" element={<CompanyAdd/>} />
						<Route path="company/list" element={<CompanyListComponent/>} />
						<Route path="company/list/tvc" element={<CompanyListTVCComponent/>} />
						<Route path="company/list/rdc" element={<CompanyListRDCComponent/>} />
						<Route path="company/list/print-online" element={<CompanyListPrintOnlineComponent/>} />
						<Route path="company/search" element={<HomeComponent/>} />
						<Route path="user/add" element={<UserAdd/>} />
						<Route path="user/add-sub-user" element={<ChildUserAddForm/>} />
						<Route path="user/add-sub-user-2" element={<ChildUser2AddForm/>} />
						<Route path="user/list" element={<UserList/>} />
						<Route path="/user/sub-user/edit" element={<EditChildUser/>} />
						<Route path="/user/sub-user/activity/:id" element={<UserActivitiesHistory/>} />
						<Route path="/user/sub-user/service-access/:id" element={<UserServiceAccess/>} />
						<Route path="subscriber/set-subscriber" element={<SetSubscriber/>} />
						<Route path="subscriber/list" element={<SubscriberList/>} />
						<Route path="subscriber/summary" element={<SubscriberSummary/>} />
						<Route path="subscriber/add-more-subscriber/:id" element={<AddMoreSubscriber/>} />
						<Route path="permission/add" element={<PermissionAdd/>} />
						<Route path="permission/add/advanced" element={<PermissionAddAdvanced />} />
						<Route path="permission/list/advanced/all" element={<PermissionAdvancedList />} />
						<Route path="permission/list/advanced/all/details/:id" element={<PermissionListAdvancedAll />} />
						<Route path="permission/edit/advanced/:id" element={<PermissionEditAdvanced />} />
						<Route path="permission/list" element={<PermissionList/>} />
						<Route path="permission/access-details/:id" element={<PermissionDetails />} />
						<Route path="permission/list/edit/:id" element={<PermissionEdit />} />
						<Route path="brand/tvc" element={<BrandListTVC />} />
						<Route path="brand/rdc" element={<BrandListRDC />} />
						<Route path="brand/print-online" element={<BrandList />} />
						<Route path="brand/list" element={<BrandList/>} />
						<Route path="admin/profile" element={<AdminProfile/>} />
						<Route path="streaming/admin-add" element={<StreamAdminAdd/>} />
						<Route path="streaming/admin-list" element={<StreamAdminList/>} />
						<Route path="streaming/video-list" element={<AdminProfile/>} />
						<Route path="inbox/alert-list" element={<AlertList/>} />
						</Route>
					</Route>
				</Routes>
			</Authapi.Provider>
		)
	}

	const signout = (e) => {
		e.preventDefault()
		clearAdminCookies('adata')
		window.location.href = "/"
	}

	return(
		<>
			<div className="content-wrapper">

				<nav className="top-toolbar navbar navbar-mobile navbar-tablet">
					<ul className="navbar-nav nav-left">
						<li className="nav-item">
							<a href="#" data-toggle-state="aside-left-open">
								<i className="icon dripicons-align-left"></i>
							</a>
						</li>
					</ul>
					<ul className="navbar-nav nav-center site-logo">
						<li>
							<a href="index-2.html">
								<div className="logo_mobile">
									<svg id="logo_mobile" width="27" height="27" viewBox="0 0 54.03 56.55">
										<defs>
											<linearGradient id="logo_background_mobile_color">
												<stop className="stop1" offset="0%" />
												<stop className="stop2" offset="50%" />
												<stop className="stop3" offset="100%" />
											</linearGradient>
										</defs>
										<path id="logo_path_mobile" className="cls-2"
											d="M90.32,0c14.2-.28,22.78,7.91,26.56,18.24a39.17,39.17,0,0,1,1,4.17l.13,1.5A15.25,15.25,0,0,1,118.1,29v.72l-.51,3.13a30.47,30.47,0,0,1-3.33,8,15.29,15.29,0,0,1-2.5,3.52l.06.07c.57.88,1.43,1.58,2,2.41,1.1,1.49,2.36,2.81,3.46,4.3.41.55,1,1,1.41,1.56.68.92,1.16,1.89.32,3.06-.08.12-.08.24-.19.33a2.39,2.39,0,0,1-2.62.07,4.09,4.09,0,0,1-.7-.91c-.63-.85-1.41-1.61-2-2.48-1-1.42-2.33-2.67-3.39-4.1a16.77,16.77,0,0,1-1.15-1.37c-.11,0-.06,0-.13.07-.41.14-.65.55-1,.78-.72.54-1.49,1.08-2.24,1.56A29.5,29.5,0,0,1,97.81,53c-.83.24-1.6.18-2.5.39a16.68,16.68,0,0,1-3.65.26H90.58L88,53.36A36.87,36.87,0,0,1,82.71,52a27.15,27.15,0,0,1-15.1-14.66c-.47-1.1-.7-2.17-1.09-3.39-1-3-1.45-8.86-.51-12.38a29,29,0,0,1,2.56-7.36c3.56-6,7.41-9.77,14.08-12.57a34.92,34.92,0,0,1,4.8-1.3Zm.13,4.1c-.45.27-1.66.11-2.24.26a32.65,32.65,0,0,0-4.74,1.37A23,23,0,0,0,71,18.7,24,24,0,0,0,71.13,35c2.78,6.66,7.2,11.06,14.21,13.42,1.16.39,2.52.59,3.84.91l1.47.07a7.08,7.08,0,0,0,2.43,0H94c.89-.21,1.9-.28,2.75-.46V48.8A7.6,7.6,0,0,1,95.19,47c-.78-1-1.83-1.92-2.62-3s-1.86-1.84-2.62-2.87c-2-2.7-4.45-5.1-6.66-7.62-.57-.66-1.14-1.32-1.66-2-.22-.29-.59-.51-.77-.85a2.26,2.26,0,0,1,.58-2.61,2.39,2.39,0,0,1,2.24-.2,4.7,4.7,0,0,1,1.22,1.3l.51.46c.5.68,1,1.32,1.6,2,2.07,2.37,4.38,4.62,6.27,7.17.94,1.26,2.19,2.3,3.14,3.58l1,1c.82,1.1,1.8,2,2.62,3.13.26.35.65.6.9,1A23.06,23.06,0,0,0,105,45c.37-.27,1-.51,1.15-1h-.06c-.18-.51-.73-.83-1-1.24-.74-1-1.64-1.88-2.37-2.87-1.8-2.44-3.89-4.6-5.7-7-.61-.82-1.44-1.52-2-2.34-.85-1.16-3.82-3.73-1.54-5.41a2.27,2.27,0,0,1,1.86-.26c.9.37,2.33,2.43,2.94,3.26s1.27,1.31,1.79,2c1.44,1.95,3.11,3.66,4.54,5.6.41.55,1,1,1.41,1.56.66.89,1.46,1.66,2.11,2.54.29.39.61,1.06,1.09,1.24.54-1,1.34-1.84,1.92-2.8a25.69,25.69,0,0,0,2.5-6.32c1.27-4.51.32-10.37-1.15-13.81A22.48,22.48,0,0,0,100.75,5.94a35.12,35.12,0,0,0-6.08-1.69A20.59,20.59,0,0,0,90.45,4.11Z"
											transform="translate(-65.5)" fill="url(#logo_background_mobile_color)" />
									</svg>
								</div>
								<span className="brand-text">Portal Admin</span>
							</a>
						</li>
					</ul>
					<ul className="navbar-nav nav-right">
						<li className="nav-item">
							<a href="#" data-toggle-state="mobile-topbar-toggle">
								<i className="icon dripicons-dots-3 rotate-90"></i>
							</a>
						</li>
					</ul>
				</nav>

				<nav className="top-toolbar navbar navbar-desktop flex-nowrap">
					<ul className="navbar-nav nav-right">
						<li className="nav-item dropdown">
							<a className="nav-link nav-pill user-avatar" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
								<img src="../assets/img/avatars/1.jpg" className="w-35 rounded-circle" alt="Albert Einstein" />
							</a>
							<div className="dropdown-menu dropdown-menu-right dropdown-menu-accout">
								<div className="dropdown-header pb-3">
									<div className="media d-user">
										<img className="align-self-center mr-3 w-40 rounded-circle" src="../assets/img/avatars/1.jpg" alt="Albert Einstein" />
										<div className="media-body">
											<h5 className="mt-0 mb-0">{adminName}</h5>
											<span>{adminEmail}</span>
										</div>
									</div>
								</div>
								<Link className="dropdown-item" to="/admin/profile">
									<i className="icon dripicons-user"></i> Profile
								</Link>
								<div className="dropdown-divider"></div>
								<a className="dropdown-item" href="#" onClick={signout}>
									<i className="icon dripicons-lock-open"></i> Sign Out
								</a>
							</div>
						</li>
					</ul>
				</nav>

				<div className="content">
					{define_routes()}
				</div>

			</div>
		</>
	)
}

export default ContentComponent
