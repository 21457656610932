import React from 'react'
import Modal from 'react-modal'
import { Link } from "react-router-dom"
import { 
  FetchSubscriberGroup, 
  FetchSingleSubscriberGroupUsers,
  removeUserFromSubscriber
} from '../../../../api/requests/subscriber/SubscriberRequest'
import { searchSubscriberList } from "../../../../api/requests/subscriber/SubscriberRequest";
import { subscriptionList } from '../../../../api/requests/subscription/SubscriptionRequest'
import { ReadableDateTime } from '../../../../helpers/DateTime'
import { binarySearch } from '../../../../helpers/search/Binary'
import LoadingOverlay from 'react-loading-overlay'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const SubscriberList = () => {
    let subtitle;
    let [loading, setLoading] = React.useState(false)
    const [modalIsOpen, setIsOpen] = React.useState(false)
    const [subscribers, setSubscribers] = React.useState([])
    const [groupId, setGroupId] = React.useState(null)
    const [subScripList, setSubScripList] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [searchSubscription, setSearchSubscription] = React.useState("");
    const [subId, setSubId] = React.useState([])
  
    React.useEffect(() => {
      const data = async () => {
        setLoading(true)
        const data = await FetchSubscriberGroup()
        const subscription_list = await subscriptionList('LIST')
        
        if (data && subscription_list) {
          setSubScripList(subscription_list.data)
          for (let i = 0; i < data.data.length; i++) {
            let res = binarySearch(
              subscription_list.data,
              data.data[i].subscription_id,
              0,
              subscription_list.data.length,
            )
            data.data[i].subscription = res
          }
          setSubscribers(data.data)
          setTimeout(() => {
            setLoading(false)
          }, 800)
        } else {
          setTimeout(() => {
            setLoading(false)
          }, 800)
        }
      }
      data()
    }, [])
    
    const loadUserGroups = async (id) => {
      setLoading(true)
      setGroupId(id)
      let result = await FetchSingleSubscriberGroupUsers(id)
      if (result) {
        setTimeout(() => {
          setLoading(false)
        }, 800)
        setSubId(result.data[0].exists_members)
      }
    }

    const removeUser = async (id) => {
      setLoading(true)
      const result = await removeUserFromSubscriber(groupId, id)

      if(result.status === 'success'){
        const update_subId = subId.filter(data => data.uid !== id)
        setSubId(update_subId)
      }

      setTimeout(() => {
        setLoading(false)
      }, 800)

    }

    function openModal() {
      setIsOpen(true)
    }

    function afterOpenModal() {
      subtitle.style.color = '#f00'
    }

    const prevPagination = async () => {
      if(currentPage < 1) return false
      const data = await FetchSubscriberGroup(currentPage-1)  
      if(data && data.data.length != 0){      
        setCurrentPage(currentPage-1)
        for (let i = 0; i < data.data.length; i++) {
          let res = binarySearch(subScripList,
            data.data[i].subscription_id,
            0,
            subScripList.length,
          )
          data.data[i].subscription = res
        }
        setSubscribers(data.data)  
      }
      return true
    };

    const nextPagination = async () => {
        const data = await FetchSubscriberGroup(currentPage+1)
        if(data && data.data.length !=0){
          setCurrentPage(currentPage+1)
          for (let i = 0; i < data.data.length; i++) {
            let res = binarySearch(subScripList,
              data.data[i].subscription_id,
              0,
              subScripList.length,
            )
            data.data[i].subscription = res
          }
          setSubscribers(data.data)  
        }
        
        return true
    };

    const handleSubscriptionSearch = async (e) => {
      e.preventDefault()
      setLoading(true)
      if (searchSubscription.length > 0) {
          const data = await searchSubscriberList(searchSubscription);
          if(data.data.length !=0){
            for(let i=0; i<data.data.length; i++){
              let res = binarySearch(subScripList, data.data[i].subscription_id, 0, subScripList.length)
              data.data[i].subscription = res
            }
            setSubscribers(data.data)  
          }
          setCurrentPage(1)
      }
      setLoading(false)
    };

    const handleReset = (e) => {
      window.location.reload()
  };

    function closeModal() {
      setIsOpen(false)
      window.location.reload(true)
    }

    return (
      <>
        <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
          <div style={{minHeight:90+'vh'}}>
            <header className="page-header">
                <div className="d-flex align-items-center">
                    <div className="mr-auto">
                        <h1 className="separator">Subscriber</h1>
                        <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                  <span><i className="icon dripicons-home"></i></span>
                                </li>
                                <li className="breadcrumb-item">
                                  <span>Subscriber</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                  Subscriber List
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </header>

            <section className="page-content container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <form className="card-header">
                                <span>Subscriber List</span>
                                <input
                                    type="text"
                                    style={{ margin: "0 1rem" }}
                                    value={searchSubscription}
                                    onChange={(e) =>
                                        setSearchSubscription(e.target.value)
                                    }
                                />
                                <button
                                    type="submit"
                                    className="btn-primary"
                                    style={{ marginRight: "1rem" }}
                                    onClick={handleSubscriptionSearch}
                                >
                                    Search
                                </button>
                                <button
                                    className="btn-secondary"
                                    onClick={handleReset}
                                >
                                    Reset
                                </button>
                            </form>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Subscription</th>
                                                <th>Subscriber Group Name</th>
                                                <th>No. of Users</th>
                                                <th>Created At</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subscribers.length ? subscribers.map((item) => (
                                                <tr key={item._id}>
                                                    <td>{item.subscription.name}</td>
                                                    <td>
                                                      <strong>{item.group_name}</strong>
                                                    </td>
                                                    <td>{item.total_members}</td>
                                                    <td>{ReadableDateTime(item.created_at)}</td>
                                                    <td>
                                                        <span 
                                                          class="badge badge-pill badge-dark "
                                                          title="View sub-user list"
                                                          onClick={() => {
                                                            openModal()
                                                            loadUserGroups(item._id)
                                                          }}
                                                        >
                                                            <i class="zmdi zmdi-eye zmdi-hc-fw text-white"></i>
                                                        </span>
                                                        <Link 
                                                            to={`/subscriber/add-more-subscriber/${item._id}`}
                                                            className="badge badge-pill badge-success" 
                                                            title="Add sub-user">
                                                            <i className="la la-user-plus" style={{color: '#fff'}}></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )) : 'Subscriber Not Found'}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    ariaHideApp={false}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                  <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                    Subscriber Group Users
                  </h2>
                  <div></div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Email</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subId.length > 0 
                                    ? subId.map((data) => (
                                    <tr key={data.id}>
                                        <td>{data.uid}</td>
                                        <td>{data.email}</td>
                                        <td>
                                            <span className="badge badge-pill badge-success" title="Sub user details">
                                                <i  className="zmdi zmdi-eye zmdi-hc-fw" style={{color: '#fff'}}></i>
                                            </span>
                                            <span 
                                                class="badge badge-pill badge-danger " 
                                                title="remove user"
                                                onClick={() => {
                                                  removeUser(data.uid)
                                                }}>
                                                  <i class="la la-remove text-white"></i>
                                            </span>
                                        </td>
                                    </tr>
                                    )) : 'No sub user found'}
                            </tbody>
                        </table>
                    </div>                                                        
                </Modal>
                
                <div className="card-footer bg-light">
                    <button
                        type="submit"
                        className="btn btn-primary mr-1"
                        onClick={prevPagination}
                    >
                        Previous
                    </button>
                    <span className="btn btn-primary mr-1">
                        {currentPage+1}
                    </span>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={nextPagination}
                    >
                        Next
                    </button>
                </div>

            </section>
            
          </div>
        </LoadingOverlay>
      </>
    )
  }
  
  export default SubscriberList