import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const searchRdcCompany = async (payload) => {
    try {
        const params = new URLSearchParams()

        params.append('search_q', payload)

        const result = await AxiosInstance.post(EndPoints.search_rdc_company, params)
                        .then((res) => {
                            const result =  SuccessResponse(res)
                            return result.data
                        })
                        .catch((err) => {
                            return ErrorResponse(err)
                        })

        return result

    } catch (err) {
        return []
    }
}

export const searchRdcBrandByCompany = async (payload) => {
    const params = new URLSearchParams()
    params.append('search_q', JSON.stringify(payload))
    
    const result = await AxiosInstance.post(EndPoints.search_rdc_brand, params)
        .then((res) => {
            const result =  SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })

    return result
}

export const searchRdcBrandByName = async (payload) => {
    const params = new URLSearchParams()

    params.append('search_q', payload)

    const result = await AxiosInstance.post(EndPoints.rdc_brand_search_q, params)
        .then((res) => {
            const result = SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    
    return result
}

export const searchRdcProductType = async (payload) => {
    try {
        const params = new URLSearchParams()

        params.append('search_q', payload)

        const result = await AxiosInstance.post(EndPoints.search_rdc_product_type, params)
                        .then((res) => {
                            const result =  SuccessResponse(res)
                            return result.data
                        })
                        .catch((err) => {
                            return ErrorResponse(err)
                        })

        return result

    } catch (err) {
        return []
    }
}

export const findRdcProduct = async (payload) => {
    try {
        const params = new URLSearchParams()

        params.append('search_q', JSON.stringify(payload))
        
        const result = await AxiosInstance.post(EndPoints.search_rdc_product, params)
            .then((res) => {
                const result =  SuccessResponse(res)
                return result.data
            })
            .catch((err) => {
                return ErrorResponse(err)
            })

        return result

    } catch (err) {

    }
}

export const searchRdcProductByName = async (payload) => {
    const params = new URLSearchParams()

    params.append('search_q', payload)

    const result = await AxiosInstance.post(EndPoints.search_rdc_product_by_name, params)
        .then((res) => {
            const result = SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    
    return result
}


export const rdcMediaList = async () => {
    try {
        const result = await AxiosInstance.post(EndPoints.rdc_media_list)
            .then((res) => {
                const result =  SuccessResponse(res)
                return result.data
            })
            .catch((err) => {
                return ErrorResponse(err)
            })

        return result

    } catch (err) {
        return []
    }
}


export const rdcAdTypeList = async () => {
    try {
        const result = await AxiosInstance.post(EndPoints.rdc_ad_type_list)
            .then((res) => {
                const result =  SuccessResponse(res)
                return result.data
            })
            .catch((err) => {
                return ErrorResponse(err)
            })

        return result

    } catch (err) {
        return []
    }
}