import React from 'react'
import Layout from './apps/pages/layouts/Index'

function App() {
  return (
    <>
      <Layout/>
    </>
  )
}

export default App
