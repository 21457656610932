import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const searchNewsCompany = async (payload) => {
    const params = new URLSearchParams()

    params.append('search_q', payload)

    const result = await AxiosInstance.post(EndPoints.search_news_company, params)
        .then((res) => {
            const result =  SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })

    return result
}
export const searchNewsCategory = async (payload) => {
    const params = new URLSearchParams()

    params.append('search_q', payload)

    const result = await AxiosInstance.post(EndPoints.search_news_category, params)
        .then((res) => {
            const result =  SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })

    return result
}

export const searchNewsSubCategory = async (payload) => {
    const params = new URLSearchParams()
    
    params.append('search_q', JSON.stringify({"cat": payload}))

    const result = await AxiosInstance.post(EndPoints.search_news_sub_category, params)
        .then((res) => {
            const result =  SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })

    return result
}
export const searchNewsSubCategoryByName = async (payload) => {
    const params = new URLSearchParams()
    params.append('search_q', payload)
    const result = await AxiosInstance.post(EndPoints.search_news_sub_category_by_name, params)
        .then((res) => {
            const result =  SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })

    return result
}
