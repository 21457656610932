import React from 'react'
import { Link } from "react-router-dom"
import { Message } from '../../../../helpers/Constant'
import Notification from '../../../helpers/Notification'
import { addCompany } from '../../../../api/requests/company/CompanyRequest'
function CompanyAdd(){
    const [validated, setValidated] = React.useState(false)
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [adv_code, setAdvCode] = React.useState(null)
    const [companyID, setCompanyID] = React.useState(null)
    const [companyName, setcompanyName] = React.useState('')
    const submitForm = async (event) => {
        event.preventDefault()
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.stopPropagation()
            setNotification(false)
        } else {
            const company = {
                companyID,
                companyName,
                adv_code,
            }
            const result = await addCompany(company)
            setTimeout(() => {
                setNotification(false)
            }, 5000)
            if (result.code === 200) {
                setNotification(true)
                setNotificationFlag('success')
                setNotificationMessage(result.message)
            }
            else {
                setNotification(true)
                setNotificationFlag('danger')
                setNotificationMessage(result.message)
            }
        }
        setValidated(true)
    }

    return (
        <>
            <header className="page-header">
                <div className="d-flex align-items-center">
                    <div className="mr-auto">
                        <h1 className="separator">
                            Add Company
                        </h1>
                        <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <i className="icon dripicons-home"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/company">
                                        Company
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Company List
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </header>

            {/* company add form */}
            <section className="page-content container-fluid">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="card">
                            <h5 className="card-header">Company Form</h5>
                            <form
                                validated={validated}
                                onSubmit={submitForm}
                            >   
                                { notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}

                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="inputCompanyName">
                                            Company Name
                                        </label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="inputCompanyName"
                                            placeholder="What's company name?"
                                            name="company_name"
                                            value={companyName}
                                            required
                                            onChange={(e) => {
                                                setcompanyName(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="inputAdvCode">
                                            Adv Code
                                        </label>
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            id="inputAdvCode"
                                            min="1"
                                            value={adv_code}
                                            required
                                            onChange={(e) => {
                                                setAdvCode(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="inputCompanyId">
                                            Company ID
                                        </label>
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            id="inputCompanyId"
                                            min="1"
                                            value={companyID}
                                            required
                                            onChange={(e) => {
                                                setCompanyID(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer bg-light">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CompanyAdd