import React from 'react'
import { ALERT as text } from '../../../../helpers/Constant'
import { userList, getUserById } from '../../../../api/requests/user/UserRequest'
import { createNewAlert } from '../../../../api/requests/alert-service/AlertServiceRequest'
import Notification from '../../../helpers/Notification'
import AsyncSelect from 'react-select/async';
const InstantAlertAdd = () => {
    const [inputValue, setValue] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState(null);
    const [validated, setValidated] = React.useState(false)
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [title, setTitle] = React.useState('')
    const [client, setClient] = React.useState(0)
    const [details, setDetails] = React.useState(null)
    React.useEffect(() => {}, [])
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (selectedValue === null) {
            alert(text.instant_add_form_validate.client)
            return false
        }
        const form = event.currentTarget
        const alert = { 
            title, 
            selectedValue, 
            details 
        }
        const result = await createNewAlert(alert)
        setTimeout(() => {
            setNotification(false)
        }, 5000)

        setTimeout(() => {}, 2500)
        if (result.code === 200) {
            setNotification(true)
            setNotificationFlag('success')
            setNotificationMessage(result.message)
        }
        else {
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage(result.message)
        }
        setValidated(true)
    }
    const changeClient = async (event) => {
        setClient(event.target.value)
    }
    const handleInputChange = value => {
        setValue(value);
    };
    const handleChange = value => {
        setSelectedValue(value);
    }
    const fetchData = async (e) => {
        if(e.length > 0){
            const data = await getUserById({u_id: e})
            
            if(data.data === null){
                return []
            } else {
                return [data.data]
            }

        } else {
            return []
        }
    };


    return(<> 
        <header className="page-header">
            <div className="d-flex align-items-center">
                <div className="mr-auto">
                    <h1 className="separator">
                        {text.instant_add_title}
                    </h1>
                    <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <span><i className="icon dripicons-home"></i></span>
                            </li>
                            <li className="breadcrumb-item">
                                <span>Alert Service</span>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Add New Alert
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </header>
        <section className="page-content container-fluid">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="card">
                        <h5 className="card-header">
                            {text.instant_add_form_title}
                        </h5>
                        <div className="card-body">
                            <div className="col-md-12">

                            <form onSubmit={handleSubmit}>   
                                { notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}

                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="title">
                                            {text.instant_add_form.title}
                                        </label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="title"
                                            name="title"
                                            value={title}
                                            required
                                            onChange={(e) => {
                                                setTitle(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="inputAdvCode">
                                            {text.instant_add_form.client}
                                        </label>
                                        <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            defaultOptions
                                            value={selectedValue}
                                            getOptionLabel={e => e.email}
                                            getOptionValue={e => e.uid}
                                            loadOptions={fetchData}
                                            onInputChange={handleInputChange}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="details">
                                            {text.instant_add_form.details}
                                        </label>
                                        <textarea rows="3" className="form-control" id="details" value={details} required
                                            onChange={(e) => {
                                                setDetails(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer bg-light">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default InstantAlertAdd
