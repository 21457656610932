import React from 'react'
import { getAlertService, getAlertServiceByDate } from '../../../../api/requests/AlertServices'
import { ReadableDateTime } from '../../../../helpers/DateTime'
import { Link} from "react-router-dom"
import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"
const AlertServiceList = () => {
    
    const [alert, setAlert] = React.useState([])
    const [attachmentPopUp, setAttachmentPopUp] = React.useState(false)
    const [fileList, setFileList] = React.useState([])
    const [searchDate, setSearchDate] = React.useState('')

    React.useEffect(() => {
        const data = async () => {
        const data = await getAlertService()
        if (data) {
            setTimeout(() => {}, 800)
        } else {
            setTimeout(() => {}, 800)
        }
        setAlert(data)
        }
        data()
    }, [])

    const handleSearch = async () => {
        const payload = { search_date: searchDate }
        const data = await getAlertServiceByDate(payload)
        setAlert(data)
    }

    const handleAttachments = (obj) => {
        setFileList([
            ...(JSON.parse(obj.pdf)),
            ...(JSON.parse(obj.ppt)),
            ...(JSON.parse(obj.xls)),
            ...(JSON.parse(obj.zip)),
        ])

        if(obj.desc){
            setFileList(prev => [
                ...prev,
                { title: 'desc', path: obj.desc }
            ])
        }
        
        setAttachmentPopUp(true)
    }

    return (
        <>
            <header className="page-header">
                <div className="d-flex align-items-center">
                    <div className="mr-auto">
                        <h1 className="separator">Alert List</h1>
                        <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <span><i className="icon dripicons-home"></i></span>
                                </li>
                                <li className="breadcrumb-item">
                                    <span>Alert Service</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Alert List
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </header>

            <section className="page-content container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-header row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Select Date</label>
                                            <DatePicker
                                                className="form-control"
                                                dateFormat='dd-MM-yyyy'
                                                selected={searchDate}
                                                onChange={(date) => {
                                                    setSearchDate(date)
                                                }} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Action</label>
                                            <div>
                                            <button className='btn btn-primary' onClick={handleSearch}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>User</th>
                                                <th>Created</th>
                                                <th>Attachments</th>
                                                <th>Status</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {alert ? alert.map((x) => (
                                            <tr key={x._id}>
                                                <td>{x.name}</td>
                                                <td>
                                                    {`${x.user_info?.full_name?.first_name} ${x.user_info?.full_name?.last_name}`}
                                                </td>
                                                <td>
                                                    {ReadableDateTime(x.created_at)}
                                                </td>
                                                <td>
                                                    <span 
                                                        style={{ cursor: 'pointer', color: 'blue' }}
                                                        onClick={() => handleAttachments(x)}
                                                    >
                                                        Files
                                                    </span>
                                                </td>
                                                <td>
                                                    {x.status == 1 ? 'Active' : 'Inactive' }
                                                </td>
                                                {/* <td>
                                                    <Link to={`#/alert/edit/${x._id}`}>
                                                        Edit
                                                    </Link>
                                                </td> */}
                                            </tr>
                                        )) : 'IDs Not Found'}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Modal show={attachmentPopUp} onHide={() => setAttachmentPopUp(false)}>
                <Modal.Header>
                    <Modal.Title>
                        Attachments
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>File Type</th>
                                <th>File Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fileList.map((each, index) => (
                                <tr key={index}>
                                    <td>{ each.title }</td>
                                    <td style={{ wordBreak: 'break-word' }}>{ each.path }</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AlertServiceList
