import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const companyListPrintOnline = async (page_no) => {
    const params = new URLSearchParams()
 
    params.append('page', page_no)
 
     const result = await AxiosInstance.post(EndPoints.print_online_company_list, params)
     .then((res) => {
        return SuccessResponse(res)
     })
     .catch((err) => {
        return ErrorResponse(err)
     })
  
    return result
 }
export const searchPrintOnlineCompany = async (payload) => {
    const params = new URLSearchParams()

    params.append('search_q', payload)

    const result = await AxiosInstance.post(EndPoints.search_print_online_company, params)
        .then((res) => {
            const result =  SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })

    return result
}
export const searchPrintOnlineBrand = async (payload) => {
    const params = new URLSearchParams()
    params.append('search_q', payload)
    
    const result = await AxiosInstance.post(EndPoints.search_print_online_brand_by_name, params)
        .then((res) => {
            const result =  SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })

    return result
}
export const searchPrintOnlineProductType = async(payload) => {
    try {
        const params = new URLSearchParams()

        params.append('search_q', payload)

        const result = await AxiosInstance.post(EndPoints.search_print_online_product_type, params)
                        .then((res) => {
                            const result =  SuccessResponse(res)
                            return result.data
                        })
                        .catch((err) => {
                            return ErrorResponse(err)
                        })

        return result

    } catch (err) {

    }

}

export const findPrintOnlineProduct = async (payload) => {
    try {
        const params = new URLSearchParams()
        params.append('search_q', JSON.stringify(payload))
        
        const result = await AxiosInstance.post(EndPoints.print_online_product_find, params)
            .then((res) => {
                const result =  SuccessResponse(res)
                return result.data
            })
            .catch((err) => {
                return ErrorResponse(err)
            })

        return result

    } catch (err) {

    }
}

export const searchPrintOnlineProduct = async(payload) => {
    try {
        const params = new URLSearchParams()

        params.append('search_q', payload)

        const result = await AxiosInstance.post(EndPoints.print_online_product_search_by_name, params)
                        .then((res) => {
                            const result =  SuccessResponse(res)
                            return result.data
                        })
                        .catch((err) => {
                            return ErrorResponse(err)
                        })

        return result

    } catch (err) {

    }

}
