import { Navigate, Outlet} from "react-router"
import Cookies from "universal-cookie"

const useAuth = () => {
    const cookies = new Cookies()
    let auth_status = false
    const session_data = cookies.get('adata')
    if(session_data)
    {
        if( session_data.id && 
            session_data.uid &&
            session_data.userName &&
            session_data.email &&
            session_data.token )
        {
            auth_status = true
        }
        else
        {
            auth_status = false
        }
    } 
    else
    {
        auth_status = false
    }
    
    return auth_status
}

const ProtectedRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedRoutes