import React from "react"
import { useNavigate } from "react-router-dom"
import { loginAdmin } from '../../api/requests/admin/AdminRequest'
import Notification from '../helpers/Notification'
import { SetAdminCookies, FetchAdminCookies } from '../../helpers/Cookies'
import LoadingOverlay from 'react-loading-overlay';
import validate from "./LoginFormValidationRules";
import useLoginForm from "./useLoginForm"
function LoginComponent(){
    document.title="Admin - login";
    let navigate = useNavigate();
    let [loading, setLoading] = React.useState(false);
    const { values, handleChange, handleSubmit, errors } = useLoginForm(loginAction, validate);

    function loginAction(){
        submitFormData()
    }
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const submitFormData = async () => {
        setNotificationMessage('FALSE')
        setLoading(true)
        const {userName, password} = values
        const admin = { userName, password }
        const result = await loginAdmin(admin)
        setTimeout(() => {
            setNotification(false)
        }, 5000)
        if (result.status === 'Success') {
            setLoading(false)
            setNotification(true)
            setNotificationFlag('success')
            setNotificationMessage(result.message)
            const admin_user = {
                id: result.data.user._id,
                uid: result.data.user.uid,
                userName: result.data.user.username,
                email: result.data.user.email,
                role: result.data.user.role,
                access: result.data.access,
                token: result.data.token,
            }
            SetAdminCookies('adata', JSON.stringify(admin_user))
            setTimeout(() => {
                navigate("/home")
                window.location.reload(false)
            }, 5000)

        } else {
            setLoading(false)
            setNotification(true)
            setNotificationFlag('danger', 'D')
            if(typeof result.message === 'string'){
                setNotificationMessage(result.message)
            } else {
                setNotificationMessage('Wrong email or password 😥 !!')
            }
        }   
    }

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:90+'vh'}}>
                    <div className="container">
                        <form className="sign-in-form" onSubmit={handleSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    {notification ? (
                                        <Notification flag={notificationFlag} msg={notificationMessage} />
                                    ) : (
                                        ''
                                    )}
                                    <a href="#" className="brand text-center d-block m-b-20">
                                        <img src="../assets/img/logo-png.png" alt="Ryans Media Admin" />
                                    </a>
                                    <h6 className="sign-in-heading text-center m-b-20">
                                        Sign in to Admin account
                                    </h6>
                                    <div className="form-group">
                                        <label htmlFor="inputEmail" className="sr-only">
                                            Email
                                        </label>
                                        <input
                                            type="email" 
                                            id="userName" 
                                            className="form-control " 
                                            placeholder="Email"
                                            name="userName"
                                            value={values.userName || ''}
                                            onChange={handleChange}
                                        />
                                        <small>
                                            {errors.userName && (<p className="text-danger">*{errors.userName}</p>)}
                                        </small>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="inputPassword" className="sr-only">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            id="inputPassword"
                                            className="form-control"
                                            placeholder="Password"
                                            name="password"
                                            autoComplete="on"
                                            value={values.password || ''}
                                            onChange={handleChange}
                                        />
                                        <small>
                                            {errors.password && (<p className="text-danger">*{errors.password}</p>)}
                                        </small>
                                    </div>
                                    <button type="submit" className="btn btn-danger btn-rounded btn-floating btn-lg btn-block">
                                        Sign In
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default LoginComponent