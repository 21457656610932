const Index = {
    form: {
        error: {
            empty: (field) => { 
                return `${field} can't be empty`
            },
            min: (field, length) => { 
                return `The ${field} must contain at least ${length} characters.`
            }
        },
    }

    
}

export default Index