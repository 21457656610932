import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import Notification from '../../../helpers/Notification'
import LoadingOverlay from 'react-loading-overlay'
import "react-datepicker/dist/react-datepicker.css"
import { GetPermissionDetails } from '../../../../api/requests/data-access/DataAccessRequest'
import { permissionUpdate } from '../../../../api/requests/user/UserRequest'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { 
    searchTVBrandByCompany, 
    searchTvCompany,
    searchTvcProductType,
    findTvcProduct,
    tvcMediaList,
    tvcAdTypeList
} from '../../../../api/requests/company/TvcRequest'
import {
    searchPrintOnlineCompany,
    searchPrintOnlineBrand,
    searchPrintOnlineProductType,
    findPrintOnlineProduct
} from '../../../../api/requests/company/PrintOnlineRequest'
import {
    searchRdcCompany,
    searchRdcBrandByCompany,
    searchRdcProductType,
    findRdcProduct,
    rdcMediaList,
    rdcAdTypeList
} from '../../../../api/requests/company/RdcRequest'
import { 
    searchNewsCompany,
    searchNewsCategory,
    searchNewsSubCategory
} from '../../../../api/requests/company/NewsCompanyRequest'
const state_object = {
    company: [],
    company_all: false,
    from_date: '',
    to_date: '',
    setAll: false
}
const state_object_extends = {
    ...state_object,
    brand: [],
    brand_all: false,
    media: [],
    media_all: false,
}
const state_object_brand_extends = {
    ...state_object,
    brand: [],
    brand_all: false
}
const state_object_media_extends = {
    ...state_object,
    media: [],
    media_all: false
}
const common_state = {
    access_type: 0,
    from_date: '',
    to_date: '',
    setAll: false
}
const inner_state = {
    items:[],
    selected_all: false,
}
const state_object_of_ad_print = {
    ...common_state,
    company: {...inner_state},
    brand: {...inner_state},
    product_type: {...inner_state},
    product: {...inner_state}
}
const state_object_of_tvc_ad = {
    ...common_state,
    company: {...inner_state},
    brand: {...inner_state},
    product_type: {...inner_state},
    product: {...inner_state},
    media: { ...inner_state },
    ad_type: { ...inner_state }
}
const state_object_news = {
    ...common_state,
    company: {...inner_state},
    category: {...inner_state},
    sub_category: {...inner_state}
}
const PermissionEdit = () => {
    let [loading, setLoading] = React.useState(false)
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [permissionType, setPermissionType] = React.useState(0)
    const [selectedValue, setSelectedValue] = React.useState([])
    const [brandSelectedValue, setBrandSelectedValue] = React.useState([])
    const [mediaSelectedValue, setMediaSelectedValue] = React.useState([])
    const [productTypeSelectedValue, setProductTypeSelectedValue] = React.useState([])
    const [newsCategory, setNewsCategory] = React.useState([])
    const [newsSubCategory, setNewsSubCategory] = React.useState([])
    const [showPrint, setShowPrint] = React.useState(false)
    const [showOnline, setShowOnline] = React.useState(false)
    const [showRDC, setShowRDC] = React.useState(false)
    const [showTVC, setShowTVC] = React.useState(false)
    const [showTVNews, setShowTVNews] = React.useState(false)
    const [showRadioNews, setShowRadioNews] = React.useState(false)
    const [showPrintNews, setShowPrintNews] = React.useState(false)
    const [showOnlineNews, setShowOnlineNews] = React.useState(false)
    const [adTvcFormData, setAdTvcFormData] = React.useState(state_object)
    const [adTvcData, setAdTvcData] = React.useState(state_object_of_tvc_ad)
    const [adRdcFormData, setAdRdcFormData] = React.useState(state_object_extends)
    const [adRdcData, setAdRdcData] = React.useState(state_object_of_tvc_ad)
    const [adPrintFormData, setAdPrintFormData] = React.useState(state_object)
    const [adPrintData, setAdPrintData] = React.useState(state_object_of_ad_print)
    const [adOnlineFormData, setAdOnlineFormData] = React.useState(state_object)
    const [adOnlineData, setAdOnlineData] = React.useState(state_object_of_ad_print)
    const [newsTvcData, setNewsTvcData] = React.useState(state_object_news)
    const [newsRdcData, setNewsRdcData] = React.useState(state_object_news)
    const [newsPrintData, setNewsPrintData] = React.useState(state_object_news)
    const [newsOnlineData, setNewsOnlineData] = React.useState(state_object_news)
    const [brandList, setBrandList] = React.useState([])
    const [selectedBrand, setSelectedBrand] = React.useState([])
    const [productList, setProductList] = React.useState([])
    const [selectedProduct, setSelectedProduct] = React.useState([])
    const [selectedMedia, setSelectedMedia] = React.useState([])
    const [selectedAdType, setSelectedAdType] = React.useState([])
    const [subCategoryList, setSubCategoryList] = React.useState([])
    const [parentUser, setParentUser] = React.useState([])
    const [modalCompany, setModalCompany] = React.useState([])
    const [modalCompanySelected, setModalCompanySelected] = React.useState([])
    const [expireStartDate, setExpireStartDate] = React.useState(new Date())
    const [expireEndDate, setExpireEndDate] = React.useState(new Date())
    const [modalFromDate, setModalFromDate] = React.useState(new Date())
    const [modalToDate, setModalToDate] = React.useState(new Date())
    const [modalTitle, setModalTitle] = React.useState('')
    const [show, setShow] = React.useState(false)
    const[showBrand, setShowBrand] = React.useState(false)
    const[showMedia, setShowMedia] = React.useState(false)
    const[companyChecked, setCompanyChecked] = React.useState(false)
    const[brandChecked, setBrandChecked] = React.useState(false)
    const[mediaChecked, setMediaChecked] = React.useState(false)
    const[productTypeChecked, setProductTypeChecked] = React.useState(false)
    const[productChecked, setProductChecked] = React.useState(false)
    const[adTypeChecked, setAdTypeChecked] = React.useState(false)
    const[newsSubCategoryChecked, setNewsSubCategoryChecked] = React.useState(false)

    const checkedPermissionChange = (type) => {
        if(type === 0){
            setPermissionType(0)
        }
        else if(type === 1) {
            setPermissionType(1)
        }
        else if(type === 2) {
            setPermissionType(2)
        }
    }
    const checkedAllOption = (option) => {
        if(option.category === 'print_ad'){
            if(option.type === 'company') setCompanyChecked(!companyChecked)
            if(option.type === 'brand') setBrandChecked(!brandChecked)
            if(option.type === 'product_type') setProductTypeChecked(!productTypeChecked)
            if(option.type === 'product') setProductChecked(!productChecked)
        }
        else if(option.category === 'rdc_ad'){
            if(option.type === 'company') setCompanyChecked(!companyChecked)
            if(option.type === 'brand') setBrandChecked(!brandChecked)
            if(option.type === 'product_type') setProductTypeChecked(!productTypeChecked)
            if(option.type === 'product') setProductChecked(!productChecked)
            if(option.type === 'media') setMediaChecked(!mediaChecked)
            if(option.type === 'ad_type') setAdTypeChecked(!adTypeChecked)
        }
        else if(option.category === 'tvc_ad'){
            if(option.type === 'media') setMediaChecked(!mediaChecked)
            if(option.type === 'ad_type') setAdTypeChecked(!adTypeChecked)
        }
        else if(['tv_news', 'radio_news', 'print_news', 'online_news'].includes(option.category)) {
            if(option.type === 'sub_category') setNewsSubCategoryChecked(!newsSubCategoryChecked)
        }
    }

    const stateControl = (action, section, data='') => {
        if (section === 'print_ad'){
            if (action === 'modal_closed') {
                setShowPrint(false)
                setAdPrintFormData(prevState => ({
                    ...prevState,
                    company: selectedValue,
                    company_all: companyChecked
                }))
                setAdPrintData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: companyChecked,
                    },
                    brand: {
                        ...prevState.brand,
                        items: selectedBrand,
                        selected_all: brandChecked,
                    },
                    product_type: {
                        ...prevState.product_type,
                        items: productTypeSelectedValue,
                        selected_all: productTypeChecked,
                    },
                    product: {
                        ...prevState.product,
                        items: selectedProduct,
                        selected_all: productChecked,
                    }
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(adPrintData.access_type)
                setSelectedValue(adPrintData.company.items)
                setCompanyChecked(adPrintData.company.selected_all)
                setSelectedBrand(adPrintData.brand.items)
                setBrandChecked(adPrintData.brand.selected_all)
                setProductTypeSelectedValue(adPrintData.product_type.items)
                setSelectedProduct(adPrintData.product.items)
                setProductChecked(adPrintData.product.selected_all)
                setShowPrint(true)
            }
            else if(action === 'show_active'){
                return `btn ${  
                    adPrintData.access_type === 2 ||
                    adPrintData.company.items.length > 0 ||
                    adPrintData.product_type.items.length > 0 ||
                    adPrintFormData.company_all ||
                    adPrintFormData.company.length > 0 ?
                    'btn-success' :
                    'btn-danger btn-outline'
                }`
            }
        }
        else if(section === 'online_ad'){
            if(action === 'modal_closed'){
                setShowOnline(false)

                setAdOnlineFormData(prevState => ({
                    ...prevState,
                    company: selectedValue,
                    company_all: companyChecked
                }))

                setAdOnlineData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: companyChecked,
                    },
                    brand: {
                        ...prevState.brand,
                        items: selectedBrand,
                        selected_all: brandChecked,
                    },
                    product_type: {
                        ...prevState.product_type,
                        items: productTypeSelectedValue,
                        selected_all: productTypeChecked,
                    },
                    product: {
                        ...prevState.product,
                        items: selectedProduct,
                        selected_all: productChecked,
                    }
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(adOnlineData.access_type)
                setSelectedValue(adOnlineData.company.items)
                setCompanyChecked(adOnlineData.company.selected_all)
                setSelectedBrand(adOnlineData.brand.items)
                setBrandChecked(adOnlineData.brand.selected_all)
                setProductTypeSelectedValue(adOnlineData.product_type.items)
                setSelectedProduct(adOnlineData.product.items)
                setProductChecked(adOnlineData.product.selected_all)
                setShowOnline(true)
            }
            else if(action === 'show_active'){
                return `btn ${
                    adOnlineData.access_type === 2 ||
                    adOnlineData.company.items.length > 0 ||
                    adOnlineData.product_type.items.length > 0 ||
                    adOnlineFormData.company_all || 
                    adOnlineFormData.company.length > 0 ?
                        'btn-success' :
                        'btn-danger btn-outline'
                    }`
            }
        }
        else if(section === 'rdc_ad'){
            if(action === 'modal_closed'){
                setShowRDC(false)
                setAdRdcFormData(prevState => ({
                    ...prevState,
                    company: selectedValue,
                    brand: brandSelectedValue,
                    media: mediaSelectedValue,
                    company_all: companyChecked,
                }))
                setAdRdcData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: companyChecked,
                    },
                    brand: {
                        ...prevState.brand,
                        items: selectedBrand,
                        selected_all: brandChecked,
                    },
                    product_type: {
                        ...prevState.product_type,
                        items: productTypeSelectedValue,
                        selected_all: productTypeChecked,
                    },
                    product: {
                        ...prevState.product,
                        items: selectedProduct,
                        selected_all: productChecked,
                    },
                    media: {
                        ...prevState.media,
                        items: selectedMedia,
                        selected_all: mediaChecked,
                    },
                    ad_type: {
                        ...prevState.ad_type,
                        items: selectedAdType,
                        selected_all: adTypeChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(adRdcData.access_type)
                setSelectedValue(adRdcData.company.items)
                setCompanyChecked(adRdcData.company.selected_all)
                setSelectedBrand(adRdcData.brand.items)
                setBrandChecked(adRdcData.brand.selected_all)
                setProductTypeSelectedValue(adRdcData.product_type.items)
                setSelectedProduct(adRdcData.product.items)
                setSelectedMedia(adRdcData.media.items)
                setSelectedAdType(adRdcData.ad_type.items)
                setProductChecked(adRdcData.product.selected_all)
                setMediaChecked(adRdcData.media.selected_all)
                setAdTypeChecked(adRdcData.ad_type.selected_all)
                setShowRDC(true)
                
            }
            else if(action === 'show_active'){
                return `btn ${
                    adRdcData.company.items.length > 0 ||
                    adRdcData.product_type.items.length > 0 ||
                    adRdcData.access_type == 2 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
        else if(section === 'tvc_ad'){
            if(action === 'modal_closed'){
                setShowTVC(false)
                setAdTvcFormData(prevState => ({
                    ...prevState,
                    brand: brandSelectedValue,
                    company: selectedValue,
                    media: mediaSelectedValue,
                    company_all: companyChecked
                }))
                setAdTvcData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: companyChecked,
                    },
                    brand: {
                        ...prevState.brand,
                        items: selectedBrand,
                        selected_all: brandChecked,
                    },
                    product_type: {
                        ...prevState.product_type,
                        items: productTypeSelectedValue,
                        selected_all: productTypeChecked,
                    },
                    product: {
                        ...prevState.product,
                        items: selectedProduct,
                        selected_all: productChecked,
                    },
                    media: {
                        ...prevState.media,
                        items: selectedMedia,
                        selected_all: mediaChecked,
                    },
                    ad_type: {
                        ...prevState.ad_type,
                        items: selectedAdType,
                        selected_all: adTypeChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                console.log(adTvcData)
                setPermissionType(adTvcData.access_type)
                setSelectedValue(adTvcData.company.items)
                setCompanyChecked(adTvcData.company.selected_all)
                setSelectedBrand(adTvcData.brand.items)
                setBrandChecked(adTvcData.brand.selected_all)
                setProductTypeSelectedValue(adTvcData.product_type.items)
                setProductChecked(adTvcData.product.selected_all)
                setSelectedProduct(adTvcData.product.items)
                setSelectedMedia(adTvcData.media.items)
                setMediaChecked(adTvcData.media.selected_all)
                setSelectedAdType(adTvcData.ad_type.items)
                setAdTypeChecked(adTvcData.ad_type.selected_all)
                setShowTVC(true)
            }
            else if(action === 'show_active'){
                return `btn ${
                    adTvcData.access_type === 2 ||
                    adTvcData.company.items.length > 0 ||
                    adTvcData.product_type.items.length > 0 ||
                    adTvcFormData.company_all ||
                    adTvcFormData.company.length > 0 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
        else if(section === 'print_news'){
            if(action === 'modal_closed'){
                setShowPrintNews(false)
                setNewsPrintData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: 0,
                    },
                    category: {
                        ...prevState.category,
                        items: newsCategory,
                        selected_all: 0,
                    },
                    sub_category: {
                        ...prevState.category,
                        items: newsSubCategory,
                        selected_all: newsSubCategoryChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(newsPrintData.access_type)
                setSelectedValue(newsPrintData.company.items)
                setNewsCategory(newsPrintData.category.items)
                setNewsSubCategory(newsPrintData.sub_category.items)
                setModalFromDate(newsPrintData.from_date)
                setModalToDate(newsPrintData.to_date)
                setCompanyChecked(newsPrintData.company_all)
                setNewsSubCategoryChecked(newsPrintData.sub_category.selected_all)
                setShowPrintNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${
                        newsPrintData.access_type === 2 || 
                        newsPrintData.company.company_all || 
                        newsPrintData.company.items.length > 0 ?
                            'btn-success' :
                            'btn-danger btn-outline'}`
            }
        }
        else if(section === 'online_news'){
            if(action === 'modal_closed'){
                setShowOnlineNews(false)
                setNewsOnlineData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: 0,
                    },
                    category: {
                        ...prevState.category,
                        items: newsCategory,
                        selected_all: 0,
                    },
                    sub_category: {
                        ...prevState.category,
                        items: newsSubCategory,
                        selected_all: newsSubCategoryChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                console.log('? Online News Open :', newsOnlineData)
                setPermissionType(newsOnlineData.access_type)
                setSelectedValue(newsOnlineData.company.items)
                setNewsCategory(newsOnlineData.category.items)
                setNewsSubCategory(newsOnlineData.sub_category.items)
                setModalFromDate(newsOnlineData.from_date)
                setModalToDate(newsOnlineData.to_date)
                setCompanyChecked(newsOnlineData.company_all)
                setNewsSubCategoryChecked(newsOnlineData.sub_category.selected_all)
               setShowOnlineNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${
                    newsOnlineData.access_type === 2 || 
                    newsOnlineData.company.company_all || 
                    newsOnlineData.company.items.length > 0 ?
                        'btn-success' :
                        'btn-danger btn-outline'}`
            }
        }
        else if(section === 'rdc_news'){
            if(action === 'modal_closed'){
                setShowRadioNews(false)

                setNewsRdcData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: 0,
                    },
                    category: {
                        ...prevState.category,
                        items: newsCategory,
                        selected_all: 0,
                    },
                    sub_category: {
                        ...prevState.category,
                        items: newsSubCategory,
                        selected_all: newsSubCategoryChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                console.log('? Rdc News Open :', newsRdcData)
                setPermissionType(newsRdcData.access_type)
                setSelectedValue(newsRdcData.company.items)
                setNewsCategory(newsRdcData.category.items)
                setNewsSubCategory(newsRdcData.sub_category.items)
                setModalFromDate(newsRdcData.from_date)
                setModalToDate(newsRdcData.to_date)
                setCompanyChecked(newsRdcData.company_all)
                setNewsSubCategoryChecked(newsRdcData.sub_category.selected_all)
                setShowRadioNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${
                            newsRdcData.access_type === 2 || 
                            newsRdcData.company.company_all || 
                            newsRdcData.company.items.length > 0 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
        else if(section === 'tvc_news'){
            if(action === 'modal_closed'){
                setShowTVNews(false)

                setNewsTvcData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: 0,
                    },
                    category: {
                        ...prevState.category,
                        items: newsCategory,
                        selected_all: 0,
                    },
                    sub_category: {
                        ...prevState.category,
                        items: newsSubCategory,
                        selected_all: newsSubCategoryChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(newsTvcData.access_type)
                setSelectedValue(newsTvcData.company.items)
                setNewsCategory(newsTvcData.category.items)
                setNewsSubCategory(newsTvcData.sub_category.items)
                setModalFromDate(newsTvcData.from_date)
                setModalToDate(newsTvcData.to_date)
                setCompanyChecked(newsTvcData.company_all)
                setNewsSubCategoryChecked(newsTvcData.sub_category.selected_all)
                setShowTVNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${
                        newsTvcData.access_type === 2 || 
                        newsTvcData.company.company_all || 
                        newsTvcData.company.items.length > 0 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
    }
    const handleClose = (section) => {
        setShow(false)
        stateControl('modal_closed', section, modalCompanySelected)
        setModalCompanySelected([])
        setSelectedValue([])
        setShowBrand(false)
        setShowMedia(false)
    }

    const handleShow = (section) => {
        setShow(true)
        setModalTitle(section)
        stateControl('modal_open', section)
    }

    const handleInputChange = value => { }

    const handleChange = value => {
        setSelectedValue(value)
    }

    const handleChangeBrand = value => {
        setSelectedBrand(value)
    }

    const handleChangeProduct = value => {
        setSelectedProduct(value)
    }

    const handleChangeMedia = value => {
        setSelectedMedia(value)
    }

    const handleChangeAdType = value => {
        setSelectedAdType(value)
    }

    const handleChangeProductType = value => {
        setProductTypeSelectedValue(value)
    }

    const handleChangeCategory = value => {
        setNewsCategory(value)
    }

    const handleChangeSubCategory = value => {
        setNewsSubCategory(value)
    }

    const wordSplit = (search) => {
        let word = search.split(" ")
        let word_len = word.length
        if(word[word_len-1].length == 0)
            return true
        else
            return false
    }

    const loadPrintOnlineCompanyOption = async (search) => {
        if(wordSplit(search)){
            return await searchPrintOnlineCompany(search)
        }
    }

    const loadPrintOnlineBrandOption = async () => {
        if(selectedValue.length <= 0){
            console.log('You have to select company first.')
        }
        const data = await searchPrintOnlineBrand({company: selectedValue})
        let brand = data.map((item) => {
            return {value: item.brand_id, label: item.brand_name}
        })
        setBrandList(brand)
    }

    const loadPrintOnlineProductOption = async () => {
        if(productTypeSelectedValue.length <= 0){
            console.log('You have to select product type first.')
        }
        const data = await findPrintOnlineProduct({product_type: productTypeSelectedValue})
        let product = data.map((item) => {
            return {value: item.id, label: item.product_name}
        })
        setProductList(product)
    }

    const loadPrintOnlineProductType = async (search) => {
        if(wordSplit(search)){
            return await searchPrintOnlineProductType(search)
        }
    }

    const loadTVCompanyOption = async (search) => {
        if(wordSplit(search)){
            return await searchTvCompany(search)
        }
    }

    const loadMediaOption = async () => {
        const data = await tvcMediaList()
        let media = data.map((item) => {
            return {value: item.media_id, label: item.media_name}
        })

        setSelectedMedia(media)
    }

    const loadAdTypeOption = async () => {
        const data = await tvcAdTypeList()
        let ad_type = data.map((item) => {
            return {value: item.ad_type_id, label: item.ad_type_name}
        })
        setSelectedAdType(ad_type)
    }

    const loadTVBrandOption = async () => {
        if(selectedValue.length <= 0){
            console.log('You have to select company first.')
        }
        const data = await searchTVBrandByCompany({company: selectedValue})
        let brand = data.map((item) => {
            return {value: item.brand_id, label: item.brand_name}
        })
        setBrandList(brand)
    }

    const loadTvcProductType = async (search) => {
        if(wordSplit(search)){
            return await searchTvcProductType(search)
        }
    }

    const loadTvcProductOption = async () => {
        if(productTypeSelectedValue.length <= 0){
            console.log('You have to select product type first.')
        }

        const data = await findTvcProduct({product_type: productTypeSelectedValue})

        let product = data.map((item) => {
            return {value: item.product_id, label: item.product_name}
        })

        setProductList(product)
    }

    const loadRadioCompanyOption = async (search) => {
        if(wordSplit(search)){
            return await searchRdcCompany(search)
        }
    }

    const displayAccessBtn = (btn) => {
        return stateControl('show_active', btn, '')
    }
    const loadRdcBrandOption = async () => {
        if(selectedValue.length <= 0){
            console.log('You have to select company first.')
        }
        const data = await searchRdcBrandByCompany({company: selectedValue})
        let brand = data.map((item) => {
            return {value: item.brand_id, label: item.brand_name}
        })

        setBrandList(brand)
    }
    const loadRdcProductType = async (search) => {
        if(wordSplit(search)){
            return await searchRdcProductType(search)
        }
    }
    const loadRdcProductOption = async () => {
        if(productTypeSelectedValue.length <= 0){
            console.log('You have to select product type first.')
        }
        const data = await findRdcProduct({product_type: productTypeSelectedValue})
        let product = data.map((item) => {
            return {value: item.product_id, label: item.product_name}
        })
        setProductList(product)
    }
    const loadRdcMediaOption = async () => {
        const data = await rdcMediaList()
        let media = data.map((item) => {
            return {value: item.media_id, label: item.media_name}
        })
        setSelectedMedia(media)
    }
    const loadRdcAdTypeOption = async () => {
        const data = await rdcAdTypeList()
        let ad_type = data.map((item) => {
            return {
                value: item.ad_type_id, 
                label: item.ad_type_name
            }
        })
        setSelectedAdType(ad_type)
    }
    const loadNewsCompanyOption = async (search) => {
        if(wordSplit(search)){
            return await searchNewsCompany(search)
        }
    }
    const loadNewsCategoryOption = async (search) => {
        if(wordSplit(search)){
            return await searchNewsCategory(search)
        }
    }
    const loadNewsSubCategoryOption = async (search) => {
        if(newsCategory.length <= 0){
            console.log('You have to select category first.')
        }
        const data = await searchNewsSubCategory(newsCategory)
        let sub_cat = data.map((item) => {
            return {value: item.id, label: item.name}
        })
        setSubCategoryList(sub_cat)
    }
    const [serviceStatus, setServiceStatus] = React.useState({
        ad_tv: 0,
        ad_radio: 0,
        ad_print: 0,
        ad_online: 0,
        news_tv: 0,
        news_radio: 0,
        news_print: 0,
        news_online: 0
    })
	const params = useParams()
    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const permission_data = {
            parent_id: parentUser,
            start_date: expireStartDate,
            end_date: expireEndDate,
            ad_tvc: adTvcData,
            ad_rdc: adRdcData,
            ad_print: adPrintData,
            ad_online: adOnlineData,
            news_tvc: newsTvcData,
            news_rdc: newsRdcData,
            news_print: newsPrintData,
            news_online: newsOnlineData
        }
        const result = await permissionUpdate(permission_data)
        if (result) {
            setNotification(true)
            setNotificationMessage(result.message)
            if(result.status === 'fail'){
              setNotificationFlag('danger')
            } else {
              setNotificationFlag('success')
            }

            setTimeout(() => {
                setLoading(false)
                setNotification(false)
            }, 800)
        }
        return false
    }

	React.useEffect(() => {
        const fetchParentUser = async () => {
            let opt = []
            setLoading(true)
            const serviceAccess = {...serviceStatus}
			const result = await GetPermissionDetails(params.id)
            if(!result.data || result.data.length === 0) {
                window.location.href = "/permission/list"
            }
            const parentInfo = {
                value: result?.data?.client_id,
                label: result?.data?.client_name + " -- " + result?.data?.user_email    
            }
			setParentUser(parentInfo || []);
			setExpireStartDate(new Date(result?.data?.start_date * 1000))
			setExpireEndDate(new Date(result?.data?.expired_at * 1000))
            setAdTvcData(prevState => ({
                ...prevState,
                access_type: result?.data?.ad_tvc?.access_type,
                company: {
					...prevState.company,
                    selected_all: result?.data?.ad_tvc?.company?.selected_all,
					items: result?.data?.ad_tvc?.company?.item?.map(each => {
                        return {
                            company_id: each.id,
                            company_name: each.name
                        }
                    }),
				},
                brand: {
					...prevState.brand,
                    selected_all: result?.data?.ad_tvc?.brand?.selected_all,
					items: result?.data?.ad_tvc?.brand?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    }),
				},
				product_type: {
					...prevState.product_type,
					selected_all: result?.data?.ad_tvc?.product_type?.selected_all,
                    items: result?.data?.ad_tvc?.product_type?.item?.map(each => {
                        return {
                            product_type_id: each.id,
                            product_type_name: each.name
                        }
                    }),
				},
				product: {
					...prevState.product,
                    selected_all: result?.data?.ad_tvc?.product?.selected_all,
					items: result?.data?.ad_tvc?.product?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    }),
				},
                media: {
                    ...prevState.media,
                    selected_all: result?.data?.ad_tvc?.media?.selected_all,
					items: result?.data?.ad_tvc?.media?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    }),
                },
                ad_type: {
                    ...prevState.ad_type,
                    selected_all: result?.data?.ad_tvc?.ad_type?.selected_all,
                    items: result?.data?.ad_tvc?.ad_type?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    })
                }
            }))

			setAdPrintData(prevState => ({
				...prevState,
				access_type: result?.data?.ad_print?.access_type,
				company: {
					...prevState.company,
                    selected_all: result?.data?.ad_print?.company?.selected_all,
					items: result?.data?.ad_print?.company?.item?.map(each => {
                        return {
                            company_id: each.id,
                            company_name: each.name
                        }
                    }),
				},
				brand: {
					...prevState.brand,
                    selected_all: result?.data?.ad_print?.brand?.selected_all,
					items: result?.data?.ad_print?.brand?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    }),
				},
				product_type: {
					...prevState.product_type,
					selected_all: result?.data?.ad_print?.product_type?.selected_all,
                    items: result?.data?.ad_print?.product_type?.item?.map(each => {
                        return {
                            id: each.id,
                            product_type: each.name
                        }
                    }),
				},
				product: {
					...prevState.product,
                    selected_all: result?.data?.ad_print?.product?.selected_all,
					items: result?.data?.ad_print?.product?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    }),
				}
			}))

           
			setAdOnlineData(prevState => ({
				...prevState,
				access_type: result?.data?.ad_online?.access_type,
				company: {
					...prevState.company,
					selected_all: result?.data?.ad_online?.company?.selected_all,
                    items: result?.data?.ad_online?.company?.item?.map(each => {
                        return {
                            company_id: each.id,
                            company_name: each.name
                        }
                    }),
				},
				brand: {
					...prevState.brand,
					selected_all: result?.data?.ad_online?.brand?.selected_all,
                    items: result?.data?.ad_online?.brand?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    }),
				},
				product_type: {
					...prevState.product_type,
					selected_all: result?.data?.ad_online?.product_type?.selected_all,
                    items: result?.data?.ad_online?.product_type?.item?.map(each => {
                        return {
                            id: each.id,
                            product_type: each.name
                        }
                    }),
				},
				product: {
					...prevState.product,
					selected_all: result?.data?.ad_online?.product?.selected_all,
                    items: result?.data?.ad_online?.product?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    }),
				}
			}))

            setAdRdcData(prevState => ({
                ...prevState,
                access_type: result?.data?.ad_rdc?.access_type,
                company: {
					...prevState.company,
                    selected_all: result?.data?.ad_rdc?.company?.selected_all,
					items: result?.data?.ad_rdc?.company?.item?.map(each => {
                        return {
                            company_id: each.id,
                            company_name: each.name
                        }
                    }),
				},
                brand: {
					...prevState.brand,
                    selected_all: result?.data?.ad_rdc?.brand?.selected_all,
					items: result?.data?.ad_rdc?.brand?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    }),
				},
				product_type: {
					...prevState.product_type,
					selected_all: result?.data?.ad_rdc?.product_type?.selected_all,
                    items: result?.data?.ad_rdc?.product_type?.item?.map(each => {
                        return {
                            product_type_id: each.id,
                            product_type_name: each.name
                        }
                    }),
				},
				product: {
					...prevState.product,
                    selected_all: result?.data?.ad_rdc?.product?.selected_all,
					items: result?.data?.ad_rdc?.product?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    }),
				},
                media: {
                    ...prevState.media,
                    selected_all: result?.data?.ad_rdc?.media?.selected_all,
                    items: result?.data?.ad_rdc?.media?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    })
                },
                ad_type: {
                    ...prevState.ad_type,
                    selected_all: result?.data?.ad_rdc?.ad_type?.selected_all,
                    items: result?.data?.ad_rdc?.ad_type?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    })
                }
            }))

            setNewsTvcData(prevState => ({
                ...prevState,
                access_type: result?.data?.news_tvc?.access_type,
                company: {
					...prevState.company,
                    selected_all: result?.data?.news_tvc?.company?.selected_all,
					items: result?.data?.news_tvc?.company?.item?.map(each => {
                        return {
                            company_id: each.id,
                            company_name: each.name
                        }
                    }),
				},
				category: {
					...prevState.product,
                    selected_all: result?.data?.news_tvc?.category?.selected_all,
					items: result?.data?.news_tvc?.category?.item?.map(each => {
                        return {
                            id: each.id,
                            name: each.name
                        }
                    }),
				},
                sub_category: {
                    ...prevState.sub_category,
                    selected_all: result?.data?.news_tvc?.sub_category?.selected_all,
                    items: result?.data?.news_tvc?.sub_category?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    })
                }
            }))

            setNewsRdcData(prevState => ({
                ...prevState,
                access_type: result?.data?.news_rdc?.access_type,
                company: {
					...prevState.company,
                    selected_all: result?.data?.news_rdc?.company?.selected_all,
					items: result?.data?.news_rdc?.company?.item?.map(each => {
                        return {
                            company_id: each.id,
                            company_name: each.name
                        }
                    }),
				},
				category: {
					...prevState.product,
                    selected_all: result?.data?.news_rdc?.category?.selected_all,
					items: result?.data?.news_rdc?.category?.item?.map(each => {
                        return {
                            id: each.id,
                            name: each.name
                        }
                    }),
				},
                sub_category: {
                    ...prevState.sub_category,
                    selected_all: result?.data?.news_rdc?.sub_category?.selected_all,
                    items: result?.data?.news_rdc?.sub_category?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    })
                }
            }))
            
            setNewsPrintData(prevState => ({
                ...prevState,
                access_type: result?.data?.news_print?.access_type,
                company: {
					...prevState.company,
                    selected_all: result?.data?.news_print?.company?.selected_all,
					items: result?.data?.news_print?.company?.item?.map(each => {
                        return {
                            company_id: each.id,
                            company_name: each.name
                        }
                    }),
				},
				category: {
					...prevState.product,
                    selected_all: result?.data?.news_print?.category?.selected_all,
					items: result?.data?.news_print?.category?.item?.map(each => {
                        return {
                            id: each.id,
                            name: each.name
                        }
                    }),
				},
                sub_category: {
                    ...prevState.sub_category,
                    selected_all: result?.data?.news_print?.sub_category?.selected_all,
                    items: result?.data?.news_print?.sub_category?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    })
                }
            }))

            setNewsOnlineData(prevState => ({
                ...prevState,
                access_type: result?.data?.news_online?.access_type,
                company: {
					...prevState.company,
                    selected_all: result?.data?.news_online?.company?.selected_all,
					items: result?.data?.news_online?.company?.item?.map(each => {
                        return {
                            company_id: each.id,
                            company_name: each.name
                        }
                    }),
				},
				category: {
					...prevState.product,
                    selected_all: result?.data?.news_online?.category?.selected_all,
					items: result?.data?.news_online?.category?.item?.map(each => {
                        return {
                            id: each.id,
                            name: each.name
                        }
                    }),
				},
                sub_category: {
                    ...prevState.sub_category,
                    selected_all: result?.data?.news_online?.sub_category?.selected_all,
                    items: result?.data?.news_online?.sub_category?.item?.map(each => {
                        return {
                            value: each.id,
                            label: each.name
                        }
                    })
                }
            }))

            if(
                result?.data?.ad_print?.company?.item?.length > 0 ||
                result?.data?.ad_print?.product_type?.item?.length > 0 ||
                result?.data?.ad_print?.access_type === 2
            ){
                serviceAccess.ad_print = 1
            }
            
            if(
                result?.data?.ad_online?.company?.item?.length > 0 ||
                result?.data?.ad_online?.product_type?.item?.length > 0 ||
                result?.data?.ad_online?.access_type === 2
            ){
                serviceAccess.ad_online = 1
            }
            
            if(
                result?.data?.ad_tvc?.company?.item?.length > 0 ||
                result?.data?.ad_tvc?.product_type?.item?.length > 0 ||
                result?.data?.ad_tvc?.access_type === 2
            ){
                serviceAccess.ad_tv = 1
            }
            
            setServiceStatus(serviceAccess)

			setLoading(false)
        }

        fetchParentUser()
    }, [])

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:90+'vh'}}>

                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Edit Data Permission</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <span><i className="icon dripicons-home"></i></span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span>Data Service</span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/permission/list">
                                                <u>Permission List</u>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Edit Data Permission
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <h5 className="card-header">
                                        Edit Data Access Permission
                                    </h5>

                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="parent">Parent ID</label>
                                                            <div><strong>{ parentUser.label }</strong></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="firstName">Data Access Start Date</label>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={expireStartDate}
                                                                onChange={(date) => setExpireStartDate(date)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="firstName">Data Access End Date</label>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={expireEndDate}
                                                                onChange={(date) => setExpireEndDate(date)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="dashed"/>

                                                <div className="row">
                                                    <div className="col-md-2">
                                                        Control For - Ad
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown ">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('tvc_ad')}>
                                                                TV
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('tvc_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('rdc_ad')}>
                                                                Radio
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('rdc_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('print_ad')}>
                                                                    Print
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('print_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('online_ad')}>
                                                                Online
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('online_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="dashed"/>

                                                <div className="row">
                                                    <div className="col-md-2">
                                                        Control For - News
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown ">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('tvc_news')}>
                                                                TV
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('tvc_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('rdc_news')}>
                                                                Radio
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('rdc_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('print_news')}>
                                                                Print
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('print_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('online_news')}>
                                                                Online
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('online_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-light">
                                                <button type="submit" className="btn btn-primary">Save Access</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <Modal show={show} onHide={() => handleClose(modalTitle)}>
                            <Modal.Header>
                                <Modal.Title>
                                    Access Permission For - {modalTitle}
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            { showPrint ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0)?true:false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Company - Brand
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="type_product"
                                                                checked={(permissionType === 1)?true:false}
                                                                onChange={() => checkedPermissionChange(1)}
                                                            />
                                                            <label className="form-check-label" htmlFor="type_product">
                                                                Product Type - Product
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2)?true:false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadPrintOnlineCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="brand">
                                                                Select Brand
                                                            </label>
                                                            <Select
                                                                value={selectedBrand}
                                                                onFocus={loadPrintOnlineBrandOption}
                                                                defaultValue={selectedBrand}
                                                                onChange={handleChangeBrand}
                                                                options={brandList}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_brand"
                                                                checked={brandChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'brand'})}
                                                                disabled={(permissionType !== 0)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_brand">
                                                                Select All Brand
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product_type">
                                                                Select Product Type
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={productTypeSelectedValue}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                getOptionLabel={e => e.product_type}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadPrintOnlineProductType}
                                                                onChange={handleChangeProductType}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product">
                                                                Select Product
                                                            </label>
                                                            <Select
                                                                onFocus={loadPrintOnlineProductOption}
                                                                defaultValue={selectedProduct}
                                                                onChange={handleChangeProduct}
                                                                options={productList}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_product"
                                                                checked={productChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'product'})}
                                                                disabled={(permissionType !== 1)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_product">
                                                                All Product
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>
                                                </div>
                                            ) : ('')}

                                            { showOnline ? (
                                                <div className="row">

                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0)?true:false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Company - Brand
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="type_product"
                                                                checked={(permissionType === 1)?true:false}
                                                                onChange={() => checkedPermissionChange(1)}
                                                            />
                                                            <label className="form-check-label" htmlFor="type_product">
                                                                Product Type - Product
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2)?true:false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadPrintOnlineCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="brand">
                                                                Select Brand
                                                            </label>
                                                            <Select
                                                                value={selectedBrand}
                                                                onFocus={loadPrintOnlineBrandOption}
                                                                defaultValue={selectedBrand}
                                                                onChange={handleChangeBrand}
                                                                options={brandList}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_brand"
                                                                checked={brandChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'brand'})}
                                                                disabled={(permissionType !== 0)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_brand">
                                                                Select All Brand
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product_type">
                                                                Select Product Type
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={productTypeSelectedValue}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                getOptionLabel={e => e.product_type}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadPrintOnlineProductType}
                                                                onChange={handleChangeProductType}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product">
                                                                Select Product
                                                            </label>
                                                            <Select
                                                                onFocus={loadPrintOnlineProductOption}
                                                                defaultValue={selectedProduct}
                                                                onChange={handleChangeProduct}
                                                                options={productList}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_product"
                                                                checked={productChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'product'})}
                                                                disabled={(permissionType !== 1)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_product">
                                                                All Product
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>
                                                </div>
                                            ) : ('')}

                                            { showTVC ? (
                                                <div className="row">

                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0)?true:false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Company - Brand
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="type_product"
                                                                checked={(permissionType === 1)?true:false}
                                                                onChange={() => checkedPermissionChange(1)}
                                                            />
                                                            <label className="form-check-label" htmlFor="type_product">
                                                                Product Type - Product
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2)?true:false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="company">Select Company</label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadTVCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="brand">
                                                                Select Brand
                                                            </label>
                                                            <Select
                                                                value={selectedBrand}
                                                                onFocus={loadTVBrandOption}
                                                                defaultValue={selectedBrand}
                                                                onChange={handleChangeBrand}
                                                                options={brandList}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_brand"
                                                                checked={brandChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'brand'})}
                                                                disabled={(permissionType !== 0)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_brand">
                                                                All Brand
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product_type">
                                                                Select Product Type
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={productTypeSelectedValue}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                getOptionLabel={e => e.product_type_name}
                                                                getOptionValue={e => e.product_type_id}
                                                                loadOptions={loadTvcProductType}
                                                                onChange={handleChangeProductType}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product">
                                                                Select Product
                                                            </label>
                                                            <Select
                                                                onFocus={loadTvcProductOption}
                                                                defaultValue={selectedProduct}
                                                                onChange={handleChangeProduct}
                                                                options={productList}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_product"
                                                                checked={productChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'product'})}
                                                                disabled={(permissionType !== 1)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_product">
                                                                All Product
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="media">
                                                                Select Media
                                                            </label>
                                                            <Select
                                                                onFocus={loadMediaOption}
                                                                defaultValue={selectedMedia}
                                                                onChange={handleChangeMedia}
                                                                options={selectedMedia}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="select_media"
                                                                checked={mediaChecked}
                                                                onChange={() => checkedAllOption({category:'tvc_ad',type:'media'})}
                                                            />
                                                            <label className="form-check-label" htmlFor="select_media">
                                                                All Media
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="media">
                                                                Select Ad Type
                                                            </label>
                                                            <Select
                                                                onFocus={loadAdTypeOption}
                                                                defaultValue={selectedAdType}
                                                                onChange={handleChangeAdType}
                                                                options={selectedAdType}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="select_ad_type"
                                                                checked={adTypeChecked}
                                                                onChange={() => checkedAllOption({category:'tvc_ad',type:'ad_type'})}
                                                            />
                                                            <label className="form-check-label" htmlFor="select_ad_type">
                                                                All Ad Type
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            ) : ('')}

                                            { showRDC ? (
                                                <div className="row">

                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0)?true:false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Company - Brand
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="type_product"
                                                                checked={(permissionType === 1)?true:false}
                                                                onChange={() => checkedPermissionChange(1)}
                                                            />
                                                            <label className="form-check-label" htmlFor="type_product">
                                                                Product Type - Product
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2)?true:false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadRadioCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="brand">
                                                                Select Brand
                                                            </label>
                                                            <Select
                                                                value={selectedBrand}
                                                                onFocus={loadRdcBrandOption}
                                                                defaultValue={selectedBrand}
                                                                onChange={handleChangeBrand}
                                                                options={brandList}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_brand"
                                                                checked={brandChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'brand'})}
                                                                disabled={(permissionType !== 0)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_brand">
                                                                All Brand
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product_type">
                                                                Select Product Type
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={productTypeSelectedValue}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                getOptionLabel={e => e.product_type_name}
                                                                getOptionValue={e => e.product_type_id}
                                                                loadOptions={loadRdcProductType}
                                                                onChange={handleChangeProductType}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product">
                                                                Select Product
                                                            </label>
                                                            <Select
                                                                onFocus={loadRdcProductOption}
                                                                defaultValue={selectedProduct}
                                                                onChange={handleChangeProduct}
                                                                options={productList}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="rdc_all_product"
                                                                checked={productChecked}
                                                                onChange={() => checkedAllOption({category:'rdc_ad',type:'product'})}
                                                                disabled={(permissionType !== 1)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="rdc_all_product">
                                                                All Product
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="media">
                                                                Select Media
                                                            </label>
                                                            <Select
                                                                onFocus={loadRdcMediaOption}
                                                                defaultValue={selectedMedia}
                                                                onChange={handleChangeMedia}
                                                                options={selectedMedia}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="rdc_ad_all_media"
                                                                onChange={() => checkedAllOption({category:'rdc_ad',type:'media'})}
                                                                checked={mediaChecked}
                                                            />
                                                            <label className="form-check-label" htmlFor="rdc_ad_all_media">
                                                                All Media
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="media">
                                                                Select Ad Type
                                                            </label>
                                                            <Select
                                                                onFocus={loadRdcAdTypeOption}
                                                                defaultValue={selectedAdType}
                                                                onChange={handleChangeAdType}
                                                                options={selectedAdType}
                                                                isMulti
                                                            />
                                                        </div>
                                                        
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="select_ad_type"
                                                                checked={adTypeChecked}
                                                                onChange={() => checkedAllOption({category:'rdc_ad',type:'ad_type'})}
                                                            />
                                                            <label className="form-check-label" htmlFor="select_ad_type">
                                                                All ad-type
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            ) : ('')}

                                            { showTVNews ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Custom
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2) ? true : false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadNewsCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Category
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={newsCategory}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.name}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadNewsCategoryOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChangeCategory}
                                                                isMulti
                                                            />
                                                        </div>

                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Sub-Category
                                                            </label>
                                                            <Select
                                                                value={newsSubCategory}
                                                                onFocus={loadNewsSubCategoryOption}
                                                                onChange={handleChangeSubCategory}
                                                                defaultValue={newsSubCategory}
                                                                options={subCategoryList}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                isMulti
                                                            />
                                                           
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="sub_category"
                                                                    checked={newsSubCategoryChecked}
                                                                    onChange={() => checkedAllOption({category:'tv_news',type:'sub_category'})}
                                                                    disabled={(permissionType !== 0)?true:false}
                                                                />
                                                                <label className="form-check-label" htmlFor="sub_category">
                                                                    All sub-category
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            ) : ('') }

                                            { showRadioNews ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Custom
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2) ? true : false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadNewsCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Category
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={newsCategory}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.name}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadNewsCategoryOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChangeCategory}
                                                                isMulti
                                                            />
                                                        </div>

                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Sub-Category
                                                            </label>
                                                            <Select
                                                                value={newsSubCategory}
                                                                onFocus={loadNewsSubCategoryOption}
                                                                onChange={handleChangeSubCategory}
                                                                defaultValue={newsSubCategory}
                                                                options={subCategoryList}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                isMulti
                                                            />
                                                           
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="sub_category"
                                                                    checked={newsSubCategoryChecked}
                                                                    onChange={() => checkedAllOption({category:'radio_news',type:'sub_category'})}
                                                                    disabled={(permissionType !== 0)?true:false}
                                                                />
                                                                <label className="form-check-label" htmlFor="sub_category">
                                                                    All sub-category
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            ) : ('') }

                                            { showPrintNews ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Custom
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2) ? true : false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadNewsCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Category
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={newsCategory}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.name}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadNewsCategoryOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChangeCategory}
                                                                isMulti
                                                            />
                                                        </div>

                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Sub-Category
                                                            </label>
                                                            <Select
                                                                value={newsSubCategory}
                                                                onFocus={loadNewsSubCategoryOption}
                                                                onChange={handleChangeSubCategory}
                                                                defaultValue={newsSubCategory}
                                                                options={subCategoryList}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                isMulti
                                                            />
                                                           
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="sub_category"
                                                                    checked={newsSubCategoryChecked}
                                                                    onChange={() => checkedAllOption({category:'print_news',type:'sub_category'})}
                                                                    disabled={(permissionType !== 0)?true:false}
                                                                />
                                                                <label className="form-check-label" htmlFor="sub_category">
                                                                    All sub-category
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            ) : ('') }

                                            { showOnlineNews ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Custom
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2) ? true : false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadNewsCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Category
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={newsCategory}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.name}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadNewsCategoryOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChangeCategory}
                                                                isMulti
                                                            />
                                                        </div>

                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Sub-Category
                                                            </label>
                                                            <Select
                                                                value={newsSubCategory}
                                                                onFocus={loadNewsSubCategoryOption}
                                                                onChange={handleChangeSubCategory}
                                                                defaultValue={newsSubCategory}
                                                                options={subCategoryList}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                isMulti
                                                            />
                                                           
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="sub_category"
                                                                    checked={newsSubCategoryChecked}
                                                                    onChange={() => checkedAllOption({category:'online_news',type:'sub_category'})}
                                                                    disabled={(permissionType !== 0)?true:false}
                                                                />
                                                                <label className="form-check-label" htmlFor="sub_category">
                                                                    All sub-category
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            ) : ('') }

                                        </div>
                                    </div>

                                </div>
                            </Modal.Body>
                            
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => handleClose(modalTitle)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => handleClose(modalTitle)}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default PermissionEdit