import React from 'react';
import { Link } from "react-router-dom";
import Notification from '../../../helpers/Notification';
import { v4 as uuidv4 } from 'uuid';
import { fetchParentUserList } from '../../../../api/requests/user/UserRequest';
import { addSubUsers } from '../../../../api/requests/user/UserRequest';
import LoadingOverlay from 'react-loading-overlay';
import Select from "react-select";
function ChildUserAddForm(){
    let [loading, setLoading] = React.useState(false)
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [users, setUsers] = React.useState([])
    const [parentUser, setParentUser] = React.useState([])
    const [parentId, setParentId] = React.useState({ pId: 0 })
    const [inputFields, setInputFields] = React.useState([{ id: uuidv4(), email: '' }])
    const handleSubmit = async (event) => {
        event.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const childEmails = {
            parentId,
            inputFields,
        }
        
        if (childEmails.parentId.pId === 0) {
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage('MISSING ...! You have to select a Parent User.')
            setTimeout(() => {
                setNotification(false)
            }, 3000)
        } else {
            setLoading(false)
            const result = await addSubUsers(childEmails)
            if (result.code === 200) {
                setTimeout(() => {
                    setLoading(false)
                }, 2000)
                setNotification(true)
                setNotificationFlag('success')
                setNotificationMessage(result.message)
                setInputFields([{ id: uuidv4(), email: '' }])
            } 
            else {
                setLoading(false)
                setNotification(true)
                setNotificationFlag('danger')
                setNotificationMessage(result.message)
            }
        }
    }

    const handleChangeInput = (id, event) => {
        const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
                i[event.target.name] = event.target.value
            }
            return i
        })
        setInputFields(newInputFields)
    }

    const selectParent = (e) => {
        setParentId({ pId: e.value })
    }

    React.useEffect(async () => {
        setLoading(true)
        const data = async () => {
            const result = await fetchParentUserList()
            if (result) {
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            }
            setUsers(result.data)
        }
        data()
        const fetchParentUser = async () => {
            let opt = []
            setLoading(true)
            const result = await fetchParentUserList()
            if (result) {
                result.data.map((data) => {
                    let arr = {
                        value: data.uid,
                        label: `${data.full_name.first_name} ${data.full_name.last_name} -- ${data.email}`,
                    }
                    opt.push(arr);
                    return true;
                })
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            }
            setParentUser(opt)
        }

        fetchParentUser()
    }, [])

    return (
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:90+'vh'}}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">
                                    Add Sub User
                                </h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="icon dripicons-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            User
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            User List
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="card">
                                    <h5 className="card-header">Sub User Form</h5>
                                    <form onSubmit={handleSubmit}>   
                                        {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}

                                        <div className="card-body">
                                            <div className='form-group' key="parent_user">
                                                <Select
                                                    options={parentUser}
                                                    onChange={selectParent}
                                                    required
                                                    placeholder="Select Parent User"
                                                />
                                            </div>
                                            <hr/>
                                            <div className="form-group" key="child_user">
                                                {inputFields.map((inputField, index) => (
                                                    <div className="">
                                                        <label>Sub User Email List</label>
                                                        <div className="row">
                                                            <div className="col-sm-10">
                                                                <textarea
                                                                    className="form-control"
                                                                    rows="5"
                                                                    cols="10"
                                                                    type="email"
                                                                    name="email"
                                                                    value={inputField.email}
                                                                    required
                                                                    onChange={(event) => {
                                                                        handleChangeInput(inputField.id, event)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                            <div className="card-footer bg-light" key="store_sub_users">
                                                <button type="submit" className="btn btn-primary">
                                                    <i className="la la-check text-white"></i>
                                                    Save Sub User
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </LoadingOverlay>

        </>
    )
}

export default ChildUserAddForm