import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const PermissionList = async (sorted='') => {
    try{
      const params = new URLSearchParams()
      params.append('sort_by', sorted)
      const result = await AxiosInstance.post(EndPoints.access_permission_list, params)
                    .then((res) => {
                      return SuccessResponse(res)
                    })
                    .catch((err) => {
                      return ErrorResponse(err)
                    })
      return result
    } catch(err) {
      return []
    }
}

export const PermissionListAdvanced = async (sorted='') => {
  try{
    const params = new URLSearchParams()
    params.append('sort_by', sorted)
    const result = await AxiosInstance.post(EndPoints.access_permisssion_list_advanced, params)
                  .then((res) => {
                    return SuccessResponse(res)
                  })
                  .catch((err) => {
                    return ErrorResponse(err)
                  })
    return result
  } catch(err) {
    console.log('Error :: ', err)
    return []
  }
}
export const fetchDataAccessByUId = async (uid=0) => {
  try {
    const params = new URLSearchParams();
    params.append('uid', uid);
    
    const result = await AxiosInstance.post(EndPoints.access_permission_list_by_uid, params)
                  .then((res) => {
                    return SuccessResponse(res)
                  })
                  .catch((err) => {
                    return ErrorResponse(err)
                  })
    return result

  } catch(err) {
    return [];
  }
}

export const GetPermissionDetails = async (id) => {
  try {
    const params = new URLSearchParams()
    params.append('doc_id', id)
    const result = await AxiosInstance.post(EndPoints.access_permission_details, params)
                  .then((res) => {
                    return SuccessResponse(res)
                  })
                  .catch((err) => {
                    return ErrorResponse(err)
                  })
    return result
  } catch (err) {
    return []
  }
}

export const DeletePermission = async (doc_id, parent_id) => {
  try {
    const params = new URLSearchParams()
    params.append('doc_id', doc_id)
    params.append('parent_id', parent_id)
    const result = await AxiosInstance.post(EndPoints.access_permission_delete, params)
                  .then((res) => {
                    return SuccessResponse(res)
                  })
                  .catch((err) => {
                    return ErrorResponse(err)
                  })
    return result
  } catch (err) {
    return []
  }
}

export const searchAccessPermission = async (search_q) => {
  const params = new URLSearchParams()
  params.append('search_q', search_q)

  const result = await AxiosInstance.post(EndPoints.access_permission_search_advanced, params)
      .then((res) => {
          return SuccessResponse(res)
      })
      .catch((err) => {
          return ErrorResponse(err)
      })
      
  return result
}

export const fetchPermission = async (doc_id) => {
  const params = new URLSearchParams();
  params.append('id', doc_id);
  const result = await AxiosInstance.post(EndPoints.access_permission_list_by_doc_id, params)
      .then((res) => {
          return SuccessResponse(res)
      })
      .catch((err) => {
          return ErrorResponse(err)
      })
      
  return result
}
