import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const createNewAlert = async (data) => {
  const params = new URLSearchParams()
  params.append('name', data.title)
  params.append('desc', data.details)
  params.append('uid', data.client)
  params.append('subusers', data.subusers)
  params.append('pdf[]', data.pdf)
  params.append('zip[]', data.zip)
  params.append('xls[]', data.excel)
  params.append('ppt[]', data.ppt)

  const result = await AxiosInstance.post(EndPoints.create_alert, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}
export const createNewOtherServiceAlert = async (data) => {
  const params = new URLSearchParams()
  params.append('name', data.title)
  params.append('desc', data.details)
  params.append('uid', data.client)
  params.append('subusers', data.subusers)
  params.append('pdf[]', data.pdf)
  params.append('zip[]', data.zip)
  params.append('xls[]', data.excel)
  params.append('ppt[]', data.ppt)

  const result = await AxiosInstance.post(EndPoints.create_other_service, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })
  return result
}
