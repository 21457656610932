import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const addCompany = async (data) => {
    const params = new URLSearchParams()
    params.append('companyid', data.companyID)
    params.append('companyname', data.companyName)
    params.append('advcode', data.adv_code)

    const result = await AxiosInstance.post(EndPoints.create_company, params)
        .then((res) => {
            return SuccessResponse(res)
        })
        .catch((err) => {
            return ErrorResponse(err)
        })

    return result
}
export const companyList = async () => {
    const result = await AxiosInstance.post(EndPoints.company_list)
    .then((res) => {
       return SuccessResponse(res)
    })
    .catch((err) => {
       return ErrorResponse(err)
    })
 
   return result
}
export const companyAllList = async () => {
    const result = await AxiosInstance.post(EndPoints.company_all_list)
    .then((res) => {
       return SuccessResponse(res)
    })
    .catch((err) => {
       return ErrorResponse(err)
    })
 
   return result
}

export const searchCompanyByQuery = async (data) => {
   const params = new URLSearchParams()
   params.append('search_q', data.search_q)
   const result = await AxiosInstance.post(EndPoints.company_search_q, params)
    .then((res) => {
       return SuccessResponse(res)
    })
    .catch((err) => {
       return ErrorResponse(err)
    })
   return result
}
export const searchCompany = async (data) => {
    const params = new URLSearchParams()
    params.append('search_query', data.search_query)
    params.append('search_by', data.search_by)
    const result = await AxiosInstance.post(EndPoints.company_search_by, params)
    .then((res) => {
       return SuccessResponse(res)
    })
    .catch((err) => {
       return ErrorResponse(err)
    })
    return result
}
export const updateCompanyData = async (data, id) => {
    const params = new URLSearchParams()
    params.append('key', '_id')
    params.append('value', id)
    params.append('company_id', data.company_id)
    params.append('company_name', data.company_name)
    params.append('adv_code', data.adv_code)
 
    const result = await AxiosInstance.post(EndPoints.update_company, params)
    .then((res) => {
       return SuccessResponse(res)
    })
    .catch((err) => {
       return ErrorResponse(err)
    })
    return result
}
 