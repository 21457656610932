import React from 'react'
import { Link } from "react-router-dom"
import {
  PermissionListAdvanced,
  DeletePermission,
  searchAccessPermission } from '../../../../api/requests/data-access/DataAccessRequest';
import { ReadableDateTime } from '../../../../helpers/DateTime';
import LoadingOverlay from 'react-loading-overlay';
import Notification from '../../../helpers/Notification';
import { Modal, Button } from 'react-bootstrap';
import { 
  fetchSubUsers
} from '../../../../api/requests/user/UserRequest';

const PermissionAdvancedList = () => {
    let [loading, setLoading] = React.useState(false);
    const [notification, setNotification] = React.useState(false);
    const [notificationFlag, setNotificationFlag] = React.useState('');
    const [notificationMessage, setNotificationMessage] = React.useState('');
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [alert, setAlert] = React.useState([]);
    const [result, setResult] = React.useState([]);
    const [removeId, setRemoveId] = React.useState(0);
    const [parentId, setParentId] = React.useState(0);
    const [subUsers, setSubUsers] = React.useState([]);
    const [fetchUsers, setFetchUsers] = React.useState([]);
    const [allPermission, setAllPermission] = React.useState([]);
    const [permissionSearch, setPermissionSearch] = React.useState("");

    React.useEffect(() => {
      const data = async () => {
        setLoading(true);
        const access_list = await PermissionListAdvanced();
        if (access_list) {
            setTimeout(() => {
                setAlert(access_list.data);
                setAllPermission(access_list.data);
                setLoading(false);
            }, 800);
        } else {
            setLoading(false);
        }
      }
      data();
    }, [])
    const removePermission = async () => {
      try {
        setLoading(true)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const result = await DeletePermission(removeId, parentId)

        if (result) {
            setTimeout(() => {
                setLoading(false)
            }, 800)
            setNotification(true)
            setNotificationFlag('success')
            setNotificationMessage(result.message)
            const re_alert_list = alert.filter((item) => {
              if(item._id !== removeId) return item
            })
            setAlert(re_alert_list)
        }

        setTimeout(() => {
          setNotification(false)
        }, 2000)
        return result
      } catch(err) {
        console.log(err.message)
        return false
      }
    }
    const addRemoveId = (item) => {
      setRemoveId(item._id)
      setParentId(item.client_id)
      return true
    }

    const viewSubUsers = async (id) => {
      setShowModal(true)
      const result = await fetchSubUsers(id)
      setSubUsers(result.data);
    }

    const handleAccessSearch = async (e) => {
      e.preventDefault();
      setLoading(true);
      if (permissionSearch.length > 0) {
          const result = await searchAccessPermission(permissionSearch);
          setAlert(result.data);
      }
      setLoading(false);
    }
  
    const handleReset = (e) => {
      e.preventDefault();
      setPermissionSearch("");
      setAlert(allPermission);
    }

    return (
      <>
        <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
          <div style={{minHeight:90+'vh'}}>
            
            <header className="page-header">
                <div className="d-flex align-items-center">
                    <div className="mr-auto">
                        <h1 className="separator">Advanced Data Access Permission</h1>
                        <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <span><i className="icon dripicons-home"></i></span>
                                </li>
                                <li className="breadcrumb-item">
                                    <span>Data Service</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Permission List
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </header>

            <section className="page-content container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <form className="card-header">
                                <span>
                                  Client Name or Email
                                </span>
                                <input
                                    type="text"
                                    style={{ margin: "0 1rem" }}
                                    value={permissionSearch}
                                    onChange={(e) =>
                                      setPermissionSearch(e.target.value)
                                    }
                                />
                                <button
                                    type="submit"
                                    className="btn-primary"
                                    style={{ marginRight: "1rem" }}
                                    onClick={handleAccessSearch}
                                >
                                    Search
                                </button>
                                <button
                                    className="btn-secondary"
                                    onClick={handleReset}
                                >
                                    Reset
                                </button>
                            </form>
                            <div className="card-body">
                                {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Client</th>
                                                <th>Email</th>
                                                <th>Number of Permissions</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {alert.length ? alert.map((item) => (
                                                <tr key={item._id}>
                                                    <td>{`${item.full_name.first_name} ${item.full_name.last_name}`}</td>
                                                    <td>
                                                      <strong>{item.email}</strong>
                                                    </td>
                                                    <td>{item.totalAccess}</td>
                                                    <td>
                                                        <Link
                                                            to={`/permission/list/advanced/all/details/${item.uid}`}
                                                            className="badge badge-pill badge-primary"
                                                            title="Access details">
                                                            <i className="la la-eye" style={{color: '#fff'}}></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )) : 'Data Access Permission Not Found'}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
                              <Modal.Header>
                                  <Modal.Title>
                                      Sub Users List
                                  </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="table-responsive">
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {subUsers.length > 0 
                                      ? subUsers.map((data) => (
                                      <tr key={data.id}>
                                          <td>{data.uid}</td>
                                          <td>{data.email}</td>
                                          <td>{data.status == 1 ? 'Active' : 'Inactive'}</td>
                                          <td>{ReadableDateTime(data.created_at)}</td>
                                          <td>
                                            <Link to={`/user/sub-user/service-access/${data.uid}`}>
                                              <span
                                                className="badge badge-pill badge-warning"
                                                title="Edit User Permission">
                                                <i className="zmdi zmdi-settings zmdi-hc-fw" style={{color: '#fff'}}></i>
                                              </span>
                                            </Link>
                                          </td>
                                      </tr>
                                      )) : 'No sub user found'}
                                    </tbody>
                                  </table>
                                </div>  
                              </Modal.Body>
                              <Modal.Footer>
                                  <Button variant="primary" onClick={() => setShowModal(false)}>
                                      Close
                                  </Button>
                              </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade bd-example-modal-sm" tabIndex="-1" role="dialog" aria-hidden="true">
        			<div className="modal-dialog modal-sm">
        				<div className="modal-content">
        					<div className="modal-header">
        						<h5 className="modal-title" id="ModalTitle4">Remove Permission</h5>
        						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
        							<span aria-hidden="true" className="zmdi zmdi-close"></span>
        						</button>
        					</div>
        					<div className="modal-body">
        						<p>Do you want to delete selected user data access permission ?</p>
        					</div>
        					<div className="modal-footer">
        						<button type="button" className="btn btn-success btn-outline" data-dismiss="modal">No</button>
        						<button type="button" className="btn btn-danger" data-dismiss="modal" onClick={removePermission}>Yes</button>
        					</div>
        				</div>
        			</div>
        		</div>

          </div>
        </LoadingOverlay>
      </>
    )
  }

export default PermissionAdvancedList;
