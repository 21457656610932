import React from 'react'
import ChangePassword from './ChangePassword';

const AdminProfile = () => {
    
    return(
        <> 
            {/* page header */}
            <header className="page-header">
                <div className="d-flex align-items-center">
                    <div className="mr-auto">
                        <h1 className="separator">My Account</h1>
                        <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <span><i className="zmdi zmdi-format-align-justify zmdi-hc-fw"></i></span>
                                </li>
                                <li className="breadcrumb-item">
                                    <span>Profile</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </header>

            {/* page content */}
            <section className="page-content container-fluid">
                <div className="row">
                	<div className="col">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-12 col-lg-3">
										<div className="nav flex-column nav-pills" id="my-account-tabs" role="tablist" aria-orientation="vertical">
											<a className="nav-link active" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="true">Profile</a>
											<a className="nav-link" id="v-pills-password-tab" data-toggle="pill" href="#v-pills-password" role="tab" aria-controls="v-pills-password" aria-selected="false">
												Change Password
											</a>
										</div>
									</div>
									<div className="col-md-12 col-lg-9">
										<div className="tab-content" id="my-account-tabsContent">

											{/* update profile section */}
											<div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
												<h4 className="card-heading p-b-20">Profile</h4>
												<form>
													<div className="form-group">
														<img src="../assets/img/avatars/1.jpg" className="w-50 rounded-circle" alt="Albert Einstein" />
														<div className="file-upload">
															<label htmlFor="upload" className="btn btn-primary m-b-0 m-l-5 m-r-5">Upload a new picture</label>
															<input id="upload" className="file-upload__input" type="file" name="file-upload" />
														</div>
														<button className="btn btn-secondary">Delete</button>
													</div>
													<div className="form-group">
														<label htmlFor="inputName">Your name</label>
														<input type="text" className="form-control" id="inputName" placeholder="Enter your name" />
													</div>
													
													<button type="submit" className="btn btn-primary">Update Profile</button>
												</form>
											</div>

											{/* set new password section */}
											<div className="tab-pane fade" id="v-pills-password" role="tabpanel" aria-labelledby="v-pills-password-tab">
												<h4 className="card-heading p-b-20">Change Password</h4>
												<ChangePassword />
											</div>

											</div>
										</div>
									</div>
							</div>
						</div>
					</div>
				</div>
            </section>
        </>
    )
}

export default AdminProfile
