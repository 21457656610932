import React, { useState } from "react";
import Cookies from "universal-cookie";
import LoginComponent from "../auth/Loginpage";
import SidebarComponent from "./Sidebar";
import ContentComponent from "./Content";
import Authapi from "../../context/Authapi";

const cookies = new Cookies();

function Index() {
    let auth_status;
    let role = 0;

    const session_data = cookies.get("adata");
    if (session_data) {
        if (
            session_data.id &&
            session_data.uid &&
            session_data.userName &&
            session_data.email &&
            session_data.token
        ) {
            auth_status = true;
            role = session_data.role;
        } else {
            auth_status = false;
            role = 0;
        }
    } else {
        auth_status = false;
        role = 0;
    }

    const [auth, setAuth] = useState(auth_status);

    const authControl = () => setAuth(auth ? true : false);

    if (auth) {
        return (
            <>
                <Authapi.Provider value={role}>
                    <div id="app">
                        <SidebarComponent />
                        <ContentComponent />
                    </div>
                </Authapi.Provider>
            </>
        );
    } else {
        return <LoginComponent />;
    }
}

export default Index;
