import AxiosInstance from '../Index';
import EndPoints from '../../end-points/EndPoints';
import { SuccessResponse, ErrorResponse } from '../../helpers/Request';
export const rdcCompanyList = async (page_no) => {
    const params = new URLSearchParams();
    params.append('page', page_no);
    const result = await AxiosInstance.post(EndPoints.rdc_company_list, params)
        .then((res) => {
            return SuccessResponse(res)
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    return result
}
export const searchRdcCompany = async (payload) => {
    const params = new URLSearchParams();
    params.append('search_q', payload);
    const result = await AxiosInstance.post(EndPoints.search_rdc_company, params)
        .then((res) => {
            const result = SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    return result
}
