import React from 'react'
import { Link } from "react-router-dom"
import { Message } from '../../../../helpers/Constant'
import Notification from '../../../helpers/Notification'
import { 
    fetchUserServiceAccess,
    changeServiceAccessStatus 
} from '../../../../api/requests/user/UserRequest'
import LoadingOverlay from 'react-loading-overlay'
function ServiceAccess(){
    let [loading, setLoading] = React.useState(false)

    const btn_style = {minWidth: "75%"}
    const [validated, setValidated] = React.useState(false)
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')

    const [adService, setAdService] = React.useState({tv: 0, radio: 0, print: 0, online: 0})
    const [newsService, setNewsService] = React.useState({tv: 0, radio: 0, print: 0, online: 0})

    React.useEffect(() => {
      const data = async () => {
        setLoading(true)
        
        const user_id = window.location.href.split("/")[6]

        const activity_info = await fetchUserServiceAccess(user_id)
        
        if(activity_info){
            setAdService(activity_info.result.data[0].data_service.ad)
            setNewsService(activity_info.result.data[0].data_service.news)
        }

        setTimeout(() => {
            setLoading(false)
        }, 2000)

      }
      data()
    }, [])

   
    const changeStatus = async (type, key, value) => {
        setLoading(true)

        const user_id = window.location.href.split("/")[6]
        const status = await changeServiceAccessStatus({user_id, type, key, value})

        if(status){
            if(type === 'ad'){
                if(key === 'tv') setAdService(prevState => ({...prevState, tv: !adService[key]} ))
                if(key === 'radio') setAdService(prevState => ({...prevState, radio: !adService[key]} ))
                if(key === 'print') setAdService(prevState => ({...prevState, print: !adService[key]} ))
                if(key === 'online') setAdService(prevState => ({...prevState, online: !adService[key]} ))
            } else if(type === 'news'){
                if(key === 'tv') setNewsService(prevState => ({...prevState, tv: !newsService[key]} ))
                if(key === 'radio') setNewsService(prevState => ({...prevState, radio: !newsService[key]} ))
                if(key === 'print') setNewsService(prevState => ({...prevState, print: !newsService[key]} ))
                if(key === 'online') setNewsService(prevState => ({...prevState, online: !newsService[key]} ))
            }
        }

        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    return (
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:90+'vh'}}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">
                                    Service Access
                                </h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="icon dripicons-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/user/add">
                                                User
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            User List
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <h5 className="card-header">
                                        Configure User Service Access
                                    </h5>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <h5>Data Service</h5>
                                                        </td>
                                                        <td>
                                                            <div className="col-md-12">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="text-center">
                                                                                    <button 
                                                                                        className={`btn ${adService.tv ? 'btn-success':'btn-danger'}`} style={btn_style}
                                                                                        
                                                                                    >
                                                                                        Ad - Tv
                                                                                    </button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <button 
                                                                                        className={`btn ${adService.radio ? 'btn-success':'btn-danger'}`} style={btn_style}
                                                                                        
                                                                                    >
                                                                                        Ad - Radio
                                                                                    </button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <button 
                                                                                        className={`btn ${adService.print ? 'btn-success':'btn-danger'} `} style={btn_style}
                                                                                        
                                                                                    >
                                                                                        Ad - Print
                                                                                    </button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <button 
                                                                                        className={`btn ${adService.online ? 'btn-success':'btn-danger'} `} style={btn_style}
                                                                                        
                                                                                    >
                                                                                        Ad - Online
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-center">
                                                                                    <button 
                                                                                        className={`btn ${newsService.tv ? 'btn-success':'btn-danger'}`} style={btn_style}
                                                                                        
                                                                                    >
                                                                                        News - Tv
                                                                                    </button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <button 
                                                                                        className={`btn ${newsService.radio ? 'btn-success':'btn-danger'} `} style={btn_style}
                                                                                        
                                                                                    >
                                                                                        News - Radio
                                                                                    </button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <button 
                                                                                        className={`btn ${newsService.print ? 'btn-success':'btn-danger'} `} style={btn_style}
                                                                                        
                                                                                    >
                                                                                        News - Print
                                                                                    </button>
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <button 
                                                                                        className={`btn ${newsService.online ? 'btn-success':'btn-danger'} `} style={btn_style}
                                                                                        
                                                                                    >
                                                                                        News - Online
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </LoadingOverlay>

        </>
    )
}

export default ServiceAccess
