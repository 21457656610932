export const log = (type='', msg='') => {

    switch(type){
        case 'success':
            console.log(`✅  ${msg}`)
            break;
        case 'error':
            console.error(`⛔  ${msg}`)
            break;
        default:
            console.log(`❕  ${msg}`)
    }

    return true
}