import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Notification from '../../../helpers/Notification';
import LoadingOverlay from 'react-loading-overlay';
import { fetchPermission } from '../../../../api/requests/data-access/DataAccessRequest';
import "react-datepicker/dist/react-datepicker.css";
import { 
    fetchParentUserList, 
    advancePermission 
} from '../../../../api/requests/user/UserRequest';
import TvcAdEditForm from './../permission-add/advanced-permission/ad/forms/TvcAdEditForm';
import RdcAdForm from './../permission-add/advanced-permission/ad/forms/RdcAdForm';
import PrintAdForm from './../permission-add/advanced-permission/ad/forms/PrintAdForm';
import OnlineAdForm from './../permission-add/advanced-permission/ad/forms/OnlineAdForm';
import TvcNewsForm from './../permission-add/advanced-permission/news/forms/TvcNewsForm';
import RdcNewsForm from './../permission-add/advanced-permission/news/forms/RdcNewsForm';
import PrintNewsForm from './../permission-add/advanced-permission/news/forms/PrintNewsForm';
import OnlineNewsForm from './../permission-add/advanced-permission/news/forms/OnlineNewsForm';
const input_error = {
    borderColor: "#ff5c75"
}
const PermissionEditAdvanced = () => {
    let [loading, setLoading] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [notification, setNotification] = React.useState(false);
    const [notificationFlag, setNotificationFlag] = React.useState('');
    const [notificationMessage, setNotificationMessage] = React.useState('');
    const [inputError, setInputError] = React.useState(false);
    const doc_id = window.location.href.split("/")[6];
    const [tvcAdData, setTvcAdData] = React.useState({
        tv_ad: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            brand_select: 0,
            brand_list: [],
            product_type_list: [],
            product_type_select: 0,
            product_select: 0,
            product_list: [],
            media_select: 0,
            media_list: [],
            media_ad_type_select: 0,
            media_ad_type_list: [],
        }
    });
    const [rdcAdData, setRdcAdData] = React.useState({
        rdc_ad: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            brand_select: 0,
            brand_list: [],
            product_type_list: [],
            product_type_select: 0,
            product_select: 0,
            product_list: [],
            media_select: 0,
            media_list: [],
            media_ad_type_select: 0,
            media_ad_type_list: [],
        }
    });
    const [printAdData, setPrintAdData] = React.useState({
        print_ad: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            brand_select: 0,
            brand_list: [],
            product_type_list: [],
            product_type_select: 0,
            product_select: 0,
            product_list: []
        }
    });
    const [onlineAdData, setOnlineAdData] = React.useState({
        online_ad: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            brand_select: 0,
            brand_list: [],
            product_type_list: [],
            product_type_select: 0,
            product_select: 0,
            product_list: [],
            media_select: 0,
            media_list: [],
            media_ad_type_select: 0,
            media_ad_type_list: [],
        }
    });
    const [tvcNewsData, setTvcNewsData] = React.useState({
        tvc_news: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            category_list: [],
            category_select: 0,
            sub_category_list: [],
            sub_category_select: 0
        }
    });
    const [rdcNewsData, setRdcNewsData] = React.useState({
        rdc_news: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            category_list: [],
            category_select: 0,
            sub_category_list: [],
            sub_category_select: 0
        }
    });
    const [printNewsData, setPrintNewsData] = React.useState({
        print_news: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            category_list: [],
            category_select: 0,
            sub_category_list: [],
            sub_category_select: 0
        }
    });
    const [onlineNewsData, setOnlineNewsData] = React.useState({
        online_news: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            category_list: [],
            category_select: 0,
            sub_category_list: [],
            sub_category_select: 0
        }
    });
    const [showPrint, setShowPrint] = React.useState(false);
    const [showOnline, setShowOnline] = React.useState(false);
    const [showRDC, setShowRDC] = React.useState(false);
    const [showTVC, setShowTVC] = React.useState(false);
    const [showTVNews, setShowTVNews] = React.useState(false);
    const [showRadioNews, setShowRadioNews] = React.useState(false);
    const [showPrintNews, setShowPrintNews] = React.useState(false);
    const [showOnlineNews, setShowOnlineNews] = React.useState(false);
    const [parentUser, setParentUser] = React.useState([]);
    const [modalCompanySelected, setModalCompanySelected] = React.useState([]);
    const [expireStartDate, setExpireStartDate] = React.useState(new Date());
    const [expireEndDate, setExpireEndDate] = React.useState(new Date());
    const [modalTitle, setModalTitle] = React.useState('');
    const [show, setShow] = React.useState(false);
    const stateControl = (action, section, data='') => {
        if(section === 'print_ad'){
            if (action === 'modal_closed'){
                setShowPrint(false)
            }
            else if(action === 'modal_open'){
                setShowPrint(true)
            }
            else if(action === 'show_active'){
                return `btn ${  printAdData.print_ad.child_all || 
                                printAdData.print_ad.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'online_ad'){
            if(action === 'modal_closed'){
                setShowOnline(false)
            }
            else if(action === 'modal_open'){
                setShowOnline(true)
            }
            else if(action === 'show_active'){
                return `btn ${  onlineAdData.online_ad.child_all ||
                                onlineAdData.online_ad.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'rdc_ad'){
            if(action === 'modal_closed'){
                setShowRDC(false)
            }
            else if(action === 'modal_open'){
                setShowRDC(true)
            }
            else if(action === 'show_active'){
                return `btn ${  rdcAdData.rdc_ad.child_all || 
                                rdcAdData.rdc_ad.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'tvc_ad'){
            if(action === 'modal_closed'){
                setShowTVC(false)
            }
            else if(action === 'modal_open'){
                setShowTVC(true)
            }
            else if(action === 'show_active'){
                return `btn ${  tvcAdData.tv_ad.child_all ||
                                tvcAdData.tv_ad.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'tv_news'){
            if(action === 'modal_closed'){
                setShowTVNews(false)
            }
            else if(action === 'modal_open'){
                setShowTVNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${  tvcNewsData.tvc_news.child_all ||
                                tvcNewsData.tvc_news.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'radio_news'){
            if(action === 'modal_closed'){
                setShowRadioNews(false)
            }
            else if(action === 'modal_open'){
                setShowRadioNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${  rdcNewsData.rdc_news.child_all || 
                                rdcNewsData.rdc_news.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'print_news'){
            if(action === 'modal_closed'){
                setShowPrintNews(false)
            }
            else if(action === 'modal_open'){
                setShowPrintNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${  printNewsData.print_news.child_all ||
                                printNewsData.print_news.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'online_news'){
            if(action === 'modal_closed'){
                setShowOnlineNews(false)
            }
            else if(action === 'modal_open'){
                setShowOnlineNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${  onlineNewsData.online_news.child_all ||
                                onlineNewsData.online_news.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
    }
    const handleClose = (section) => {
        setShow(false)
        stateControl('modal_closed', section, modalCompanySelected)
    }

    const handleShow = (section) => {
        setShow(true)
        setModalTitle(section)
        stateControl('modal_open', section)
    }
 
    const displayAccessBtn = (btn) => {
        return stateControl('show_active', btn, '')
    }  

    const dateTimeStamp = (date_string) => {
        const date = new Date(date_string)
        return Math.floor(date.getTime() / 1000)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if(title.trim() == '' ){
            setNotification(true);
            setNotificationFlag('danger');
            setNotificationMessage("Alert title can't be empty.");
            setInputError(true);
            setTimeout(() => {
                setNotification(false);
                setLoading(false);
            }, 1500)
            return false;
        }
        const permission_data = {
            doc_id,
            title,
            parent_id: parentUser,
            start_date: expireStartDate,
            end_date: expireEndDate,
            print_ad: printAdData.print_ad,
            online_ad: onlineAdData.online_ad,
            rdc_ad: rdcAdData.rdc_ad,
            tv_ad: tvcAdData.tv_ad,
            print_news: printNewsData.print_news,
            online_news: onlineNewsData.online_news,
            rdc_news: rdcNewsData.rdc_news,
            tvc_news: tvcNewsData.tvc_news
        }
        const result = await advancePermission(permission_data, 'update')
        if (result) {
            setNotification(true)
            setNotificationMessage(result.message)
            if(result.status === 'fail'){
              setNotificationFlag('danger')
            } else {
              setNotificationFlag('success')
            }
            setTimeout(() => {
                setLoading(false)
                setNotification(false)
            }, 800)
        }
        return false
    }

    React.useEffect(() => {
        const fetch_permission = async () => {
            setLoading(true)
            await fetchPermission(doc_id)
            .then( (data) => {
                const access_data = data?.data[0];
                
                if(access_data){
                    setExpireStartDate(new Date(access_data['start_date'] * 1000));
                    setExpireEndDate(new Date(access_data['expired_at'] * 1000));
                    setTitle(access_data['title']);
                    setParentUser(access_data?.user_details);

                    setTvcAdData({
                        tv_ad: {
                            start_date: access_data.tv_ad.start_date === 0 ? 
                                        new Date(access_data['start_date'] * 1000) : 
                                        new Date(access_data.tv_ad.start_date * 1000),
                            end_date: access_data.tv_ad.end_date === 0 ? 
                                        new Date(access_data['expired_at'] * 1000) : 
                                        new Date(access_data.tv_ad.end_date * 1000),
                            is_local: access_data.tv_ad.is_local,
                            child_all: access_data.tv_ad.child_all,
                            child_list: access_data.tv_ad?.child_list || [],
                            company_list: access_data.tv_ad?.company_list || [],
                            brand_select: access_data.tv_ad?.brand_select || 0,
                            brand_list: access_data.tv_ad?.brand_list || [],
                            product_type_list: access_data.tv_ad?.product_type_list || [],
                            product_type_select: access_data.tv_ad?.product_type_select || 0,
                            product_select: access_data.tv_ad?.product_select || 0,
                            product_list: access_data.tv_ad?.product_list || [],
                            media_select: access_data.tv_ad?.media_select || 0,
                            media_list: access_data.tv_ad?.media_list || [],
                            media_ad_type_select: access_data.tv_ad?.media_ad_type_select || 0,
                            media_ad_type_list: access_data.tv_ad?.media_ad_type_list || []
                        }
                    });

                    setRdcAdData({
                        rdc_ad: {
                            start_date: access_data.rdc_ad.start_date === 0 ? 
                                        new Date(access_data['start_date'] * 1000) : 
                                        new Date(access_data.rdc_ad.start_date * 1000),
                            end_date: access_data.rdc_ad.end_date === 0 ? 
                                        new Date(access_data['expired_at'] * 1000) : 
                                        new Date(access_data.rdc_ad.end_date * 1000),
                            is_local: access_data?.rdc_ad?.is_local || false,
                            child_all: access_data?.rdc_ad?.child_all || 0,
                            child_list: access_data?.rdc_ad?.child_list || [],
                            company_list: access_data?.rdc_ad?.company_list || [],
                            brand_select: access_data?.rdc_ad?.brand_select || 0,
                            brand_list: access_data?.rdc_ad?.brand_list || [],
                            product_type_list: access_data?.rdc_ad?.product_type_list || [],
                            product_type_select: access_data?.rdc_ad?.product_type_select || 0,
                            product_select: access_data?.rdc_ad?.product_select || 0,
                            product_list: access_data?.rdc_ad?.product_list || [],
                            media_select: access_data?.rdc_ad?.media_select || 0,
                            media_list: access_data?.rdc_ad?.media_list || [],
                            media_ad_type_select: access_data?.rdc_ad?.media_ad_type_select || 0,
                            media_ad_type_list: access_data?.rdc_ad?.media_ad_type_list || []
                        }
                    });

                    setPrintAdData({
                        print_ad: {
                            start_date: access_data?.print_ad?.start_date === 0 ? 
                                        new Date(access_data['start_date'] * 1000) : 
                                        new Date(access_data.print_ad.start_date * 1000),
                            end_date: access_data?.print_ad?.end_date === 0 ? 
                                        new Date(access_data['expired_at'] * 1000) : 
                                        new Date(access_data.print_ad.end_date * 1000),
                            is_local: access_data?.print_ad?.is_local || false,
                            child_all: access_data?.print_ad?.child_all || 0,
                            child_list: access_data?.print_ad?.child_list || [],
                            company_list: access_data?.print_ad?.company_list || [],
                            brand_select: access_data?.print_ad?.brand_select || 0,
                            brand_list: access_data?.print_ad?.brand_list || [],
                            product_type_list: access_data?.print_ad?.product_type_list || [],
                            product_type_select: access_data?.print_ad?.product_type_select || 0,
                            product_select: access_data?.print_ad?.product_select || 0,
                            product_list: access_data?.print_ad?.product_list || []
                        }
                    });

                    setOnlineAdData({
                        online_ad: {
                            start_date: access_data?.online_ad?.start_date === 0 ? 
                                        new Date(access_data['start_date'] * 1000) : 
                                        new Date(access_data.online_ad.start_date * 1000),
                            end_date: access_data?.online_ad?.end_date === 0 ? 
                                        new Date(access_data['expired_at'] * 1000) : 
                                        new Date(access_data.online_ad.end_date * 1000),
                            is_local: access_data?.online_ad?.is_local || false,
                            child_all: access_data?.online_ad?.child_all || 0,
                            child_list: access_data?.online_ad?.child_list || [],
                            company_list: access_data?.online_ad?.company_list || [],
                            brand_select: access_data?.online_ad?.brand_select || 0,
                            brand_list: access_data?.online_ad?.brand_list || [],
                            product_type_list: access_data?.online_ad?.product_type_list || [],
                            product_type_select: access_data?.online_ad?.product_type_select || 0,
                            product_select: access_data?.online_ad?.product_select || 0,
                            product_list: access_data?.online_ad?.product_list || [],
                            media_select: access_data?.online_ad?.media_select || 0,
                            media_list: access_data?.online_ad?.media_list || [],
                            media_ad_type_select: access_data?.online_ad?.media_ad_type_select || 0,
                            media_ad_type_list: access_data?.online_ad?.media_ad_type_list || [],
                        }
                    });

                    setTvcNewsData({
                        tvc_news: {
                            start_date: access_data?.tvc_news?.start_date === 0 ? 
                                        new Date(access_data['start_date'] * 1000) : 
                                        new Date(access_data?.tvc_news?.start_date * 1000),
                            end_date: access_data?.tvc_news?.end_date === 0 ? 
                                        new Date(access_data['expired_at'] * 1000) : 
                                        new Date(access_data?.tvc_news?.end_date * 1000),
                            is_local: access_data?.tvc_news?.is_local,
                            child_all: access_data?.tvc_news?.child_all || 0,
                            child_list: access_data?.tvc_news?.child_list || [],
                            company_list: access_data?.tvc_news?.company_list || [],
                            category_list: access_data?.tvc_news?.category_list || [],
                            category_select: access_data?.tvc_news?.category_select || 0,
                            sub_category_list: access_data?.tvc_news?.sub_category_list || [],
                            sub_category_select: access_data?.tvc_news?.sub_category_select || 0
                        }
                    });
                    
                    setRdcNewsData({
                        rdc_news: {
                            start_date: access_data?.rdc_news?.start_date === 0 ? 
                                        new Date(access_data['start_date'] * 1000) : 
                                        new Date(access_data.rdc_news.start_date * 1000),
                            end_date: access_data?.rdc_news?.end_date === 0 ? 
                                        new Date(access_data['expired_at'] * 1000) : 
                                        new Date(access_data.rdc_news.end_date * 1000),
                            is_local: access_data?.rdc_news?.is_local || false,
                            child_all: access_data?.rdc_news?.child_all || 0,
                            child_list: access_data?.rdc_news?.child_list || [],
                            company_list: access_data?.rdc_news?.company_list || [],
                            category_list: access_data?.rdc_news?.category_list || [],
                            category_select: access_data?.rdc_news?.category_select ||  0,
                            sub_category_list: access_data?.rdc_news?.sub_category_list ||  [],
                            sub_category_select: access_data?.rdc_news?.sub_category_select ||  0
                        }
                    });

                    setPrintNewsData({
                        print_news: {
                            start_date: access_data?.print_news?.start_date === 0 ? 
                                        new Date(access_data['start_date'] * 1000) : 
                                        new Date(access_data.print_news.start_date * 1000),
                            end_date: access_data?.print_news?.end_date === 0 ? 
                                        new Date(access_data['expired_at'] * 1000) : 
                                        new Date(access_data.print_news.end_date * 1000),
                            is_local: access_data?.print_news?.is_local || false,
                            child_all: access_data?.print_news?.child_all || 0,
                            child_list: access_data?.print_news?.child_list || [],
                            company_list: access_data?.print_news?.company_list || [],
                            category_list: access_data?.print_news?.category_list || [],
                            category_select: access_data?.print_news?.category_select || 0,
                            sub_category_list: access_data?.print_news?.sub_category_list || [],
                            sub_category_select: access_data?.print_news?.sub_category_select || 0
                        }
                    });

                    setOnlineNewsData({
                        online_news: {
                            start_date: access_data?.online_news?.start_date === 0 ? 
                                            new Date(access_data['start_date'] * 1000) : 
                                            new Date(access_data.online_news.start_date * 1000),
                            end_date: access_data?.online_news?.end_date === 0 ? 
                                            new Date(access_data['expired_at'] * 1000) : 
                                            new Date(access_data.online_news.end_date * 1000),
                            is_local: access_data?.online_news?.is_local || false,
                            child_all: access_data?.online_news?.child_all || 0,
                            child_list: access_data?.online_news?.child_list || [],
                            company_list: access_data?.online_news?.company_list || [],
                            category_list: access_data?.online_news?.category_list || [],
                            category_select: access_data?.online_news?.category_select || 0,
                            sub_category_list: access_data?.online_news?.sub_category_list || [],
                            sub_category_select: access_data?.online_news?.sub_category_select || 0
                        }
                    });
                }
                setLoading(false)
            })
            .catch( (err) => {
                setLoading(false)
                return [];
            });
        }
        fetch_permission();
    }, [])

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:100+'vh'}}>

                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Data Permission</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <span><i className="icon dripicons-home"></i></span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span>Data Settings</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Update Data Access Permission
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <h5 className="card-header">
                                        Update Data Access Permission
                                    </h5>

                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="parent">
                                                                Parent User
                                                            </label>
                                                            <div>
                                                                <strong>{ parentUser?.email }</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                            <label htmlFor="parent">
                                                                Alert Title  <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                style={inputError?input_error:{borderColor:"none"}}
                                                                className="form-control"
                                                                value={title}
                                                                onChange={(e) => {
                                                                    setTitle(e.target.value)
                                                                }}
                                                            />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label htmlFor="firstName">Access Start Date</label>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={expireStartDate}
                                                                onChange={(date) => setExpireStartDate(date)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label htmlFor="firstName">Access End Date</label>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={expireEndDate}
                                                                onChange={(date) => setExpireEndDate(date)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="dashed"/>

                                                <div className="row">
                                                    <div className="col-md-2">
                                                        Control For - Ad
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown ">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('tvc_ad')}>
                                                                    TV
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('tvc_ad')}>
                                                                        Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('rdc_ad')}>
                                                                    Radio
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('rdc_ad')}>
                                                                        Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('print_ad')}>
                                                                    Print
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('print_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('online_ad')}>
                                                                Online
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('online_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="dashed"/>

                                                <div className="row">
                                                    <div className="col-md-2">
                                                        Control For - News
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown ">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('tv_news')}>
                                                                TV
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('tv_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('radio_news')}>
                                                                Radio
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('radio_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('print_news')}>
                                                                Print
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('print_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('online_news')}>
                                                                Online
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('online_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card-footer bg-light">
                                                <button type="submit" className="btn btn-primary">
                                                    ✔ Update Data Access
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <Modal show={show} onHide={() => handleClose(modalTitle)}>
                            <Modal.Header>
                                <Modal.Title>
                                    Access Permission For - {modalTitle}
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div className="row">
                                    { showTVC ? (
                                        <TvcAdEditForm
                                            data={{
                                                parent: parentUser, 
                                                stateinfo: tvcAdData, 
                                                stateData: setTvcAdData
                                            }}
                                        />
                                    ) : ('') }

                                    { showRDC ? (
                                        <RdcAdForm
                                            data={{
                                                parent: parentUser, 
                                                stateinfo: rdcAdData, 
                                                stateData: setRdcAdData
                                            }}
                                        />
                                    ) : ('') }

                                    { showPrint ? (
                                        <PrintAdForm
                                            data={{
                                                parent: parentUser, 
                                                stateinfo: printAdData, 
                                                stateData: setPrintAdData
                                            }}
                                        />
                                    ) : ('') }

                                    { showOnline ? (
                                        <OnlineAdForm
                                            data={{
                                                parent: parentUser, 
                                                stateinfo: onlineAdData, 
                                                stateData: setOnlineAdData
                                            }}
                                        />
                                    ) : ('') }
                                    
                                    { showTVNews ? (
                                        <TvcNewsForm
                                            data={{
                                                parent: parentUser, 
                                                stateinfo: tvcNewsData, 
                                                stateData: setTvcNewsData
                                            }}
                                        />
                                    ) : ('') }

                                    { showRadioNews ? (
                                        <RdcNewsForm
                                            data={{
                                                parent: parentUser, 
                                                stateinfo: rdcNewsData, 
                                                stateData: setRdcNewsData
                                            }}
                                        />
                                    ) : ('') }

                                    { showPrintNews ? (
                                        <PrintNewsForm
                                            data={{
                                                parent: parentUser, 
                                                stateinfo: printNewsData, 
                                                stateData: setPrintNewsData
                                            }}
                                        />
                                    ) : ('') }

                                    { showOnlineNews ? (
                                        <OnlineNewsForm
                                            data={{
                                                parent: parentUser, 
                                                stateinfo: onlineNewsData, 
                                                stateData: setOnlineNewsData
                                            }}
                                        />
                                    ) : ('') }
                                </div>
                            </Modal.Body>
                            
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => handleClose(modalTitle)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => handleClose(modalTitle)}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>

                        </Modal>

                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default PermissionEditAdvanced;