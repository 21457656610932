import { getAdminToken } from '../../helpers/Cookies'
const axios = require('axios')
const resourceServerInstance = axios.create({
  baseURL: process.env.REACT_APP_RESOURCE_SERVER,
  headers: {
    Authorization: (getAdminToken()) ? getAdminToken() : ''
  },
})

export default resourceServerInstance
 