export const Message = {
  CompanyName: 'Company Name Is Required',
  CompanyADVCode: 'Company ADV Code Is Required',
  CompanyID: 'Company ID Is Required.',
  user_firstName: 'User First Name Is Required',
  user_lastName: 'User Last Name Is Required',
  user_email: 'User Email Is Required',
  user_password: 'User Password Is Required',
  user_address: 'User Address Is Required',
  user_uid: 'User UID Is Required',
  alert_title: 'Alert title Is Required',
  alert_details: 'Alert details Is Required',
}
  
export const TEXT = {
  tv_ad_service: 'TV Ad Service',
  radio_ad_service: 'Radio Ad Service',
}
export const ALERT = {
  instant_add_title: 'Add Instant Alert',
  instant_add_form_title: 'Instant Alert Service Form',
  instant_add_form: {
    title: 'Instant alert title',
    client: 'Select a client',
    details: 'Instant alert body'
  },
  instant_add_form_validate: {
    client: 'Have to select a client.'
  },
}
export const CONS = {
  AD_SERVICE: 'ad_service',
  NEWS_SERVICE: 'news_service',
  MEDIUM_TV: 'tv',
  MEDIUM_RADIO: 'radio',
  MEDIUM_PRINT_ONLINE: 'print_online',
}
  