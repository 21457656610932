import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
import { CONS } from '../../../helpers/Constant'
export const addUser = async (data) => {
  const params = new URLSearchParams()
  params.append('user_name', data.email)
  params.append('email', data.email)
  params.append('address', data.address)
  params.append('firstname', data.firstName)
  params.append('lastname', data.lastName)
  params.append('password', data.password)
  const result = await AxiosInstance.post(EndPoints.user_add, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}
export const permissionStore = async (data) => {
  const params = new URLSearchParams()
  
  params.append('permission', JSON.stringify(data))

  const result = await AxiosInstance.post(EndPoints.user_data_permission, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}
export const advancePermission = async (data, flag="") => {
  let END_POINT = EndPoints.advanced_user_data_permisssion;

  if(flag==='update'){
    END_POINT = EndPoints.advanced_user_data_permisssion_update;
  }

  const params = new URLSearchParams()
  
  params.append('permission', JSON.stringify(data))

  const result = await AxiosInstance.post(END_POINT, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}

export const permissionUpdate = async (data) => {
  
  const params = new URLSearchParams()
  
  params.append('permission', JSON.stringify(data))

  const result = await AxiosInstance.post(EndPoints.access_permission_update, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}

export const userList = async () => {
  const result = await AxiosInstance.post(EndPoints.user_list)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })
  return result
}

export const fetchParentUserList = async () => {

  const params = new URLSearchParams()
  params.append('page', -1)
  const reuslt = await AxiosInstance.post(EndPoints.parent_user_list)
                .then((res) => {
                  return SuccessResponse(res)
                })
                .catch((err) => {
                  return ErrorResponse(err)
                })

  return reuslt
}

export const getSubUsers = async (uid) => {
  const params = new URLSearchParams()
  params.append('p_uId', uid)
  const result = await AxiosInstance.post(EndPoints.get_user_sub_id, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })
  
  return result
}

export const getParentUsers = async () => {
  const result = await AxiosInstance.post(EndPoints.parent_user_list)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })
  return result
}

export const getUserById = async (data) => {
  const params = new URLSearchParams()
  params.append('u_id', data.u_id)

  const result = await AxiosInstance.post(EndPoints.user_get, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}

export const updateUser = async (data) => {
  const params = new URLSearchParams()
  for (const [key, value] of Object.entries(data)) {
    if (value !== 'undefined' || value !== '') {
      params.append(key, value)
    }
  }
 
  const result = await AxiosInstance.post(EndPoints.user_update, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })
 
  return result
}

export const deleteUser = async (data) => {
  const params = new URLSearchParams()
  params.append('id', data)
  params.append('isdelete', 1)

  const result = await AxiosInstance.post(EndPoints.user_delete, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}

export const addUserDataAccessPermission = async (data) => {
  if (data.service_type === CONS.AD_SERVICE) {
    if (data.medium === CONS.MEDIUM_TV) {
      const result = await AxiosInstance.post(EndPoints.data_service_add, data)
        .then((res) => {
          return SuccessResponse(res)
        })
        .catch((err) => {
          return ErrorResponse(err)
        })
      return result
    }
    else if (data.medium === CONS.MEDIUM_RADIO) { }
    else if (data.medium === CONS.MEDIUM_PRINT_ONLINE) {
      return false
    }
  }
  else if (data.service_type === CONS.NEWS_SERVICE) {
    if (data.medium === CONS.MEDIUM_TV) { }
    else if (data.medium === CONS.MEDIUM_RADIO) { }
    else if (data.medium === CONS.MEDIUM_PRINT_ONLINE) {
      return false
    }
  }
  return false
}

export const addSubUsers = async (data) => {
  const params = new URLSearchParams()
  params.append('parent_id', data.parentId.pId)
  data.inputFields.map((data, index) => {
    params.append(`email_${index + 1}`, data.email)
  })

  const result = await AxiosInstance.post(EndPoints.user_sub_add, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}

export const fetchSubUsers = async (id) => {
  try {
    const params = new URLSearchParams()
    params.append('p_uId', id)
    const result = await AxiosInstance.post(EndPoints.get_user_sub_id, params)
      .then((res) => {
        return SuccessResponse(res)
      })
      .catch((err) => {
        return ErrorResponse(err)
      })

    return result
  } catch (err) { }
}

export const fetchSubscription = async (id) => {
   try {
     const params = new URLSearchParams()
     params.append('status', 1)
 
     const result = await AxiosInstance.post(EndPoints.get_subscription, params)
       .then((res) => {
         return SuccessResponse(res)
       })
       .catch((err) => {
         return ErrorResponse(err)
       })
 
     return result
   } catch (err) { }
}
 
export const changeUserPassword = async (password, pUid) => {
  try {
    const params = new URLSearchParams()
    params.append('pass', password.confirm_pass)
    params.append('p_uid', pUid)

    const result = await AxiosInstance.post(EndPoints.parent_user_change_password, params)
        .then((res) => {
          return SuccessResponse(res)
        })
        .catch((err) => {
          return ErrorResponse(err)
        })
        
    return result
  } 
  catch (err) {
    return []
  }
}

export const updateUserInfo = async (data) => {
  try {
    const params = new URLSearchParams()
    params.append('data', JSON.stringify(data))

    const result = await AxiosInstance.post(EndPoints.user_update_data, params)
        .then((res) => {
          return SuccessResponse(res)
        })
        .catch((err) => {
          return ErrorResponse(err)
        })
        
    return result

  } catch (err) {
    return []
  }

}

export const fetchUserActivity = async (uid) => {
  try {
    const params = new URLSearchParams()
    params.append('uid', uid)

    const result = await AxiosInstance.post(EndPoints.get_user_activities_history, params)
        .then((res) => {
          return SuccessResponse(res)
        })
        .catch((err) => {
          return ErrorResponse(err)
        })
        
    return result
  } catch (err) {
    return []
  }
}

export const fetchUserServiceAccess = async (uid) => {
  try {
    const params = new URLSearchParams()
    params.append('u_id', uid)

    const result = await AxiosInstance.post(EndPoints.get_user_service_access, params)
                  .then((res) => {
                    return res.data
                  })
                  .catch((err) => {
                    return ErrorResponse(err)
                  })
        
    return result
  } catch (err) {
    return []
  }
}

export const changeServiceAccessStatus = async (data) => {
  try {
    const params = new URLSearchParams()
    params.append('u_id', data.user_id)
    params.append('type', data.type)
    params.append('key', data.key)
    params.append('value', !data.value)

    const result = await AxiosInstance.post(EndPoints.change_user_service_access, params)
                  .then((res) => {
                    return res.data
                  })
                  .catch((err) => {
                    return ErrorResponse(err)
                  })
        
    return result
  } catch (err) {
    return []
  }
}

export const searchUserByQuery = async (search_q) => {
  const params = new URLSearchParams()
  params.append('search_q', search_q)

  const result = await AxiosInstance.post(EndPoints.search_parent_user, params)
      .then((res) => {
          return SuccessResponse(res)
      })
      .catch((err) => {
          return ErrorResponse(err)
      })

  return result
}

export const deleteAdvancedPermission = async (data) => {
  const params = new URLSearchParams();
  params.append('d_id', data.d_id);
  params.append('u_id', data.u_id);
  
  const result = await AxiosInstance.post(EndPoints.access_permission_delete_advanced, params)
    .then((res) => {
      return SuccessResponse(res);
    })
    .catch((err) => {
      return ErrorResponse(err);
    })
  return result;
}