import React from 'react'
import { Link } from "react-router-dom"
import { Message } from '../../../../helpers/Constant'
import Notification from '../../../helpers/Notification'
import LoadingOverlay from 'react-loading-overlay'
import { log } from '../../../../helpers/ConsoleLog'
import { addAdmin } from '../../../../api/requests/streaming/StreamingRequest'
function StreamAddAdmin(){
    let [loading, setLoading] = React.useState(false);
    const [validated, setValidated] = React.useState(false)
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [uid, setUid] = React.useState(0)
    const handleSubmit = async (event) => {
        event.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.stopPropagation()
            setNotification(false)
        } else {
            setLoading(true)
            const user = {
                firstName,
                lastName,
                email,
                password
            }
            const result = await addAdmin(user)
            setTimeout(() => {
                setNotification(false)
            }, 5000)
            if (result.code === 200) {
                setTimeout(() => {
                    setLoading(false)
                }, 2000)
                setNotification(true)
                setNotificationFlag('success')
                setNotificationMessage(result.message)
                setFirstName('')
                setLastName('')
                setEmail('')
                setPassword('')
            }
            else {
                setLoading(false)
                setNotification(true)
                setNotificationFlag('danger')
                setNotificationMessage(result.message)
            }
        }
        setValidated(true)
    }

    return (
        <>

            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:90+'vh'}}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">
                                    Add Admin For Streaming
                                </h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="icon dripicons-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/company">
                                                Streaming List
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            <Link to="/streaming/admin-add">
                                              Add Admin
                                            </Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="card">
                                    <h5 className="card-header">Admin User Form</h5>
                                    <form onSubmit={handleSubmit}>

                                        {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}

                                        <div className="card-body">

                                            <div className="form-group">
                                                <label htmlFor="firstName">First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="firstName"
                                                    name="firstName"
                                                    value={firstName}
                                                    required
                                                    onChange={(e) => {
                                                        setFirstName(e.target.value)
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="lastName"
                                                    name="lastName"
                                                    value={lastName}
                                                    required
                                                    onChange={(e) => {
                                                        setLastName(e.target.value)
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    name="email"
                                                    value={email}
                                                    required
                                                    onChange={(e) => {
                                                        setEmail(e.target.value)
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={password}
                                                    required
                                                    onChange={(e) => {
                                                        setPassword(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-footer bg-light">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}
export default StreamAddAdmin
