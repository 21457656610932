import React from 'react'
import { Link } from "react-router-dom"
import { ReadableDateTime } from '../../../../helpers/DateTime'
import { 
    getUserById,
    updateUserInfo
} from '../../../../api/requests/user/UserRequest'
import LoadingOverlay from 'react-loading-overlay'
import Notification from '../../../helpers/Notification'
import {user_edit_text as user_text} from '../../../../helpers/text/UserEditText';
document.title = user_text.document
function EditChildUser(){
    let [loading, setLoading] = React.useState(false);
    const u_id = new URLSearchParams(window.location.search).get("id");
    const [validated, setValidated] = React.useState(false);
    const [notification, setNotification] = React.useState(false);
    const [notificationFlag, setNotificationFlag] = React.useState('');
    const [notificationMessage, setNotificationMessage] = React.useState('');
    const [userData, setUserData] = React.useState({
        uid: u_id,
        first_name: '', 
        last_name: '',
        email: '', 
        address: '',
    })
    const handleChange = (e) => {
        setUserData( prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    } 
    
    React.useEffect(() => {
        const data = async () => {
            setLoading(true)
            const data = {u_id}
            const result = await getUserById(data)
            if (result) {
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            }
            
            if(result.data){
                setUserData(prevState => ({
                    ...prevState, 
                    first_name: result.data.full_name.first_name,
                    last_name: result.data.full_name.last_name,
                    email: result.data.email,
                    address: result.data.address,
                }))
            }
        }
        data()
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' })
        
        if(userData.first_name === '' || userData.last_name === ''){
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage(user_text.form_error.empty('User Name'))
            setTimeout(() => {
                setNotification(false)
            }, 2000)
            return false
        } else {
            const result = await updateUserInfo(userData)
            
            console.log(result)
        }
        return true
    }    

    return(
        <>
            <LoadingOverlay active={loading} spinner text={user_text.fetching}>
                <div style={{minHeight:90+'vh'}}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">
                                    {user_text.page_title}
                                </h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="icon dripicons-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/user/add">
                                                {user_text.breadcrumb[0].title}
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/user/list">
                                                {user_text.breadcrumb[1].title}
                                            </Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="card">
                                    <h5 className="card-header">
                                        {user_text.form_title}
                                    </h5>
                                    <form onSubmit={handleSubmit}>   

                                        {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}

                                        <div className="card-body">

                                            <div className="form-group">
                                                <label htmlFor="first_name">
                                                    {user_text.form_field.first_name}
                                                </label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="first_name"
                                                    name="first_name"
                                                    value={userData.first_name}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="last_name">
                                                    {user_text.form_field.last_name}
                                                </label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="last_name"
                                                    name="last_name"
                                                    value={userData.last_name}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="email">
                                                    {user_text.form_field.email}
                                                </label>
                                                <input 
                                                    type="email" 
                                                    className="form-control" 
                                                    id="email"
                                                    name="email"
                                                    value={userData.email}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="address">
                                                    {user_text.form_field.address}
                                                </label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="address"
                                                    id="address"
                                                    value={userData.address}
                                                    required
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-footer bg-light">
                                            <button type="submit" className="btn btn-primary">
                                                Update
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </LoadingOverlay>
        </>
    )
}

export default EditChildUser