import React from "react";
import { Link } from "react-router-dom";
import ActionBadge from "../../helpers/ActionBadge";
import LoadingOverlay from "react-loading-overlay";
import { searchTvcBrand, TVCbrandList } from "../../../api/requests/brand/TvcBrandRequest";
function BrandListTVC() {
    document.title = "Brand - List";
    let [loading, setLoading] = React.useState(false);

    const [brands, setBrands] = React.useState([]);
    const [brandSearch, setBrandSearch] = React.useState("");
    const [fetchBrand, setFetchBrand] = React.useState("")
    const [pageNo, setPageNo] = React.useState(0);
    const [isDisable, setIsDisable] = React.useState(false)

    const fetchData = async (page) => {
        setLoading(true);
        const result = await TVCbrandList(page);
        if (result) {
            setTimeout(() => {
                setLoading(false);
                setBrands(result.data);
            }, 500);
            
            if(page === 0){
                setFetchBrand(result.data)
            }
        }

    };

    React.useEffect(() => {
        fetchData(pageNo);
    }, []);

    const handlePreviousPage = () => {
        setPageNo((curPageNo) => {
            if (curPageNo > 0) {
                fetchData(curPageNo - 1);
                return curPageNo - 1;
            } else {
                return 0;
            }
        });
    };

    const handleNextPage = () => {
        setPageNo((curPageNo) => {
            fetchData(curPageNo + 1);
            return curPageNo + 1;
        });
    };

    const handleBrandSearch = async (e) => {
        e.preventDefault()

        setLoading(true)
        if (brandSearch.length > 0) {
            const result = await searchTvcBrand(brandSearch);
            setBrands(result);
            setPageNo(0)
            setIsDisable(true)
        }
        setLoading(false)
    };

    const handleReset = (e) => {
        e.preventDefault()

        setPageNo(0)
        setBrands(fetchBrand)
        setBrandSearch("")
        setIsDisable(false)
    };

    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner
                text="Fetching Brand Data ......"
            >
                <div style={{ minHeight: 90 + "vh" }}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">TVC - Brand List</h1>
                                <nav
                                    className="breadcrumb-wrapper"
                                    aria-label="breadcrumb"
                                >
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="icon dripicons-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            Brand List
                                        </li>
                                        <li className="breadcrumb-item">
                                            Search Brand
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <form className="card-header">
                                        <span>Brand List</span>
                                        <input
                                            type="text"
                                            style={{ margin: "0 1rem" }}
                                            value={brandSearch}
                                            onChange={(e) =>
                                                setBrandSearch(e.target.value)
                                            }
                                        />
                                        <button
                                            type="submit"
                                            className="btn-primary"
                                            style={{ marginRight: "1rem" }}
                                            onClick={handleBrandSearch}
                                        >
                                            Search
                                        </button>
                                        <button
                                            className="btn-secondary"
                                            onClick={handleReset}
                                        >
                                            Reset
                                        </button>
                                    </form>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Brand Code</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {brands
                                                        ? brands.map((data) => (
                                                              <tr
                                                                  key={data._id}
                                                              >
                                                                  <td>
                                                                      {
                                                                          data.brand_id
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          data.brand_name
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          data.brand_code
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {data.deleted ==
                                                                      0 ? (
                                                                          <span class="badge badge-pill badge-success">
                                                                              Active
                                                                          </span>
                                                                      ) : (
                                                                          <span class="badge badge-pill badge-danger">
                                                                              Inactive
                                                                          </span>
                                                                      )}
                                                                  </td>
                                                                  <td>
                                                                      <ActionBadge
                                                                          type="view"
                                                                          page="Brand"
                                                                      />
                                                                  </td>
                                                              </tr>
                                                          ))
                                                        : "Brand Not Found"}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-light">
                            <button
                                type="submit"
                                className="btn btn-primary mr-1"
                                disabled={isDisable}
                                onClick={handlePreviousPage}
                            >
                                Previous
                            </button>
                            <span className="btn btn-primary mr-1">
                                {pageNo + 1}
                            </span>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isDisable}
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    </section>
                </div>
            </LoadingOverlay>
        </>
    );
}

export default BrandListTVC;
