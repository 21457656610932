import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Notification from '../../../helpers/Notification';
import LoadingOverlay from 'react-loading-overlay';
import "react-datepicker/dist/react-datepicker.css";
import TvcAdForm from './../permission-add/advanced-permission/ad/forms/TvcAdForm';
import RdcAdForm from './../permission-add/advanced-permission/ad/forms/RdcAdForm';
import PrintAdForm from './../permission-add/advanced-permission/ad/forms/PrintAdForm';
import OnlineAdForm from './../permission-add/advanced-permission/ad/forms/OnlineAdForm';
import TvcNewsForm from './../permission-add/advanced-permission/news/forms/TvcNewsForm';
import RdcNewsForm from './../permission-add/advanced-permission/news/forms/RdcNewsForm';
import PrintNewsForm from './../permission-add/advanced-permission/news/forms/PrintNewsForm';
import OnlineNewsForm from './../permission-add/advanced-permission/news/forms/OnlineNewsForm';
import { fetchParentUserList, advancePermission } from '../../../../api/requests/user/UserRequest';
const PermissionAddAdvanced = () => {
    let [loading, setLoading] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [notification, setNotification] = React.useState(false);
    const [notificationFlag, setNotificationFlag] = React.useState('');
    const [notificationMessage, setNotificationMessage] = React.useState('');
    const [tvcAdData, setTvcAdData] = React.useState({
        tv_ad: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            brand_select: 0,
            brand_list: [],
            product_type_list: [],
            product_type_select: 0,
            product_select: 0,
            product_list: [],
            media_select: 0,
            media_list: [],
            media_ad_type_select: 0,
            media_ad_type_list: [],
        }
    });
    const [rdcAdData, setRdcAdData] = React.useState({
        rdc_ad: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            brand_select: 0,
            brand_list: [],
            product_type_list: [],
            product_type_select: 0,
            product_select: 0,
            product_list: [],
            media_select: 0,
            media_list: [],
            media_ad_type_select: 0,
            media_ad_type_list: [],
        }
    });
    const [printAdData, setPrintAdData] = React.useState({
        print_ad: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            brand_select: 0,
            brand_list: [],
            product_type_list: [],
            product_type_select: 0,
            product_select: 0,
            product_list: []
        }
    });
    const [onlineAdData, setOnlineAdData] = React.useState({
        online_ad: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            brand_select: 0,
            brand_list: [],
            product_type_list: [],
            product_type_select: 0,
            product_select: 0,
            product_list: [],
            media_select: 0,
            media_list: [],
            media_ad_type_select: 0,
            media_ad_type_list: [],
        }
    });
    const [tvcNewsData, setTvcNewsData] = React.useState({
        tvc_news: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            category_list: [],
            category_select: 0,
            sub_category_list: [],
            sub_category_select: 0
        }
    });
    const [rdcNewsData, setRdcNewsData] = React.useState({
        rdc_news: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            category_list: [],
            category_select: 0,
            sub_category_list: [],
            sub_category_select: 0
        }
    });
    const [printNewsData, setPrintNewsData] = React.useState({
        print_news: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            category_list: [],
            category_select: 0,
            sub_category_list: [],
            sub_category_select: 0
        }
    });
    const [onlineNewsData, setOnlineNewsData] = React.useState({
        online_news: {
            start_date: new Date(),
            end_date: new Date(),
            is_local: false,
            child_all: 0,
            child_list: [],
            company_list: [],
            category_list: [],
            category_select: 0,
            sub_category_list: [],
            sub_category_select: 0
        }
    });
    const [showPrint, setShowPrint] = React.useState(false);
    const [showOnline, setShowOnline] = React.useState(false);
    const [showRDC, setShowRDC] = React.useState(false);
    const [showTVC, setShowTVC] = React.useState(false);
    const [showTVNews, setShowTVNews] = React.useState(false);
    const [showRadioNews, setShowRadioNews] = React.useState(false);
    const [showPrintNews, setShowPrintNews] = React.useState(false);
    const [showOnlineNews, setShowOnlineNews] = React.useState(false);
    const [parentUser, setParentUser] = React.useState([]);
    const [modalCompanySelected, setModalCompanySelected] = React.useState([]);
    const [expireStartDate, setExpireStartDate] = React.useState(new Date());
    const [expireEndDate, setExpireEndDate] = React.useState(new Date());
    const [modalTitle, setModalTitle] = React.useState('');
    const [show, setShow] = React.useState(false);
    const stateControl = (action, section, data='') => {
        if(section === 'print_ad'){
            if (action === 'modal_closed'){
                setShowPrint(false);
            }
            else if(action === 'modal_open'){
                setShowPrint(true);
            }
            else if(action === 'show_active'){
                return `btn ${  printAdData.print_ad.child_all || 
                                printAdData.print_ad.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'online_ad'){
            if(action === 'modal_closed'){
                setShowOnline(false)
            }
            else if(action === 'modal_open'){
                setShowOnline(true)
            }
            else if(action === 'show_active'){
                return `btn ${  onlineAdData.online_ad.child_all ||
                                onlineAdData.online_ad.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'rdc_ad'){
            if(action === 'modal_closed'){
                setShowRDC(false)
            }
            else if(action === 'modal_open'){
                setShowRDC(true)
            }
            else if(action === 'show_active'){
                return `btn ${  rdcAdData.rdc_ad.child_all || 
                                rdcAdData.rdc_ad.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'tvc_ad'){
            if(action === 'modal_closed'){
                setShowTVC(false)
            }
            else if(action === 'modal_open'){
                setShowTVC(true)
            }
            else if(action === 'show_active'){
                return `btn ${  tvcAdData.tv_ad.child_all ||
                                tvcAdData.tv_ad.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'tv_news'){
            if(action === 'modal_closed'){
                setShowTVNews(false)
            }
            else if(action === 'modal_open'){
                setShowTVNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${  tvcNewsData.tvc_news.child_all ||
                                tvcNewsData.tvc_news.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'radio_news'){
            if(action === 'modal_closed'){
                setShowRadioNews(false)
            }
            else if(action === 'modal_open'){
                setShowRadioNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${  rdcNewsData.rdc_news.child_all || 
                                rdcNewsData.rdc_news.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'print_news'){
            if(action === 'modal_closed'){
                setShowPrintNews(false)
            }
            else if(action === 'modal_open'){
                setShowPrintNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${  printNewsData.print_news.child_all ||
                                printNewsData.print_news.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
        else if(section === 'online_news'){
            if(action === 'modal_closed'){
                setShowOnlineNews(false)
            }
            else if(action === 'modal_open'){
                setShowOnlineNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${  onlineNewsData.online_news.child_all ||
                                onlineNewsData.online_news.child_list.length > 0 ?
                                    'btn-success' : 'btn-danger btn-outline' }`
            }
        }
    }
    const handleClose = (section) => {
        setShow(false)
        stateControl('modal_closed', section, modalCompanySelected)
    }
    const handleShow = (section) => {
        setShow(true)
        setModalTitle(section)
        stateControl('modal_open', section)
    }
    const selectValue = (tag, event) => {
        if(tag === 'company') {
            setSelectedCompany(event.value)
        } else if(tag === 'parent') {
            setSelectedParent(event)
        }
    }
    const displayAccessBtn = (btn) => {
        return stateControl('show_active', btn, '')
    }
    const [selectedCompany, setSelectedCompany] = React.useState(-1);
    const [selectedParent, setSelectedParent] = React.useState([]);
    const dateTimeStamp = (date_string) => {
        const date = new Date(date_string)
        return Math.floor(date.getTime() / 1000)
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        if(title.trim() == '' ){
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage("Alert title can't be empty.")
            setTimeout(() => {
                setNotification(false)
                setLoading(false)
            }, 1500)
            return false
        }
        if(selectedParent.length == 0) {
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage("Must be selected an user.")
            setTimeout(() => {
                setNotification(false)
                setLoading(false)
            }, 1500)
            return false
        }
        const permission_data = {
            title,
            parent_id: selectedParent,
            start_date: expireStartDate,
            end_date: expireEndDate,
            print_ad: printAdData.print_ad,
            online_ad: onlineAdData.online_ad,
            rdc_ad: rdcAdData.rdc_ad,
            tv_ad: tvcAdData.tv_ad,
            print_news: printNewsData.print_news,
            online_news: onlineNewsData.online_news,
            rdc_news: rdcNewsData.rdc_news,
            tvc_news: tvcNewsData.tvc_news
        }
        const result = await advancePermission(permission_data)
        if (result) {
            setNotification(true);
            setNotificationMessage(result.message);
            if(result.status === 'fail'){
              setNotificationFlag('danger');
            } else {
              setNotificationFlag('success');
            }
            setTimeout(() => {
                setLoading(false);
                setNotification(false);
            }, 800);

            setTimeout(() => {
                window.location.href = `${process.env.REACT_APP_BASE_URL}permission/list/advanced/all`;
            }, 1000);
        }
        return false
    }
    React.useEffect(() => {
        const fetchParentUser = async () => {
            let opt = []
            setLoading(true)
            const result = await fetchParentUserList()
            if (result) {
                result.data.map((data, index) => {
                    let arr = {
                        value: data.uid,
                        label: `${data.full_name.first_name} ${data.full_name.last_name} -- ${data.email}`,
                    }
                    opt.push(arr)
                })
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            }
            setParentUser(opt)
        }
        fetchParentUser()
    }, [])

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:100+'vh'}}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Data Permission</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <span><i className="icon dripicons-home"></i></span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span>Data Service</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Data Access Permission
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <h5 className="card-header">
                                        Set Advanced Data Access Permission
                                    </h5>

                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="parent">
                                                                Select Parent Id <span className="text-danger">*</span>
                                                            </label>
                                                            <Select
                                                                options={parentUser}
                                                                onChange={(e) => selectValue('parent', e) }
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="parent">
                                                                Alert Title  <span className="text-danger">*</span>
                                                            </label>
                                                            <input 
                                                                className="form-control"
                                                                value={title}
                                                                onChange={(e) => {
                                                                    setTitle(e.target.value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label htmlFor="firstName">Access Start Date</label>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={expireStartDate}
                                                                onChange={(date) => setExpireStartDate(date)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label htmlFor="firstName">Access End Date</label>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={expireEndDate}
                                                                onChange={(date) => setExpireEndDate(date)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="dashed"/>

                                                <div className="row">
                                                    <div className="col-md-2">
                                                        Control For - Ad
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown ">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('tvc_ad')}>
                                                                    TV
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('tvc_ad')}>
                                                                        Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('rdc_ad')}>
                                                                    Radio
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('rdc_ad')}>
                                                                        Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('print_ad')}>
                                                                    Print
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('print_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('online_ad')}>
                                                                Online
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('online_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="dashed"/>

                                                <div className="row">
                                                    <div className="col-md-2">
                                                        Control For - News
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown ">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('tv_news')}>
                                                                TV
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('tv_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('radio_news')}>
                                                                Radio
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('radio_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('print_news')}>
                                                                Print
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('print_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('online_news')}>
                                                                Online
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('online_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card-footer bg-light">
                                                <button type="submit" className="btn btn-primary">
                                                    ✅ Add Data Access
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <Modal show={show} onHide={() => handleClose(modalTitle)}>

                            <Modal.Header>
                                <Modal.Title>
                                    Access Permission For - {modalTitle}
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div className="row">
                                    { showTVC ? (
                                        <TvcAdForm
                                            data={{
                                                parent: selectedParent, 
                                                stateinfo: tvcAdData, 
                                                stateData: setTvcAdData
                                            }}
                                        />
                                    ) : ('') }

                                    { showRDC ? (
                                        <RdcAdForm
                                            data={{
                                                parent: selectedParent, 
                                                stateinfo: rdcAdData, 
                                                stateData: setRdcAdData
                                            }}
                                        />
                                    ) : ('') }

                                    { showPrint ? (
                                        <PrintAdForm
                                            data={{
                                                parent: selectedParent, 
                                                stateinfo: printAdData, 
                                                stateData: setPrintAdData
                                            }}
                                        />
                                    ) : ('') }

                                    { showOnline ? (
                                        <OnlineAdForm
                                            data={{
                                                parent: selectedParent, 
                                                stateinfo: onlineAdData, 
                                                stateData: setOnlineAdData
                                            }}
                                        />
                                    ) : ('') }

                                    { showTVNews ? (
                                        <TvcNewsForm
                                            data={{
                                                parent: selectedParent, 
                                                stateinfo: tvcNewsData, 
                                                stateData: setTvcNewsData
                                            }}
                                        />
                                    ) : ('') }

                                    { showRadioNews ? (
                                        <RdcNewsForm
                                            data={{
                                                parent: selectedParent, 
                                                stateinfo: rdcNewsData, 
                                                stateData: setRdcNewsData
                                            }}
                                        />
                                    ) : ('') }

                                    { showPrintNews ? (
                                        <PrintNewsForm
                                            data={{
                                                parent: selectedParent, 
                                                stateinfo: printNewsData, 
                                                stateData: setPrintNewsData
                                            }}
                                        />
                                    ) : ('') }
                                    
                                    { showOnlineNews ? (
                                        <OnlineNewsForm
                                            data={{
                                                parent: selectedParent, 
                                                stateinfo: onlineNewsData, 
                                                stateData: setOnlineNewsData
                                            }}
                                        />
                                    ) : ('') }
                                </div>
                            </Modal.Body>
                            
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => handleClose(modalTitle)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => handleClose(modalTitle)}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>

                        </Modal>

                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default PermissionAddAdvanced