import React from 'react'
import { Message } from '../../../../helpers/Constant'
import Notification from '../../../helpers/Notification'
import { fetchParentUserList, getSubUsers } from '../../../../api/requests/user/UserRequest'
import { subscriptionList } from '../../../../api/requests/subscription/SubscriptionRequest'
import { addSubscriber } from '../../../../api/requests/subscriber/SubscriberRequest'
import LoadingOverlay from 'react-loading-overlay'
import { MultiSelect } from 'react-multi-select-component'
import Select from 'react-select'
const SetSubscriber = () => {
    let [loading, setLoading] = React.useState(false);
    const [subscription, setSubscription] = React.useState([])
    const [parentUser, setParentUser] = React.useState([])
    const [subUser, setSubUser] = React.useState([])
    const [subscriptionTitle, setSubscriptionTitle] = React.useState('')
    const [validated, setValidated] = React.useState(false)
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [selected, setSelected] = React.useState([])
    const [selectParent, setSelectParent] = React.useState(-1)
    const [selectSubscription, setSelectSubscription] = React.useState(-1)
    const handleSubmit = async (event) => {
        event.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' })
        if (selectParent === -1) {
            event.stopPropagation()
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage("Parent user can't be empty")
        } else if(selectSubscription === -1){
            event.stopPropagation()
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage("Subscription can't be empty.")
        } else if (selected.length === 0){
            event.stopPropagation()
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage("Subscription email can't be empty")
        } else {
            setLoading(true)
            const subscription = {
                parent_id: selectParent,
                subscription_id: selectSubscription,
                child: selected,
                name: subscriptionTitle
            }
            const result = await addSubscriber(subscription)
            if (result.code === 200) {
                setTimeout(() => {
                    setLoading(false)
                }, 2000)
                setNotification(true)
                setNotificationFlag('success')
                setNotificationMessage(result.message)
                setTimeout(() => {
                    window.location.replace("/subscriber/list")
                }, 3000)
            }
            else {
                setNotification(true)
                setNotificationFlag('danger')
                setNotificationMessage(result.message)
            }
        }
        setValidated(true)
    }

    const loadChild = async (event) => {
        setLoading(true)
        setSelectParent(event.value)
        let opt = []
        const result = await getSubUsers(event.value)
        if (result) {
            result.data.map((data) => {
                let arr = {
                value: data.uid,
                label: data.email,
                }
                opt.push(arr)
            })
            setTimeout(() => {
                setLoading(false)
            }, 800)
            setSubUser(opt)
        }
    }
    const changeSubscription = (event) => {
        setSelectSubscription(event.value)
    }

    React.useEffect(() => {
        const data = async () => {
            let opt = []
            setLoading(true)
            const result = await subscriptionList()
            if (result) {
                result.data.map((data) => {
                let arr = { value: data.id, label: data.name }
                opt.push(arr)
                })
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            }
            setSubscription(opt)
        }
        const fetchParentList = async () => {
            let opt = []
            setLoading(true)
            const result = await fetchParentUserList()
            if (result) {
                result.data.map((data, index) => {
                    let arr = {
                        value: data.uid,
                        label: `${data.full_name.first_name} ${data.full_name.last_name} - ${data.email}`,
                    }
                    opt.push(arr)
                })
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            }
            setParentUser(opt)
        }
        fetchParentList()
        data()
    }, [])

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:90+'vh'}}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Set Subscriber</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <span><i className="icon dripicons-home"></i></span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span>Subscriber</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Subscriber List
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="card">
                                    <h5 className="card-header">Subscriber Add Form</h5>
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <form onSubmit={handleSubmit}>   
                                                {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}
                                                <div className="card-body">

                                                <div className="form-group">
                                                    <label htmlFor="name">Name</label>
                                                    <input 
                                                        type="text" 
                                                        required
                                                        className="form-control" 
                                                        id="name"
                                                        value={subscriptionTitle}
                                                        placeholder="Type Subscription Group Name"
                                                        onChange={(e) => {
                                                            setSubscriptionTitle(e.target.value)
                                                        }}
                                                    />
                                                </div>

                                                    <div className="form-group">
                                                        <label htmlFor="firstName">Parent User</label>
                                                        <Select options={parentUser} onChange={loadChild} required/>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="lastName">Subscription For</label>
                                                        <Select options={subscription} onChange={changeSubscription} required/>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="email">Add Subscription Emails</label>
                                                        <MultiSelect
                                                            options={subUser}
                                                            value={selected}
                                                            onChange={setSelected}
                                                            labelledBy="Select"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="card-footer bg-light">
                                                    <button type="submit" className="btn btn-primary">Save Subscriber</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default SetSubscriber