import AxiosInstance from './Index'
import EndPoints from '../end-points/EndPoints'
export const getAlertService = async () => {
    const data = await AxiosInstance.post(EndPoints.get_alert_service)
        .then(function (response) {
            return response.data.result.data
        })
        .catch(function (err) {
            console.log(err)
        })
    return data
}
export const getAlertServiceByDate = async (payload) => {
    const data = await AxiosInstance.post(EndPoints.get_alert_services_by_date, payload)
        .then(function (response) {
            return response.data.result.data
        })
        .catch(function (err) {
            console.log(err)
        })
    return data
}
