import Index from './Index'
export const user_edit_text = {
    document: "Admin - Edit User",
    page_title: 'Edit User Data',
    fetching: 'Fetching User Data... ... ...',
    inbox_loading: 'Updating Password Please Wait ......',
    breadcrumb: [
        {
            'link' : '/user/add',
            'title': 'User'
        },
        {
            'link' : '/user/list',
            'title': 'User List'
        }
    ],
    form_title: "User Data Update Form",
    form_field: {
        first_name: "First Name *",
        last_name: "Last Name *",
        email: "Email Address",
        address: "Address"
    },
    form_error: {
        ...Index.form.error,
    },
    modal: {}, 

    help_text: "",
}