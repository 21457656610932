import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const TVCbrandList = async (page_no) => {
    const params = new URLSearchParams()
    params.append('page', page_no)
    const result = await AxiosInstance.post(EndPoints.tvc_brand_list, params)
                    .then((res) => {
                        return SuccessResponse(res)
                    })
                    .catch((err) => {
                        return ErrorResponse(err)
                    })
    return result
}
export const searchTvcBrand = async (data) => {
    const params = new URLSearchParams()
    params.append('search_q', data)
    const result = await AxiosInstance.post(EndPoints.tvc_brand_search, params)
        .then((res) => {
            const result =  SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    return result
}
 