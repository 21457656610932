export default function validate(values){
    let errors = {};

    if(!values.userName){
        errors.userName =  "Email is required."
    } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.userName) === false){
        errors.userName =  "Email address is invalid."
    }

    if(!values.password){
        errors.password = "Password is required."
    }

    return errors;
}