export const TEXT = {
    document: "Inbox - Alert",
    page_title: 'Inbox Alert List',
    inbox_loading: 'Loading Inbox Alert ......',
    instant_add_form_title: 'Instant Alert Service Form',
    breadcrumb: {
      title: 'Instant alert title',
      client: 'Select a client',
      details: 'Instant alert body'
    },
    instant_add_form_validate: {
      client: 'Have to select a client.'
    }
}