import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const companyListTVC = async (page_no) => {
    const params = new URLSearchParams()
    params.append('page', page_no)
 
     const result = await AxiosInstance.post(EndPoints.tvc_company_list, params)
     .then((res) => {
        return SuccessResponse(res)
     })
     .catch((err) => {
        return ErrorResponse(err)
     })
  
    return result
 }

export const searchTvcCompany = async (payload) => {
    const params = new URLSearchParams()
    params.append('search_q', payload)
    const result = await AxiosInstance.post(EndPoints.search_tvc_company, params)
        .then((res) => {
            const result = SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    return result
}

export const searchTvCompany = async (payload) => {
    const params = new URLSearchParams()
    params.append('search_q', payload)
    const result = await AxiosInstance.post(EndPoints.search_tv_company, params)
        .then((res) => {
            const result = SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    return result
}

export const searchTVBrandByName = async (payload) => {
    const params = new URLSearchParams()

    params.append('search_q', payload)

    const result = await AxiosInstance.post(EndPoints.search_tv_brand_by_name, params)
        .then((res) => {
            const result = SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    
    return result
}

export const searchTVBrandByCompany = async (payload) => {
    const params = new URLSearchParams()
    params.append('search_q', JSON.stringify(payload))
    const result = await AxiosInstance.post(EndPoints.search_tv_brand, params)
        .then((res) => {
            const result =  SuccessResponse(res)
            return result.data
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    return result
}

export const searchTvcProductType = async (payload) => {
    try {
        const params = new URLSearchParams()
        params.append('search_q', payload)
        const result = await AxiosInstance.post(EndPoints.search_tv_product_type, params)
                        .then((res) => {
                            const result =  SuccessResponse(res)
                            return result.data
                        })
                        .catch((err) => {
                            return ErrorResponse(err)
                        })

        return result

    } catch (err) {
        return []
    }
}

export const searchTvcProduct = async (payload) => {
    try {
        const params = new URLSearchParams()
        params.append('search_q', payload)
        const result = await AxiosInstance.post(EndPoints.search_tv_product_by_name, params)
                        .then((res) => {
                            const result =  SuccessResponse(res)
                            return result.data
                        })
                        .catch((err) => {
                            return ErrorResponse(err)
                        })

        return result

    } catch (err) {
        return []
    }
}

export const findTvcProduct = async (payload) => {
    try {
        const params = new URLSearchParams()
        params.append('search_q', JSON.stringify(payload))
        
        const result = await AxiosInstance.post(EndPoints.search_tv_product, params)
            .then((res) => {
                const result =  SuccessResponse(res)
                return result.data
            })
            .catch((err) => {
                return ErrorResponse(err)
            })

        return result

    } catch (err) {

    }
}

export const tvcMediaList = async () => {
    try {
        const result = await AxiosInstance.post(EndPoints.tv_media_list)
            .then((res) => {
                const result =  SuccessResponse(res)
                return result.data
            })
            .catch((err) => {
                return ErrorResponse(err)
            })

        return result

    } catch (err) {
        return []
    }
}

export const tvcAdTypeList = async () => {
    try {
        const result = await AxiosInstance.post(EndPoints.tv_ad_type_list)
            .then((res) => {
                const result =  SuccessResponse(res)
                return result.data
            })
            .catch((err) => {
                return ErrorResponse(err)
            })

        return result

    } catch (err) {
        return []
    }
}