import Cookies from 'js-cookie'
export const SetAdminCookies = (key, value) => {
    Cookies.set(key, value, { expires: 1, path: '' })
    return true
}
export const FetchAdminCookies = (key) => {
    return Cookies.get(key)
}
export const clearAdminCookies = (key) => {
    Cookies.remove(key)
    return true
}
export const getAdminToken = () => {
    if(Cookies.get('adata')){
        let data = JSON.parse(Cookies.get('adata'))
        return (data.token) ? data.token : null
    } else {
        return null
    }
}
  