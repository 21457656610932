import React from 'react'
import { Link } from "react-router-dom"
import { Message } from '../../../../helpers/Constant'
import Notification from '../../../helpers/Notification'
import { v4 as uuidv4 } from 'uuid'
import { fetchUserActivity } from '../../../../api/requests/user/UserRequest'
import { addSubUsers } from '../../../../api/requests/user/UserRequest'
import LoadingOverlay from 'react-loading-overlay'
import Select from "react-select"
function UserActivitiesHistory(){
    let [loading, setLoading] = React.useState(false)
    const [validated, setValidated] = React.useState(false)
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [activities, setActivities] = React.useState([])

    React.useEffect(() => {
      const data = async () => {
        setLoading(true)
        const user_id = window.location.href.split("/")[6]
        const activity_info = await fetchUserActivity(user_id)
        if(activity_info){
            setActivities(activity_info.data)
        }
        setTimeout(() => {
            setLoading(false)
        }, 2000)

      }
      data()
    }, [])

    return (
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:90+'vh'}}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">
                                    User Activities
                                </h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="icon dripicons-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/user/add">
                                                User
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            User List
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <h5 className="card-header">
                                      USER Token Activities
                                    </h5>
                                    <div className="card-body">
                                      <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th>Logging At</th>
                                                <th>IP</th>
                                                <th>Device</th>
                                                <th>Log Expired</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                                {activities.length > 0 ? activities.map((data) => (
                                                    <tr>
                                                        <td>{data.createdAt}</td>
                                                        <td>{data.macAddress}</td>
                                                        <td>{data.deviceType}</td>
                                                        <td>{data.expireAt}</td>
                                                        <td>{data.status}</td>
                                                    </tr>
                                                )) : ''}

                                            </tbody>
                                          </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </LoadingOverlay>

        </>
    )
}

export default UserActivitiesHistory
