import React from 'react'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import "react-datepicker/dist/react-datepicker.css"
import { getSubUsers } from './../../../../../../../api/requests/user/UserRequest'
import { 
    searchNewsCompany,
    searchNewsCategory,
    searchNewsSubCategoryByName
} from './../../../../../../../api/requests/company/NewsCompanyRequest'
import { attributes } from '../helpers/form_attributes'
const checkbox = {
    category: {
        category_all: { key: "category_all", value: 1 }
    },
    sub_category: {
        sub_category_all: { key: "sub_category_all", value: 1 }, 
        category_all_sub_category: { key: "category_all_sub_category", value: 2 },
        select_category_and_sub_category_only: { key: "select_category_and_sub_category_only", value: 3 },
    }
}
const PrintAdForm = ({data}) => {
    let [loading, setLoading] = React.useState(false)
    const { parent, stateinfo, stateData } = data
    let p_id = 0;
    if(parent?.uid){
        p_id = parent.uid;
    } else if(parent?.value){
        p_id = parent.value;
    }
    const [childUser, setChildUser] = React.useState([])
    const [startDate, setStartDate] = React.useState(stateinfo.tvc_news.start_date)
    const [endDate, setEndDate] = React.useState(stateinfo.tvc_news.end_date)
    const [activeDate, setActiveDate] = React.useState(stateinfo.tvc_news.is_local);
    const [selectedChild, setSelectedChild] = React.useState(stateinfo.tvc_news.child_list)
    const [allChild, setAllChild] = React.useState(stateinfo.tvc_news.child_all)
    const [selectedCompany, setSelectedCompany] = React.useState(stateinfo.tvc_news.company_list)
    const [selectedCategory, setSelectedCategory] = React.useState(stateinfo.tvc_news.category_list)
    const [categorySelect, setCategorySelect] = React.useState(stateinfo.tvc_news.category_select)
    const [selectedSubCategory, setSelectedSubCategory] = React.useState(stateinfo.tvc_news.sub_category_list)
    const [subCategorySelect, setSubCategorySelect] = React.useState(stateinfo.tvc_news.sub_category_select)
    React.useEffect(() => {
        fetchChildUsers()
    }, [])

    const fetchChildUsers = async () => {
        let opt = []
        setLoading(true)
        const result = await getSubUsers(p_id)
        if (result) {
            if(result.data != null && result.data.length > 0){
                result.data.map((data, index) => {
                    let arr = {
                        value: data.uid,
                        label: data.email,
                    }
                    opt.push(arr)
                })
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            }
        }
        setChildUser(opt)
    }

    const setStateData = (data, key) => {
        stateData((prevState) => ({
            ...prevState,
            tvc_news: {
                ...prevState.tvc_news,
                [key]: data
            }
        }))
    }
    
    const handleSelectChange = (data, key) => {
        
        switch (key){
            case 'child_change':
                setSelectedChild(data)
                setStateData(data, 'child_list')
                break;

            case 'company_change':
                setSelectedCompany(data)
                setStateData(data, 'company_list')
                break;

            case 'category_change':
                setSelectedCategory(data)
                setStateData(data, 'category_list')
                break;

            case 'sub_category_change':
                setSelectedSubCategory(data)
                setStateData(data, 'sub_category_list')
                break;
            
        }
    }

    const checkedAllOption = (option) => {
        if(option.category === 'child') {
            setAllChild(!allChild)
            setStateData(!allChild, 'child_all')
        }
        else if(option.type === 'category') {
            if(checkbox[option.type][option.category].value == categorySelect) {
                setCategorySelect(0);
                setStateData(0, 'category_select')
            } else {
                setCategorySelect(checkbox[option.type][option.category].value);
                setStateData(checkbox[option.type][option.category].value, 'category_select')
            }
        }
        else if(option.type === 'sub_category') {
            if(checkbox[option.type][option.category].value == subCategorySelect) {
                setSubCategorySelect(0);
                setStateData(0, 'sub_category_select')
            } else {
                setSubCategorySelect(checkbox[option.type][option.category].value);
                setStateData(checkbox[option.type][option.category].value, 'sub_category_select')
            }
        }
        else if(option.type === 'active_local'){
            setActiveDate(!activeDate);
            setStateData(!activeDate, 'is_local');
        }
    }

    const loadCompanyOption = async (search) => {
        if(wordSplit(search)){
            const result = await searchNewsCompany(search)
            return result
        }
    }

    const loadCategoryOption = async (search) => {
        if(wordSplit(search)){
            return await searchNewsCategory(search)
        }
    }

    const loadSubCategoryOption = async (search) => {
        if(wordSplit(search)){
            return await searchNewsSubCategoryByName(search)
        }
    }

    const wordSplit = (search) => {
        let word = search.split(" ")
        let word_len = word.length
        if(word[word_len-1].length == 0)
            return true
        else
            return false
    }

    return(
        <>
            <section className="page-content container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="media">
                                            {attributes.start_date.label}
                                        </label>
                                        <DatePicker
                                            className="form-control"
                                            selected={startDate}
                                            onChange={ (date) => {
                                                setStartDate(date)
                                                setStateData(date, "start_date")
                                            }} 
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="media">
                                            {attributes.end_date.label}
                                        </label>
                                        <DatePicker
                                            className="form-control"
                                            selected={endDate}
                                            onChange={(date) => {
                                                setEndDate(date)
                                                setStateData(date, "end_date")
                                            }} 
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            id="is_local"
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={activeDate}
                                            onChange={() => checkedAllOption({ category:'local_date', type:'active_local' })}
                                        />
                                        <label className="form-check-label" htmlFor="is_local">
                                            Active This Date Range
                                        </label>
                                    </div>
                                </div>

                                <span className="draw-hr"/>
                                
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="company">
                                            {attributes.child_user.label}
                                        </label>
                                        <Select
                                            isMulti
                                            options={childUser}
                                            value={selectedChild}
                                            onChange={(value) => handleSelectChange(value, 'child_change')}
                                            isDisabled={(allChild) ? true : false}
                                        />
                                    </div>
                                    <div className="form-check">
                                        <input
                                            id={attributes.child_user.checkbox[0].key}
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={allChild}
                                            onChange={() => checkedAllOption({category:'child', type:'all_select'})}
                                        />
                                        <label 
                                            className="form-check-label" 
                                            htmlFor={attributes.child_user.checkbox[0].key}>
                                                {attributes.child_user.checkbox[0].label}
                                        </label>
                                    </div>
                                </div>
                                
                                <span className="draw-hr"/>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="company">
                                            {attributes.select_company.label}
                                        </label>
                                        <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            defaultOptions
                                            value={selectedCompany}
                                            loadOptions={loadCompanyOption}
                                            getOptionLabel={e => e.company_name}
                                            getOptionValue={e => e.company_id}
                                            onChange={(value) => handleSelectChange(value, 'company_change')}
                                        />
                                    </div>
                                </div>
                                
                                <span className="draw-hr"/>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="category">
                                            {attributes.select_category.label}
                                        </label>
                                        <AsyncSelect 
                                            isMulti
                                            cacheOptions
                                            defaultOptions
                                            value={selectedCategory}
                                            loadOptions={loadCategoryOption}
                                            getOptionLabel={e => e.name}
                                            getOptionValue={e => e.id}
                                            onChange={(value) => handleSelectChange(value, 'category_change')}
                                            isDisabled={ ([1].includes(categorySelect) )}
                                        />
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="category_all"
                                            checked={ (categorySelect === 1) ? true : false }
                                            onChange={() => checkedAllOption({ category:'category_all', type:'category' })}
                                        />
                                        <label className="form-check-label" htmlFor="category_all">
                                            {attributes.select_category.checkbox[0].label}
                                        </label>
                                    </div>
                                </div>

                                <span className="draw-hr"/>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="product_type">
                                            {attributes.select_sub_category.label}
                                        </label>
                                        <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            defaultOptions
                                            value={selectedSubCategory}
                                            loadOptions={loadSubCategoryOption}
                                            getOptionLabel={e => e.name}
                                            getOptionValue={e => e.id}
                                            onChange={(value) => handleSelectChange(value, 'sub_category_change')}
                                            isDisabled={ ([1,2].includes(subCategorySelect) )}
                                        />
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="sub_category_all"
                                            checked={ (subCategorySelect === 1) ? true : false }
                                            onChange={() => checkedAllOption({category:'sub_category_all', type:'sub_category' })}
                                        />
                                        <label className="form-check-label" htmlFor="sub_category_all">
                                            { attributes.select_sub_category.checkbox[0].label }
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="category_all_sub_category"
                                            checked={ (subCategorySelect === 2) ? true : false }
                                            onChange={ () => checkedAllOption({category:'category_all_sub_category', type:'sub_category'}) }
                                        />
                                        <label className="form-check-label" htmlFor="category_all_sub_category">
                                            { attributes.select_sub_category.checkbox[1].label }
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={attributes.select_sub_category.checkbox[2].key}
                                            checked={ (subCategorySelect === 3) ? true : false }
                                            onChange={ () => checkedAllOption({
                                                category: attributes.select_sub_category.checkbox[2].key, 
                                                type:'sub_category'
                                            }) }
                                        />
                                        <label 
                                            className="form-check-label" 
                                            htmlFor={attributes.select_sub_category.checkbox[2].key}>
                                                {attributes.select_sub_category.checkbox[2].label}
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrintAdForm