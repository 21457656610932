import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const loginAdmin = async (data) => {
  const params = new URLSearchParams()
  params.append('email', data.userName)
  params.append('password', data.password)
  const result = await AxiosInstance.post(EndPoints.admin_login, params)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return ErrorResponse(err)
    })
  return result
}
export const checkPassword = async (data) => {
  try {
    const params = new URLSearchParams()
    params.append('password', data.password)
    const result = await AxiosInstance.post(EndPoints.admin_check_password, params)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        return ErrorResponse(err)
      })
    return result
  } catch (err) {
    console.log(err)
    return []
  }
}
export const changePassword = async (data) => {
  try {
    const params = new URLSearchParams()
    params.append('password', data.newRetypePassword)
    params.append('old_password', data.currentPassword)
    const result = await AxiosInstance.post(EndPoints.admin_check_password, params)
      .then((res) => {
        return SuccessResponse(res)
      })
      .catch((err) => {
        return ErrorResponse(err)
      })

    return result

  } catch (err) {
    console.log(err)
    return []
  }
}