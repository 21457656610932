import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const searchSubscriptionList = async (search_q) => {
    try {
      const params = new URLSearchParams()
      params.append('search_q', search_q)

      const result = await AxiosInstance.post(EndPoints.search_subscription, params)
                    .then((res) => {
                      return SuccessResponse(res)
                    })
                    .catch((err) => {
                      return ErrorResponse(err)
                    })
      return result
    } catch(err) {
      return []
    }
 }
 
export const subscriptionList = async (sorted='') => {
    try{
      const params = new URLSearchParams()
      params.append('sort_by', sorted)
      const result = await AxiosInstance.post(EndPoints.get_subscription, params)
                    .then((res) => {
                      return SuccessResponse(res)
                    })
                    .catch((err) => {
                      return ErrorResponse(err)
                    })
      return result
    } catch(err) {
      return []
    }
}
 