import AxiosInstance from '../ResourcServer'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const uploadPDFFile = async (data, options, uid) => {
   const formData = new FormData()
   formData.append('uid', uid)
   formData.append('pdf_file', data)
   const result = await AxiosInstance.post(EndPoints.upload_pdf, formData, options)
     .then((res) => {
       return SuccessResponse(res)
     })
     .catch((err) => {
       return ErrorResponse(err)
     })
   return result
}
export const uploadZIPFile = async (data, options, uid) => {
   const formData = new FormData()
   formData.append('uid', uid)
   formData.append('zip_file', data)
 
   const result = await AxiosInstance.post(EndPoints.upload_zip, formData, options)
     .then((res) => {
       return SuccessResponse(res)
     })
     .catch((err) => {
       return ErrorResponse(err)
     })
   return result
}
export const uploadPPTFile = async (data, options, uid) => {
   const formData = new FormData()
   formData.append('uid', uid)
   formData.append('ppt_file', data)
   const result = await AxiosInstance.post(EndPoints.upload_ppt, formData, options)
     .then((res) => {
       return SuccessResponse(res)
     })
     .catch((err) => {
       return ErrorResponse(err)
     })
   return result
}

export const uploadExcelFile = async (data, options, uid) => {
   const formData = new FormData()
   formData.append('uid', uid)
   formData.append('excel_file', data)
 
   const result = await AxiosInstance.post(EndPoints.upload_excel, formData, options)
     .then((res) => {
       return SuccessResponse(res)
     })
     .catch((err) => {
       console.log(err)
       return ErrorResponse(err)
     })
   return result
}
 
export const uploadDocFile = async (data, options, uid) => {
   const formData = new FormData()
   formData.append('uid', uid)
   formData.append('doc_file', data)
   const result = await AxiosInstance.post(EndPoints.upload_excel, formData)
     .then((res) => {
       return SuccessResponse(res)
     })
     .catch((err) => {
       return ErrorResponse(err)
     })
   return result
  }
 