import { Navigate, Outlet} from "react-router"
import Cookies from "universal-cookie"

const CheckRole = ({ roles }) => {
	const cookies = new Cookies()
    let auth_status = false
    const session_data = cookies.get('adata')

	if(roles.includes(session_data.role)){
        console.log(session_data.role);
        auth_status = true
    }

    return auth_status ? <Outlet/> : <Navigate to="/" />
}

export default CheckRole