import React from 'react'
import { Message } from '../../../../helpers/Constant'
import Notification from '../../../helpers/Notification'
import { fetchParentUserList } from '../../../../api/requests/user/UserRequest'
import { subscriptionList } from '../../../../api/requests/subscription/SubscriptionRequest'
import {
    getSubscriberById, 
    unSelectedUsersList, 
    addMoreUserToSubscriber 
} from '../../../../api/requests/subscriber/SubscriberRequest'
import { MultiSelect } from 'react-multi-select-component'
import LoadingOverlay from 'react-loading-overlay'
import {Button, Modal} from 'react-bootstrap'
import { Link } from 'react-router-dom'
const SetSubscriber = () => {
    let [loading, setLoading] = React.useState(false);
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [groupName, setGroupName] = React.useState('')
    const [existsEmail, setExistsEmail] = React.useState([])
    const [existsSubUser, setExistsSubUser] = React.useState(null)
    const [parentUser, setParentUser] = React.useState(null)
    const [subscription, setSubscription] = React.useState(null)
    const [subUser, setSubUser] = React.useState([])
    const [selected, setSelected] = React.useState([])
    const [show, setShow] = React.useState(false)
    const handleSubmit = async (event) => {
        event.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' })
        if (selected.length === 0){
            event.stopPropagation()
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage("Subscription email can't be empty")
            setTimeout(() => {
                setNotification(false)
            }, 2000)
        } else {
            setLoading(true)
            const doc_id = window.location.href.split("/")[5];
            const subscription = {
                doc_id, child: selected
            }
            const result = await addMoreUserToSubscriber(subscription)
            if (result.code === 200) {
                setTimeout(() => {
                    setLoading(false)
                }, 2000)
                setNotification(true)
                setNotificationFlag('success')
                setNotificationMessage(result.message)
                setTimeout(() => {
                    setLoading(false)
                    window.location.replace("/subscriber/list")
                }, 3000)
            }
            else {
                setNotification(true)
                setNotificationFlag('danger')
                setNotificationMessage(result.message)
                setTimeout(() => {
                    setLoading(false)
                    setNotification(false)
                }, 3000)
            }
        }
    }
    const handleClose = (section) => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    } 

    React.useEffect(() => {
        const data = async () => {
            setLoading(true)
            let opt = []
            const doc_id = window.location.href.split("/")[5];
            const unselectUsersList = await unSelectedUsersList(doc_id)
            const result = await subscriptionList()
            const subscriberInfo = await getSubscriberById(doc_id)
            const parentUser = await fetchParentUserList(doc_id)
            if(result && subscriberInfo && parentUser && unselectUsersList.data){
                setSubUser(unselectUsersList.data)
                setGroupName(subscriberInfo.data.group_name)
                setExistsEmail(subscriberInfo.data.exists_members)
                parentUser.data.map((data) => {
                    let arr = {
                        value: data.uid,
                        label: data.email,
                    }
                    opt.push(arr)
                    if(data.uid == subscriberInfo.data.parent_id){
                        setParentUser(data.email)
                    }
                })
                setExistsSubUser(opt)
                result.data.map(data => {
                    if(data.id == subscriberInfo.data.subscription_id){
                        setSubscription(data.name)
                    }
                })
            }

            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        data()
    }, [])

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:90+'vh'}}>

                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Set More Subscriber</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <span><i className="icon dripicons-home"></i></span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span>Subscriber</span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/subscriber/list">
                                                <u>Subscriber List</u>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Set More Subscriber
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="card">
                                    <h5 className="card-header">Subscriber Update Form</h5>
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <form onSubmit={handleSubmit}>   
                                                {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Group Name</td>
                                                                    <td>
                                                                        <strong>
                                                                            {groupName}
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Parent User</td>
                                                                    <td>
                                                                        <strong>
                                                                            {parentUser}
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Subscription For</td>
                                                                    <td>
                                                                        <strong>
                                                                            {subscription}
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Subscriber List</td>
                                                                    <td>
                                                                        <div className="btn btn-info">
                                                                            <span onClick={() => handleShow()}>
                                                                                View List
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="email">Add Subscription Emails</label>
                                                        <MultiSelect
                                                            options={subUser}
                                                            value={selected}
                                                            onChange={setSelected}
                                                            labelledBy="Select"
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="card-footer bg-light">
                                                    <button type="submit" className="btn btn-primary">
                                                        Update Subscriber Group
                                                    </button>
                                                </div>
                                                
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* modal for existing users */}
                        <Modal show={show} onHide={() => handleClose()}>
                            <Modal.Header>
                                <Modal.Title>
                                    Already Exists Users
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ol>
                                            {
                                                existsEmail.map((item, i) => {
                                                    return <li key={i}>{item.email}</li>
                                                })
                                            }
                                        </ol>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => handleClose()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default SetSubscriber