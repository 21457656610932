
function NotificationComponent(){
    
    document.title = "Admin - Notification"

    return(
        <>
            <header className="page-header">
                <div className="d-flex align-items-center">
                    <div className="mr-auto">
                        <h1>Hello Notification Page</h1>
                    </div>
                </div>
            </header>
        </>
    )
}

export default NotificationComponent