import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import Notification from '../../../helpers/Notification'
import { account } from '../../../../helpers/text/AdminText'
import { changePassword } from '../../../../api/requests/admin/AdminRequest'
import { clearAdminCookies } from "../../../../helpers/Cookies"
const ChangePassword = () => {
    document.title = account.document    
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [currentPassword, setCurrentPassword] = React.useState('')
    const [newPassword, setNewPassword] = React.useState('')
    const [newRetypePassword, setNewRetypePassword] = React.useState('')
    const [show, setShow] = React.useState(false)
    const [modalTitle, setModalTitle] = React.useState('')
    const handleClose = (section) => {
        setShow(false)
    }
    const logout = (e) => {
        e.preventDefault()
		clearAdminCookies('adata')
		window.location.href = "/"
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' })
        if(
            currentPassword.length == 0 ||
            newPassword.length == 0 ||
            newRetypePassword.length == 0) {
            setNotification(true)
            setNotificationFlag('danger')
            let field = ''

            if (currentPassword.length == 0) {
                field = account.form_field.old_password
            } else if(newPassword.length == 0) {
                field = account.form_field.new_password
            } else if (newRetypePassword.length == 0){
                field = account.form_field.re_password
            }
            setNotificationMessage(account.form_error.empty(field))
            setTimeout(() => {
                setNotification(false)
            }, 2500)
            return false
        }
        if (newPassword !== newRetypePassword) {
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage(account.form_error.missmatch)
            setTimeout(() => {
                setNotification(false)
            }, 2500)

            return false
        }
        if (newRetypePassword.length < 8){
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage(account.form_error.min('password', 8))
            setTimeout(() => {
                setNotification(false)
            }, 2500)

            return false
        }
        const form_data = {
            currentPassword,
            newPassword,
            newRetypePassword
        }
        const result = await changePassword(form_data)
        if(result){
            if(result.status == 'success') {
                setNotification(true)
                setNotificationFlag('success')
                setNotificationMessage(result.message)
                setTimeout(() => {
                    setCurrentPassword('')
                    setNewPassword('')
                    setNewRetypePassword('')
                    setShow(true)
                    setNotification(false)
                }, 2500)
            } else if (result.status == 'error') {
                setNotification(true)
                setNotificationFlag('danger')
                setNotificationMessage(result.message)
                setTimeout(() => {
                    setNotification(false)
                }, 2500)
            }
        }
    }

    return(
        <> 
            <form onSubmit={handleSubmit}>
                {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}
                <div className="form-row">
                    <div className="col-12">
                        <h6 className="m-b-20">
                            {account.form_field.old_password}
                        </h6>
                        <div className="form-group">
                            <input 
                                type="password" 
                                id="old_pass" 
                                className="form-control" 
                                value={currentPassword}
                                onChange={(e) => {
                                    setCurrentPassword(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className="col-12">
                        <h6 className="m-b-20">
                            {account.form_field.new_password}
                        </h6>
                        <div className="form-group">
                            <input 
                                type="password" 
                                id="new_pass" 
                                className="form-control" 
                                value={newPassword}
                                onChange={(e) => {
                                    setNewPassword(e.target.value)
                                }}
                            />
                            <small id="passwordHelp" className="form-text text-muted">
                                {account.help_text}
                            </small>
                        </div>
                        <h6 className="m-b-20">
                            {account.form_field.re_password}
                        </h6>
                        <div className="form-group">
                            <input 
                                type="password" 
                                id="retype_pass" 
                                className="form-control" 
                                value={newRetypePassword}
                                onChange={(e) => {
                                    setNewRetypePassword(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer bg-light">
                    <button type="submit" className="btn btn-primary">
                        Update Password
                    </button>
                </div>
            </form>

            <Modal show={show} onHide={() => handleClose(modalTitle)}>
                <Modal.Header>
                    <Modal.Title>
                        {account.modal.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {account.modal.body}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose(modalTitle)}>
                        No
                    </Button>
                    <Button variant="primary" onClick={logout}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ChangePassword
