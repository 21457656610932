import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import { Link } from "react-router-dom"
import { ReadableDateTime } from '../../../../helpers/DateTime'
import { 
    fetchSubUsers, 
    getParentUsers, 
    deleteUser,
    changeUserPassword,
    searchUserByQuery, 
} from '../../../../api/requests/user/UserRequest'
import LoadingOverlay from 'react-loading-overlay'
import ActionBadge from '../../../helpers/ActionBadge'
import Notification from '../../../helpers/Notification'
import {user_list_text as UserText} from '../../../../helpers/text/UserListText'
document.title = UserText.document
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};
function UserList(){
    let [loading, setLoading] = React.useState(false);
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [visible, setVisible] = React.useState(false)
    const [subUserVisible, setSubUserVisible] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [fetchUsers, setFetchUsers] = React.useState([])
    const [removeId, setRemoveId] = React.useState(0)
    const [password, setPassword] = React.useState({pass: '', confirm_pass: ''})
    const [subId, setSubId] = React.useState([])
    const [pUid, setPUid] = React.useState(0)
    const [uidForPass, setUidForPass] = React.useState(0)
    const [show, setShow] = React.useState(false)
    const [showForm, setShowForm] = React.useState(false)
    const [modalTitle, setModalTitle] = React.useState('')
    const [userSearch, setUserSearch] = React.useState("")
    const handleClose = (section) => {
        setShow(false)
    }
    const handleFormClose = (section) => {
        setShowForm(false)
    }

    const loadSubUsers = async (id) => {
        setLoading(true)
        setPUid(id)
        let result = await fetchSubUsers(id)
        if (result) {
            setTimeout(() => {
                setLoading(false)
                setShow(true)
            }, 800)
            setSubId(result.data)
        }
    }

    React.useEffect(() => {
        const data = async () => {
          setLoading(true)
          const result = await getParentUsers()
          if (result) {
            setTimeout(() => {
                setLoading(false)
            }, 800)
          }
          setUsers(result.data)
          setFetchUsers(result.data)
        }
        data()
    }, [])

    const childFunc = React.useRef(null)
    
    let subtitle;

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function openFormModal() {
        setShowForm(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }
    
    function closeModal() {
        setIsOpen(false);
    }

    const removeUser = async (uid) => {
        setLoading(true)
        let result = await deleteUser(uid)
        if (result) {
            let result = await fetchSubUsers(pUid)
            setSubId(result.data)
            setTimeout(() => {
              setLoading(false)
            }, 800)
        }
    }

    const updatePassword = async (e) => {
        e.preventDefault();
        if(password.pass.length > 0 && password.confirm_pass.length > 0){
            if(password.pass !== password.confirm_pass){
                setNotification(true)
                setNotificationFlag('danger')
                setNotificationMessage('Password did not matched.')
                setTimeout(() => {
                    setNotification(false)
                }, 2000)
                return false
            } 
            setLoading(true)
            const result = await changeUserPassword(password, uidForPass)
            if(result.status === 'success') {
                setLoading(false)
                setNotification(true)
                setNotificationFlag('success')
                setNotificationMessage(result.message)
                setTimeout(() => {
                    setNotification(false)
                    setShowForm(false);
                }, 2000)
            } else {
                setLoading(false)
                setNotification(true)
                setNotificationFlag('danger')
                setNotificationMessage(result.message)
                setTimeout(() => {
                    setNotification(false)
                }, 2000)
            }
        } else {
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage('Fields can not be empty.')
            setTimeout(() => {
                setNotification(false)
            }, 2000)
            return false
        }
        return true
    }
    const changePassword = e => {
        const {name, value} = e.target;
        setPassword(prevState => ({
            ...prevState,
            [name]: value.trim()
        }));
    }

    const handleUserSearch = async (e) => {
        e.preventDefault()

        setLoading(true)
        if (userSearch.length > 0) {
            const result = await searchUserByQuery(userSearch);
            setUsers(result.data);
        }
        setLoading(false)
    }

    const handleReset = (e) => {
        e.preventDefault()

        setUserSearch("")
        setUsers(fetchUsers)
    }

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Data ... ...'>
                <div style={{minHeight:90+'vh'}}>

                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">
                                    Parent Users ID
                                </h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="icon dripicons-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            User
                                        </li>
                                        <li className="breadcrumb-item">
                                            User List
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <form className="card-header">
                                        <span>Parent Users ID</span>
                                        <input
                                            type="text"
                                            style={{ margin: "0 1rem" }}
                                            value={userSearch}
                                            onChange={(e) =>
                                                setUserSearch(e.target.value)
                                            }
                                        />
                                        <button
                                            type="submit"
                                            className="btn-primary"
                                            style={{ marginRight: "1rem" }}
                                            onClick={handleUserSearch}
                                        >
                                            Search
                                        </button>
                                        <button
                                            className="btn-secondary"
                                            onClick={handleReset}
                                        >
                                            Reset
                                        </button>
                                    </form>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Sub Id</th>
                                                        <th>Created</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {users ? users.map((data) => (
                                                    <tr key={data._id}>
                                                        <td>{data.uid}</td>
                                                        <td>{`${data.full_name.first_name} ${data.full_name.last_name}`}</td>
                                                        <td>{data.email}</td>
                                                        <td>
                                                            <span
                                                                className="badge badge-pill badge-dark" 
                                                                title="view all sub user"
                                                                onClick = {() => {
                                                                    openModal()
                                                                    loadSubUsers(data.uid)
                                                                }}>
                                                                <i className="zmdi zmdi-eye zmdi-hc-fw" style={{color: '#fff'}}></i>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {ReadableDateTime(data.created_at)}
                                                        </td>
                                                        <td>
                                                            {data.status == 1 ? 
                                                                (<span className="badge badge-pill badge-success">Active</span>) : 
                                                                (<span className="badge badge-pill badge-danger">Inactive</span>)
                                                            }
                                                        </td>
                                                        <td>
                                                            <ActionBadge type="view" page="sub-user"/>

                                                            <ActionBadge type="edit" page="sub-user" gap="true"/>

                                                            {/* button for change user password */}
                                                            <span 
                                                                className="badge badge-pill badge-danger"  
                                                                title="change password"
                                                                onClick={() => {
                                                                    openFormModal()
                                                                    setUidForPass(data.uid)
                                                                }}>
                                                                <i className="zmdi zmdi-key zmdi-hc-fw" style={{color: '#fff'}}></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )) : 'IDs Not Found'}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* modal for display child ids */}
                        <Modal size="lg" show={show} onHide={() => handleClose()}>
                            <Modal.Header>
                                <Modal.Title>
                                    Users List
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Email</th>
                                            <th>Status</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subId.length > 0 
                                            ? subId.map((data) => (
                                            <tr key={data.id}>
                                                <td>{data.uid}</td>
                                                <td>{data.email}</td>
                                                <td>{data.status == 1 ? 'Active' : 'Inactive'}</td>
                                                <td>{ReadableDateTime(data.created_at)}</td>
                                                <td>
                                                    <Link to={`/user/sub-user/edit?id=${data.uid}`}>
                                                        <span 
                                                            className="badge badge-pill badge-primary"
                                                            title="Edit sub user">
                                                            <i className="la la-edit" style={{color: '#fff'}}></i>
                                                        </span>
                                                    </Link>

                                                    {/* View Activity of User*/}
                                                    <Link to={`/user/sub-user/activity/${data.uid}`}>
                                                        <span
                                                            className="badge badge-pill badge-secondary"
                                                            title="Activity">
                                                            <i className="la la-history" style={{color: '#fff'}}></i>
                                                        </span>
                                                    </Link>

                                                    {/* View data access of User*/}
                                                    <Link to={`/user/sub-user/service-access/${data.uid}`}>
                                                        <span
                                                            className="badge badge-pill badge-warning"
                                                            title="Service Access">
                                                            <i className="zmdi zmdi-settings zmdi-hc-fw" style={{color: '#fff'}}></i>
                                                        </span>
                                                    </Link>
                                                    
                                                    <span 
                                                        onClick={() => removeUser(data.uid)}
                                                        className="badge badge-pill badge-danger" 
                                                        title="Delete sub user">
                                                        <i className="la la-remove" style={{color: '#fff'}}></i>
                                                    </span>
                                                    
                                                </td>
                                            </tr>
                                            )) : 'No sub user found'}
                                    </tbody>
                                </table>
                            </div>  
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => handleClose(modalTitle)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* modal for change password */}
                        <Modal show={showForm} onHide={() => handleFormClose()}>
                            <Modal.Header>
                                <Modal.Title>
                                    Update Password
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="table-responsive">
                                    <form>
                                        {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">
                                                New Password
                                            </label>
                                            <input 
                                                type="password" 
                                                name="pass"
                                                className="form-control" 
                                                id="exampleInputEmail1" 
                                                aria-describedby="emailHelp" 
                                                placeholder="New password"
                                                onChange={changePassword} 
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">
                                                Re-type New Password
                                            </label>
                                            <input 
                                                type="password" 
                                                name="confirm_pass"
                                                className="form-control" 
                                                id="exampleInputEmail1" 
                                                aria-describedby="emailHelp" 
                                                placeholder="Retype new password" 
                                                onChange={changePassword}
                                            />
                                            <small id="emailHelp" className="form-text text-muted">
                                                Retype your password to confirm that, you are exactlty want to change password. 
                                            </small>
                                        </div>
                                    </form>
                                </div>  
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="default" onClick={() => handleFormClose(modalTitle)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={ updatePassword }>
                                    Reset Password
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default UserList