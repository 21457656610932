export const ReadableDateTime = (timestamp) => {
    let date = new Date(timestamp * 1000)
    return date.toLocaleString()
}
  
export const CurrentDateTimeToUnix = () => {
    return Math.floor(new Date().getTime() / 1000)
}
  
export const AddedTimeWithCurrentDateTime = (minutes) => {
    return CurrentDateTimeToUnix() + minutes * 60
}
  