import React from 'react'
import { Link } from "react-router-dom"
import ActionBadge from '../../../helpers/ActionBadge'
import LoadingOverlay from 'react-loading-overlay'
import { rdcCompanyList, searchRdcCompany } from '../../../../api/requests/company/RdcCompanyRequest'
function CompanyListRDCComponent(){
    document.title = "RDC Company - List"
    let [loading, setLoading] = React.useState(false);

    const [company, setCompany] = React.useState([])
    const [fetchCompany, setFetchCompany] = React.useState([])
    const [companySearch, setCompanySearch] = React.useState("");
    const [pageNo, setPageNo] = React.useState(0)
    const [isDisable, setIsDisable] = React.useState(false)

    const fetchData = async (page) => {
        setLoading(true)
        const result = await rdcCompanyList(page)
        if (result) {
            setTimeout(() => {
                setLoading(false)
                setCompany(result.data)
            }, 800)
            
            if(page === 0){
                setFetchCompany(result.data)
            }
        }
    }

    const handlePreviousPage = () => {
        setPageNo((curPageNo) => {
            if (curPageNo > 0) {
                fetchData(curPageNo - 1);
                return curPageNo - 1;
            } else {
                return 0;
            }
        });
    };

    const handleNextPage = () => {
        setPageNo((curPageNo) => {
            fetchData(curPageNo + 1);
            return curPageNo + 1;
        });
    };

    const handleCompanySearch = async (e) => {
        e.preventDefault()

        setLoading(true)
        if (companySearch.length > 0) {
            const result = await searchRdcCompany(companySearch);
            setCompany(result);
            setPageNo(0)
            setIsDisable(true)
        }
        setLoading(false)
    };

    const handleReset = (e) => {
        e.preventDefault()

        setPageNo(0)
        setCompany(fetchCompany)
        setCompanySearch("")
        setIsDisable(false)
    };

    React.useEffect(() => {
        fetchData(pageNo)
    }, [pageNo])

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Company ... ...'>
                <div style={{minHeight:90+'vh'}}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">RDC Company List</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="icon dripicons-home"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            Company List
                                        </li>
                                        <li className="breadcrumb-item">
                                            Company Search
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                <form className="card-header">
                                        <span>Company List</span>
                                        <input
                                            type="text"
                                            style={{ margin: "0 1rem" }}
                                            value={companySearch}
                                            onChange={(e) =>
                                                setCompanySearch(e.target.value)
                                            }
                                        />
                                        <button
                                            type="submit"
                                            className="btn-primary"
                                            style={{ marginRight: "1rem" }}
                                            onClick={handleCompanySearch}
                                        >
                                            Search
                                        </button>
                                        <button
                                            className="btn-secondary"
                                            onClick={handleReset}
                                        >
                                            Reset
                                        </button>
                                    </form>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>ADV Code</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {company ? company.map((data) => (
                                                    <tr key={data._id}>
                                                        <td>{data.company_id}</td>
                                                        <td>{data.company_name}</td>
                                                        <td>{data.adv_code}</td>
                                                        <td>
                                                            {data.deleted === 0 ? (
                                                                <span class="badge badge-pill badge-success">Active</span>
                                                            ) : (
                                                                <span class="badge badge-pill badge-danger">Inactive</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <ActionBadge type="view" page="Company" />
                                                            <ActionBadge type="remove" page="Company" gap="true" />
                                                            <ActionBadge type="edit" page="Company" />
                                                        </td>
                                                    </tr>
                                                )) : 'Alert Not Found'}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-light">
                            <button
                                type="submit"
                                className="btn btn-primary mr-1"
                                disabled={isDisable}
                                onClick={handlePreviousPage}
                            >
                                Previous
                            </button>
                            <span className="btn btn-primary mr-1">
                                {pageNo + 1}
                            </span>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isDisable}
                                onClick={handleNextPage}
                            >
                                Next
                            </button>
                        </div>
                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default CompanyListRDCComponent