import Index from './Index'
export const account = {
    document: "Admin - Account",
    page_title: 'My Account',
    left_bar: ["Profile", 'Update Password'],
    inbox_loading: 'Updating Password Please Wait ......',
    breadcrumb: [
        {
            'link' : '/home',
            'title': 'Home'
        },
        {
            'link' : '#',
            'title': 'Profile'
        }
    ],
    form_field: {
        old_password: "Current Password",
        new_password: "New Password",
        re_password: "Retype Password"
    },
    form_error: {
        ...Index.form.error,
        missmatch: "New & Re-type Password Did Not Matched.",
    },
    modal: {
        title: "Password Changed Successfully.",
        body: "Do you want to login with new password ?"
    }, 

    help_text: "We recommend at least 8 characters long, avoiding patterns and common words/phrases.",
}