import React from 'react'
import Notification from '../../../helpers/Notification'
import {
    GetPermissionDetails
} from '../../../../api/requests/data-access/DataAccessRequest'
import LoadingOverlay from 'react-loading-overlay'
import {Button, Modal} from 'react-bootstrap'
import { Link } from 'react-router-dom'
const PermissionDeatils = () => {
    let [loading, setLoading] = React.useState(false);
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [details, setDetails] = React.useState(
      {
        ad_print:{
          company:{
            ids: [],
            item: []
          },
          brand: {
            ids: [],
            item: []
          },
          product_type: {
            ids: [],
            item: []
          },
          product: {
            ids: [],
            item: []
          },
        },
        ad_online: {
          company:{
            ids: [],
            item: []
          },
          brand: {
            ids: [],
            item: []
          },
          product_type: {
            ids: [],
            item: []
          },
          product: {
            ids: [],
            item: []
          },
        }
      }
    )
    const [show, setShow] = React.useState(false)
    const [modalInfo, setModalInfo] = React.useState([])
    const [modalTitle, setModalTitle] = React.useState("")
    React.useEffect(() => {
        const data = async () => {
            setLoading(true)
            let opt = []
            const doc_id = window.location.href.split("/")[5];
            const fetch_permission = await GetPermissionDetails(doc_id)
            if(fetch_permission){
              setDetails(fetch_permission.data)
            }
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        data()
    }, [])

    const handleButtonClick = (modal_for) => {
        let title = ""
        let info = []

        switch (modal_for) {
            case 'ad_print_company':
                title = "Permitted Company | Ad Print"
                info = details.ad_print.company.item
                break;
            case 'ad_print_brand':
                title = "Permitted Brand | Ad Print"
                info = details.ad_print.brand.item
                break;
            case 'ad_print_product_type':
                title = "Permitted Product Type | Ad Print"
                info = details.ad_print.product_type.item
                break;
            case 'ad_print_product':
                title = "Permitted Product | Ad Print"
                info = details.ad_print.product.item
                break;
            case 'ad_online_company':
                title = "Permitted Company | Ad Online"
                info = details.ad_online.company.item
                break;
            case 'ad_online_brand':
                title = "Permitted Brand | Ad Online"
                info = details.ad_online.brand.item
                break;
            case 'ad_online_product_type':
                title = "Permitted Product Type | Ad Online"
                info = details.ad_online.product_type.item
                break;
            case 'ad_online_product':
                title = "Permitted Product | Ad Online"
                info = details.ad_online.product.item
                break;
        
            default:
                break;
        }

        setModalTitle(title)
        setModalInfo(info)
        setShow(true)
    }

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:90+'vh'}}>

                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Data Permission Details</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <span><i className="icon dripicons-home"></i></span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span>Data Service</span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/permission/list">
                                                <u>Permission List</u>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Permission Details
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div>
                            <span>Client: </span>
                            <span><strong>{ details.client_name } ({ details.user_email })</strong></span>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <h5 className="card-header">
                                        Advertisement Print
                                    </h5>
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <tbody>
                                                    {
                                                        details?.ad_print?.access_type === 2
                                                        ?
                                                        <tr>
                                                            <td className="text-center"><h4>All Data Permitted</h4></td>
                                                        </tr>
                                                        :
                                                        <tr>
                                                            <td className="text-center">
                                                                <button
                                                                className={`btn ${(details.ad_print.company.ids.length>0)? 'btn-success' : 'btn-secondary'} `}
                                                                onClick={() => handleButtonClick('ad_print_company')}
                                                                >
                                                                    Company - {details.ad_print.company.ids.length}
                                                                </button>
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                className={`btn ${(details.ad_print.brand.ids.length>0)? 'btn-success' : 'btn-secondary'} `}
                                                                onClick={() => handleButtonClick('ad_print_brand')}
                                                                >
                                                                    Brand - {details.ad_print.brand.ids.length}
                                                                </button>
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                className={`btn ${(details.ad_print.product_type.ids.length>0)? 'btn-success' : 'btn-secondary'} `}
                                                                onClick={() => handleButtonClick('ad_print_product_type')}
                                                                >
                                                                    Product Type - {details.ad_print.product_type.ids.length}
                                                                </button>
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                className={`btn ${(details.ad_print.product.ids.length>0)? 'btn-success' : 'btn-secondary'} `}
                                                                onClick={() => handleButtonClick('ad_print_product')}
                                                                >
                                                                    Product - {details.ad_print.product.ids.length}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card">
                                    <h5 className="card-header">
                                        Advertisement Online
                                    </h5>
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <tbody>
                                                {
                                                    details?.ad_online?.access_type === 2
                                                    ?
                                                    <tr>
                                                        <td className="text-center"><h4>All Data Permitted</h4></td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        <td className="text-center">
                                                            <button
                                                            className={`btn ${(details.ad_online.company.ids.length>0)? 'btn-success' : 'btn-secondary'} `}
                                                            onClick={() => handleButtonClick('ad_online_company')}
                                                            >
                                                                Company - {details.ad_online.company.ids.length}
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                            className={`btn ${(details.ad_online.brand.ids.length>0)? 'btn-success' : 'btn-secondary'} `}
                                                            onClick={() => handleButtonClick('ad_online_brand')}
                                                            >
                                                                Brand - {details.ad_online.brand.ids.length}
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                            className={`btn ${(details.ad_online.product_type.ids.length>0)? 'btn-success' : 'btn-secondary'} `}
                                                            onClick={() => handleButtonClick('ad_online_product_type')}
                                                            >
                                                                Product Type - {details.ad_online.product_type.ids.length}
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                            className={`btn ${(details.ad_online.product.ids.length>0)? 'btn-success' : 'btn-secondary'} `}
                                                            onClick={() => handleButtonClick('ad_online_product')}
                                                            >
                                                                Product - {details.ad_online.product.ids.length}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <Modal size="lg" show={show} onHide={() => setShow(false)}>
                        <Modal.Header>
                            <Modal.Title>
                                { modalTitle }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modalInfo.map((each, index) => (
                                        <tr key={index}>
                                            <td>{ each.id }</td>
                                            <td>{ each.name }</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>  
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShow(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </LoadingOverlay>
        </>
    )
}

export default PermissionDeatils
