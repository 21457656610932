import React from 'react';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import "react-datepicker/dist/react-datepicker.css";
import { getSubUsers } from './../../../../../../../api/requests/user/UserRequest'
import { 
    tvcMediaList,
    tvcAdTypeList,
    searchTvCompany,
    searchTvcProduct,
    searchTVBrandByName,
    searchTvcProductType,
} from './../../../../../../../api/requests/company/TvcRequest';
const checkbox = {
    brand: {
        brand_all: { key: "brand_all", value: 1 }, 
        company_brand_all: { key: "company_brand_all", value: 2 },
        company_more_brand: { key: "company_more_brand", value: 3 },
        selected_brand_only: { key: "selected_brand_only", value: 4 },
    },
    product: {
        product_all: { key: "product_all", value: 1 }, 
        product_type_all_product: { key: "product_type_all_product", value: 2 },
        product_type_more_product: { key: "product_type_more_product", value: 3 },
        selected_product_only: { key: "selected_product_only", value: 4 },
    },
    media: {
        media_all: { key: "media_all", value: 1 }
    },
    ad_type: {
        ad_type_all: { key: "ad_type_all", value: 1 }
    }
}
const TvcAdEditForm = ({data}) => {
    let [loading, setLoading] = React.useState(false);
    const { parent, stateinfo, stateData } = data;
    const p_id = parent.uid;
    const [childUser, setChildUser] = React.useState([]);
    const [mediaList, setMediaList] = React.useState([]);
    const [adTypeList, setAdTypeList] = React.useState([]);
    const [activeDate, setActiveDate] = React.useState(stateinfo.tv_ad.is_local);
    const [selectedChild, setSelectedChild] = React.useState(stateinfo.tv_ad.child_list);
    const [allChild, setAllChild] = React.useState(stateinfo.tv_ad.child_all);
    const [selectedCompany, setSelectedCompany] = React.useState(stateinfo.tv_ad.company_list);
    const [selectedBrand, setSelectedBrand] = React.useState(stateinfo.tv_ad.brand_list);
    const [brandSelect, setBrandSelect] = React.useState(stateinfo.tv_ad.brand_select);
    const [selectedProductType, setSelectedProductType] = React.useState(stateinfo.tv_ad.product_type_list);
    const [selectedProduct, setSelectedProduct] = React.useState(stateinfo.tv_ad.product_list);
    const [productSelect, setProductSelect] = React.useState(stateinfo.tv_ad.product_select);
    const [selectedMedia, setSelectedMedia] = React.useState(stateinfo.tv_ad.media_list);
    const [mediaSelect, setMediaSelect] = React.useState(stateinfo.tv_ad.media_select);
    const [selectedAdType, setSelectedAdType] = React.useState(stateinfo.tv_ad.media_ad_type_list);
    const [adTypeSelect, setAdTypeSelect] = React.useState(stateinfo.tv_ad.media_ad_type_select);
    const [startDate, setStartDate] = React.useState(stateinfo.tv_ad.start_date);
    const [endDate, setEndDate] = React.useState(stateinfo.tv_ad.end_date);
    React.useEffect(() => {
        fetchChildUsers();
    }, [])
    const fetchChildUsers = async () => {
        let opt = []
        setLoading(true)
        const result = await getSubUsers(p_id)
        if (result) {
            result.data.map((data, index) => {
                let arr = {
                    value: data.uid,
                    label: data.email,
                }
                opt.push(arr)
            })
            setTimeout(() => {
                setLoading(false)
            }, 800)
        }
        setChildUser(opt)
    }
    const setStateData = (data, key) => {
        stateData((prevState) => ({
            ...prevState,
            tv_ad: {
                ...prevState.tv_ad,
                [key]: data
            }
        }))
    }
    const handleSelectChange = (data, key) => {
        switch (key){
            case 'local_date_change':
                setActiveDate(data);
                break;

            case 'child_change':
                setSelectedChild(data)
                setStateData(data, 'child_list')
                break;

            case 'company_change':
                setSelectedCompany(data)
                setStateData(data, 'company_list')
                break;

            case 'brand_change':
                setSelectedBrand(data)
                setStateData(data, 'brand_list')
                break;

            case 'product_type_change':
                setSelectedProductType(data)
                setStateData(data, 'product_type_list')
                break;

            case 'product_change':
                setSelectedProduct(data)
                setStateData(data, 'product_list')
                break;
                
            case 'media_change':
                setSelectedMedia(data)
                setStateData(data, 'media_list')
                break;

            case 'media_ad_type_change':
                setSelectedAdType(data)
                setStateData(data, 'media_ad_type_list')
                break;
        }
    }
    const checkedAllOption = (option) => {
        if(option.category === 'child'){
            setAllChild(!allChild)
            setStateData(!allChild, 'child_all')
        }
        else if(option.type === 'brand'){
            if(checkbox[option.type][option.category].value == brandSelect) {
                setBrandSelect(0);
                setStateData(0, 'brand_select')
            } else {
                setBrandSelect(checkbox[option.type][option.category].value);
                setStateData(checkbox[option.type][option.category].value, 'brand_select')
            }
        }
        else if(option.type === 'product'){
            if(checkbox[option.type][option.category].value == productSelect) {
                setProductSelect(0);
                setStateData(0, 'product_select')
            } else {
                setProductSelect(checkbox[option.type][option.category].value);
                setStateData(checkbox[option.type][option.category].value, 'product_select')
            }
        }
        else if(option.type === 'media'){
            if(checkbox[option.type][option.category].value == mediaSelect) {
                setMediaSelect(0);
                setStateData(0, 'media_select')
            } else {
                setMediaSelect(checkbox[option.type][option.category].value);
                setStateData(checkbox[option.type][option.category].value, 'media_select')
            }
        }
        else if(option.type === 'ad_type'){
            if(checkbox[option.type][option.category].value == adTypeSelect) {
                setAdTypeSelect(0);
                setStateData(0, 'media_ad_type_select')
            } else {
                setAdTypeSelect(checkbox[option.type][option.category].value);
                setStateData(checkbox[option.type][option.category].value, 'media_ad_type_select')
            }
        }
        else if(option.type === 'active_local'){
            setActiveDate(!activeDate);
            setStateData(!activeDate, 'is_local');
        }
    }
    const loadTVCompanyOption = async (search) => {
        if(wordSplit(search)){
            return await searchTvCompany(search)
        }
    }
    const loadTVBrandOption = async (search) => {
        if(wordSplit(search)){
            return await searchTVBrandByName(search)
        }
    }
    const loadTVProductTypeOption = async (search) => {
        if(wordSplit(search)){
            return await searchTvcProductType(search)
        }
    }
    const loadTVProductOption = async (search) => {
        if(wordSplit(search)){
            return await searchTvcProduct(search)
        }
    }
    const loadMediaOption = async () => {
        const data = await tvcMediaList()
        let media = data.map((item) => {
            return {value: item.media_id, label: item.media_name}
        })
        setMediaList(media)
    }
    const loadAdTypeOption = async () => {
        const data = await tvcAdTypeList()
        let ad_type = data.map((item) => {
            return {value: item.ad_type_id, label: item.ad_type_name}
        })
        setAdTypeList(ad_type)
    }
    const wordSplit = (search) => {
        let word = search.split(" ")
        let word_len = word.length
        if(word[word_len-1].length == 0)
            return true
        else
            return false
    }

    return(
        <>
            <section className="page-content container-fluid">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="form-group">
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="media">
                                                Access Start Date
                                            </label>
                                            <DatePicker
                                                className="form-control"
                                                selected={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date)
                                                    setStateData(date, "start_date")
                                                }} 
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="media">
                                                Access End Date
                                            </label>
                                            <DatePicker
                                                className="form-control"
                                                selected={endDate}
                                                onChange={(date) => {
                                                    setEndDate(date)
                                                    setStateData(date, "end_date")
                                                }} 
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-check">
                                            <input
                                                id="is_local"
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={activeDate}
                                                onChange={() => checkedAllOption({ category:'local_date', type:'active_local' })}
                                            />
                                            <label className="form-check-label" htmlFor="is_local">
                                                Active This Date Range
                                            </label>
                                        </div>
                                    </div>

                                    <span className="draw-hr"/>
                                    
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="company">Select Child User</label>
                                            <Select
                                                isMulti
                                                options={childUser}
                                                value={selectedChild}
                                                onChange={(value) => handleSelectChange(value, 'child_change')}
                                                isDisabled={(allChild) ? true : false}
                                            />
                                        </div>
                                        <div className="form-check">
                                            <input
                                                id="all_child"
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={allChild}
                                                onChange={() => checkedAllOption({category:'child', type:'all_select'})}
                                            />
                                            <label className="form-check-label" htmlFor="all_child">
                                                All Child User
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <span className="draw-hr"/>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="company">Select Company</label>
                                            <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                defaultOptions
                                                value={selectedCompany}
                                                loadOptions={loadTVCompanyOption}
                                                getOptionLabel={e => e.company_name}
                                                getOptionValue={e => e.company_id}
                                                onChange={(value) => handleSelectChange(value, 'company_change')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="brand">
                                                Select Brand
                                            </label>
                                            <AsyncSelect 
                                                isMulti
                                                cacheOptions
                                                defaultOptions
                                                value={selectedBrand}
                                                loadOptions={loadTVBrandOption}
                                                getOptionLabel={e => e.brand_name}
                                                getOptionValue={e => e.brand_id}
                                                onChange={(value) => handleSelectChange(value, 'brand_change')}
                                                isDisabled={ ([1,2].includes(brandSelect) )}
                                            />
                                        </div>

                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="all_brand"
                                                checked={ (brandSelect === 1) ? true : false }
                                                onChange={() => checkedAllOption({ category:'brand_all', type:'brand' })}
                                            />
                                            <label className="form-check-label" htmlFor="all_brand">
                                                All Brand
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="company_all_brand"
                                                checked={ (brandSelect === 2) ? true : false }
                                                onChange={() => checkedAllOption({ category:'company_brand_all', type:'brand' })}
                                            />
                                            <label className="form-check-label" htmlFor="company_all_brand">
                                                Company All Brand
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="company_more_brand"
                                                checked={ (brandSelect === 3) ? true : false }
                                                onChange={() => checkedAllOption({category:'company_more_brand',type:'brand'})}
                                            />
                                            <label className="form-check-label" htmlFor="company_more_brand">
                                                Company & More Brand
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="selected_brand_only"
                                                checked={ (brandSelect === 4) ? true : false }
                                                onChange={() => checkedAllOption({ category:'selected_brand_only', type:'brand' })}
                                            />
                                            <label className="form-check-label" htmlFor="selected_brand_only">
                                                Selected Brand Only
                                            </label>
                                        </div>
                                    </div>

                                    <span className="draw-hr"/>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="product_type">
                                                Select Product Type
                                            </label>
                                            <AsyncSelect
                                                isMulti
                                                cacheOptions
                                                defaultOptions
                                                value={selectedProductType}
                                                loadOptions={loadTVProductTypeOption}
                                                getOptionLabel={e => e.product_type_name}
                                                getOptionValue={e => e.product_type_id}
                                                onChange={(value) => handleSelectChange(value, 'product_type_change')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">

                                        <div className="form-group">
                                            <label htmlFor="product">
                                                Select Product
                                            </label>
                                            <AsyncSelect  
                                                isMulti
                                                cacheOptions
                                                defaultOptions
                                                value={selectedProduct}
                                                loadOptions={loadTVProductOption}
                                                getOptionLabel={e => e.product_name}
                                                getOptionValue={e => e.product_id}
                                                onChange={(value) => handleSelectChange(value, 'product_change')}
                                                isDisabled={ ([1,2].includes(productSelect) )}
                                            />
                                        </div>

                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="all_product"
                                                checked={ (productSelect === 1) ? true : false }
                                                onChange={() => checkedAllOption({ category:'product_all', type:'product' })}
                                            />
                                            <label className="form-check-label" htmlFor="all_product">
                                                All Product
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="product_type_all_product"
                                                checked={ (productSelect === 2) ? true : false }
                                                onChange={() => checkedAllOption({ category:'product_type_all_product', type:'product' })}
                                            />
                                            <label className="form-check-label" htmlFor="product_type_all_product">
                                                Product-type All Product
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="product_type_more_product"
                                                checked={ (productSelect === 3) ? true : false }
                                                onChange={() => checkedAllOption({ category:'product_type_more_product', type:'product' })}
                                            />
                                            <label className="form-check-label" htmlFor="product_type_more_product">
                                                Product-type & More Product
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="selected_product_only"
                                                checked={ (productSelect === 4) ? true : false }
                                                onChange={() => checkedAllOption({ category:'selected_product_only', type:'product' })}
                                            />
                                            <label className="form-check-label" htmlFor="selected_product_only">
                                                Selected Product Only
                                            </label>
                                        </div>
                                        
                                    </div>

                                    <span className="draw-hr"/>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="media">
                                                Select Media
                                            </label>
                                            <Select
                                                onFocus={loadMediaOption}
                                                defaultValue={selectedMedia}
                                                options={mediaList}
                                                isMulti
                                                checked={ (brandSelect === 3) ? true : false }
                                                onChange={(value) => handleSelectChange(value, 'media_change')}
                                                isDisabled={(mediaSelect === 1) ? true : false}
                                            />
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="all_media"
                                                checked={(mediaSelect === 1) ? true : false}
                                                onChange={() => checkedAllOption({category:'media_all',type:'media'})}
                                            />
                                            <label className="form-check-label" htmlFor="all_media">
                                                All Media
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="media">
                                                Select Ad Type
                                            </label>
                                            <Select
                                                onFocus={loadAdTypeOption}
                                                defaultValue={selectedAdType}
                                                onChange={(value) => handleSelectChange(value, 'media_ad_type_change')}
                                                options={adTypeList}
                                                isMulti
                                                isDisabled={(adTypeSelect === 1) ? true : false}
                                            />
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="all_ad_type"
                                                checked={ (adTypeSelect === 1) ? true : false }
                                                onChange={() => checkedAllOption({category:'ad_type_all',type:'ad_type'})}
                                            />
                                            <label className="form-check-label" htmlFor="all_ad_type">
                                                All Ad-Type
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
            </section>
        </>
    )
}

export default TvcAdEditForm;