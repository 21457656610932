import AxiosInstance from '../Index'
import { log } from '../../../helpers/ConsoleLog'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
export const addSubscriber = async (data) => {
    const params = new URLSearchParams()
    params.append('parent_id', data.parent_id)
    params.append('subscription_id', data.subscription_id)
    params.append('subscriber_length', data.child.length)
    params.append('subscriber_name', data.name)
    data.child.map((dt, index) => {
        params.append(`subUserId_${index + 1}`, dt.value)
        params.append(`subUserEmail_${index + 1}`, dt.label)
    })

    const result = await AxiosInstance.post(EndPoints.add_subscriber, params)
        .then((res) => {
            return SuccessResponse(res)
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    return result
}

export const FetchSubscriberList = async () => {
    const result = await AxiosInstance.post(EndPoints.list_subscriber)
        .then((res) => {
            return SuccessResponse(res)
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    return result
}

export const FetchSubscriberGroup = async (page=0) => {
    const params = new URLSearchParams()
    params.append('page', page)
    
    const result = await AxiosInstance.post(EndPoints.subscriber_group, params)
                    .then((res) => {
                        return SuccessResponse(res)
                    })
                    .catch((err) => {
                        return ErrorResponse(err)
                    })
    return result
}

export const FetchSingleSubscriberGroupUsers = async (id) => {
    const params = new URLSearchParams()
    params.append('doc_id', id)

    const result = await AxiosInstance.post(EndPoints.subscriber_group_users, params)
                    .then((res) => {
                        return SuccessResponse(res)
                    })
                    .catch((err) => {
                        return ErrorResponse(err)
                    })
    return result
}

export const removeUserFromSubscriber = async (doc_id, id) => {
    try{
        const params = new URLSearchParams()
        params.append('uid', id)
        params.append('id', doc_id)
        
        const result = await AxiosInstance.post(EndPoints.subscriber_remove_user, params)
                    .then((res) => {
                        log('success', "remove user successfully.")
                        return SuccessResponse(res)
                    })
                    .catch((err) => {
                        return ErrorResponse(err)
                    })
        
        return result
    } catch { }
}

export const getSubscriberById = async (doc_id) => {
    try {
        const params = new URLSearchParams()
        params.append('id', doc_id)
        const result = await AxiosInstance.post(EndPoints.subscriber_group_fetch_by_id, params)
                    .then((res) => {
                        log('success', "successfully fetch subscriber details.")
                        return SuccessResponse(res)
                    })
                    .catch((err) => {
                        return ErrorResponse(err)
                    })
        
        return result
    } catch {
        log('error', "Unable to fetch subscriber group details.")
    }
}

export const unSelectedUsersList = async (doc_id) => {
    try{
        const params = new URLSearchParams()
        params.append('id', doc_id)

        const result = await AxiosInstance.post(EndPoints.subscriber_group_unselected_users, params)
                        .then(res => {
                            return SuccessResponse(res)
                        })
                        .catch(err => {
                            return ErrorResponse(err)
                        })

        return result
    } catch { }
}

export const addMoreUserToSubscriber = async (data) => {
    try{
        const params = new URLSearchParams()
        params.append('doc_id', data.doc_id)
        params.append('subscriber_length', data.child.length)
        data.child.map((dt, index) => {
            params.append(`subUserId_${index + 1}`, dt.value)
            params.append(`subUserEmail_${index + 1}`, dt.label)
        })

        const result = await AxiosInstance.post(EndPoints.add_more_user_to_subscriber, params)
                        .then(res => {
                            return SuccessResponse(res)
                        })
                        .catch(err => {
                            return ErrorResponse(err)
                        })
        return result
    } catch { }
}

export const searchSubscriberList = async (search_q) => {
    try{
      const params = new URLSearchParams()
      params.append('search_q', search_q)

      const result = await AxiosInstance.post(EndPoints.search_subscriber, params)
                    .then((res) => {
                      return SuccessResponse(res)
                    })
                    .catch((err) => {
                      return ErrorResponse(err)
                    })
      return result
    } catch(err) {
      console.log('Error :: ', err)
      return []
    }
 }