import React from 'react'
import { FetchSubscriberList } from '../../../../api/requests/subscriber/SubscriberRequest'
import { subscriptionList } from '../../../../api/requests/subscription/SubscriptionRequest'
import { ReadableDateTime } from '../../../../helpers/DateTime'
import { binarySearch } from '../../../../helpers/search/Binary'
import LoadingOverlay from 'react-loading-overlay'
const SubscriberSummary = () => {
    let [loading, setLoading] = React.useState(false);
    const [subcribers, setSubscribers] = React.useState([])
    const [result, setResult] = React.useState([])
  
    React.useEffect(() => {
      const data = async () => {
        setLoading(true)
        const data = await FetchSubscriberList()
        const subscription_list = await subscriptionList('LIST')
        console.log('subscriptionList', subscription_list);
        if (data && subscription_list) {
          for (let i = 0; i < data.data.length; i++) {
            let res = binarySearch(
              subscription_list.data,
              data.data[i]._id,
              0,
              subscription_list.data.length,
            )
            data.data[i].subscription = res
          }

          setTimeout(() => {
            setSubscribers(data.data)
            setLoading(false)
          }, 800)
        } else {
          setTimeout(() => {
            setLoading(false)
          }, 800)
        }
      }
      data()
    }, [])

    return (
      <>
        <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
            <div style={{minHeight:90+'vh'}}>
                <header className="page-header">
                    <div className="d-flex align-items-center">
                        <div className="mr-auto">
                            <h1 className="separator">Summary</h1>
                            <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <span><i className="icon dripicons-home"></i></span>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span>Subscriber</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Subscriber List
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </header>

                <section className="page-content container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <h5 className="card-header">
                                    Subscriber Subscription Summary
                                </h5>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Subscription Name</th>
                                                    <th>Total Subscriber</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subcribers.length ? subcribers.map((item) => (
                                                    <tr key={item._id}>
                                                        <td>{item.subscription.name}</td>
                                                        <td>{item.Total}</td>
                                                        <td>
                                                            <span class="badge badge-pill badge-dark " title="view subscriber list">
                                                                <i class="zmdi zmdi-eye zmdi-hc-fw text-white"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )) : 'Subscriber Not Found'}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </LoadingOverlay>
      </>
    )
  }
  
export default SubscriberSummary