
function HomeComponent(){

    document.title = "Admin - Home"

    return(
        <>
            <header className="page-header">
                <div className="d-flex align-items-center">
                    <div className="mr-auto">
                        <h1>Dashboard</h1>
                    </div>
                </div>
            </header>
            <section className="page-content container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="row m-0 col-border-xl">
                                <div className="col-md-12 col-lg-6 col-xl-3">
                                    <div className="card-body">
                                        <div className="icon-rounded icon-rounded-primary float-left m-r-20">
                                            <i className="zmdi zmdi-attachment-alt zmdi-hc-fw"></i>
                                        </div>
                                        <h5 className="card-title m-b-5 counter" data-count="0">0</h5>
                                        <h6 className="text-muted m-t-10">
                                            Custom Alert <small>Today</small>
                                        </h6>
                                        <div className="progress progress-active-sessions mt-4" style={{height: "7px"}}>
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-3">
                                    <div className="card-body">
                                        <div className="icon-rounded icon-rounded-accent float-left m-r-20">
                                            <i className="la la-envelope"></i>
                                        </div>
                                        <h5 className="card-title m-b-5 counter" data-count="0">0</h5>
                                        <h6 className="text-muted m-t-10">
                                            Daily Alert <br/><small>Today</small>
                                        </h6>
                                        <div className="progress progress-add-to-cart mt-4" style={{height: "7px"}}>
                                            <div className="progress-bar bg-accent" role="progressbar" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-3">
                                    <div className="card-body">
                                        <div className="icon-rounded icon-rounded-info float-left m-r-20">
                                            <i className="la la-database"></i>
                                        </div>
                                        <h5 className="card-title m-b-5 counter" data-count="0">0</h5>
                                        <h6 className="text-muted m-t-10">
                                            Data Received <br/> <small>Today</small>
                                        </h6>
                                        <div className="progress progress-new-account mt-4" style={{height: "7px"}}>
                                            <div className="progress-bar bg-info" role="progressbar" aria-valuenow="83" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-3">
                                    <div className="card-body">
                                        <div className="icon-rounded icon-rounded-success float-left m-r-20">
                                            <i className="la la-dollar f-w-600"></i>
                                        </div>
                                        <h5 className="card-title m-b-5 prepend-currency counter" data-count="37873">0</h5>
                                        <h6 className="text-muted m-t-10">
                                            Data Search <br/> <small>Today</small>
                                        </h6>
                                        <div className="progress progress-total-revenue mt-4" style={{height: "7px"}}>
                                            <div className="progress-bar bg-success" role="progressbar" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeComponent