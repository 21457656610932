import AxiosInstance from '../Index'
import EndPoints from '../../end-points/EndPoints'
import { SuccessResponse, ErrorResponse } from '../../helpers/Request'
import { CONS } from '../../../helpers/Constant'
export const addAdmin = async (data) => {
  const params = new URLSearchParams()
  params.append('firstname', data.firstName)
  params.append('lastname', data.lastName)
  params.append('email', data.email)
  params.append('password', data.password)

  const result = await AxiosInstance.post(EndPoints.streaming_admin_add, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}

export const adminList = async () => {
  const result = await AxiosInstance.post(EndPoints.streaming_admin_list)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })
  return result
}

export const getUserById = async (data) => {
  const params = new URLSearchParams()
  params.append('u_id', data.u_id)

  const result = await AxiosInstance.post(EndPoints.user_get, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}

export const updateUser = async (data) => {
  const params = new URLSearchParams()
  for (const [key, value] of Object.entries(data)) {
    if (value !== 'undefined' || value !== '') {
      params.append(key, value)
    }
  }

  const result = await AxiosInstance.post(EndPoints.user_update, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}

export const deleteUser = async (data) => {
  const params = new URLSearchParams()
  params.append('id', data)
  params.append('isdelete', 1)

  const result = await AxiosInstance.post(EndPoints.user_delete, params)
    .then((res) => {
      return SuccessResponse(res)
    })
    .catch((err) => {
      return ErrorResponse(err)
    })

  return result
}
