import React from 'react'

const ActionBadge = (data) => {

    const badge = {
        bg: {
            view: 'dark',
            remove: 'danger',
            edit: 'primary',
        },
        text: {
            view: 'details',
            remove: 'remove',
            edit: 'edit',
        },
        icon: {
            view: 'la la-list',
            remove: 'la la-remove',
            edit: 'zmdi zmdi-edit zmdi-hc-fw',
        },
    }

    return (
        <>
            {(data.type === 'edit') ? (
                <span 
                    className={`badge badge-pill badge-${badge.bg[data.type]} ${(data.gap?'m-lg-1':'')}`} 
                    title={`${data.page} ${badge.text[data.type]}`}
                    onClick={() => { alert('Edit Clicked') }}>
                    <i className={badge.icon[data.type]} style={{color: '#fff'}}></i>
                </span>
            ) : (data.type === 'view') ? (
                <span 
                    className={`badge badge-pill badge-${badge.bg[data.type]} ${(data.gap?'m-lg-1':'')}`} 
                    title={`${data.page} ${badge.text[data.type]}`}
                    onClick={() => { alert('View Clicked') }}>
                    <i className={badge.icon[data.type]} style={{color: '#fff'}}></i>
                </span>
            ) : (
                <span 
                    className={`badge badge-pill badge-${badge.bg[data.type]} ${(data.gap?'m-lg-1':'')}`} 
                    title={`${data.page} ${badge.text[data.type]}`}
                    onClick={() => { alert('Delete Clicked') }}>
                    <i className={badge.icon[data.type]} style={{color: '#fff'}}></i>
                </span>
            )}
        </>
    )
}

export default ActionBadge
