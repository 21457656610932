import React from "react";
import { searchSubscriptionList, subscriptionList } from "../../../api/requests/subscription/SubscriptionRequest";
import { ReadableDateTime } from "../../../helpers/DateTime";
import LoadingOverlay from "react-loading-overlay";
const SubscriptionList = () => {
    let [loading, setLoading] = React.useState(false);
    const [subscription, setSubscription] = React.useState([]);
    const [fetchSubscription, setFetchSubscription] = React.useState([]);
    const [length, setLength] = React.useState(0);
    const [items, setItems] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [searchSubscription, setSearchSubscription] = React.useState("");
    const [isDisable, setIsDisable] = React.useState(false)
    const paginationClicked = () => {
        console.log("Clicked");
    };
    const nextPagination = () => {
        let now_page = currentPage + 1;
        let start = now_page * 10 - 10;
        let end = start + 10;
        let len = Math.ceil(items.length / 10)

        if(currentPage < len){
          setSubscription(items.slice(start, end));
          setCurrentPage(now_page);
        }
    };

    const prevPagination = () => {
        let now_page = currentPage - 1;
        let start = now_page * 10 - 10;
        let end = start + 10;

        if(currentPage > 1){
          setSubscription(items.slice(start, end));
          setCurrentPage(now_page);
        }
    };

    const sliceItems = () => {
        console.log(subscription);
    };

    const handleSubscriptionSearch = async (e) => {
        e.preventDefault()

        setLoading(true)
        if (searchSubscription.length > 0) {
            const result = await searchSubscriptionList(searchSubscription);
            setSubscription(result.data);
            setCurrentPage(1)
            setIsDisable(true)
        }
        setLoading(false)
    };

    const handleReset = (e) => {
        e.preventDefault()

        setCurrentPage(1)
        setSubscription(fetchSubscription)
        setSearchSubscription("")
        setIsDisable(false)
    };

    React.useEffect(() => {
        const data = async () => {
            setLoading(true);
            const result = await subscriptionList()
                .then((res) => {
                    setLength(res.data.length);
                    setItems(res.data);
                    setSubscription(res.data.slice(0, 10));
                    setFetchSubscription(res.data.slice(0, 10));
                    return true;
                })
                .catch((err) => {
                    return true;
                });
            if (result) {
                setTimeout(() => {
                    setLoading(false);
                }, 700);
            }
        };
        data();
    }, []);

    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner
                text="Fetching Subscription List ... ..."
            >
                <div style={{ minHeight: 90 + "vh" }}>
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Subscription</h1>
                                <nav
                                    className="breadcrumb-wrapper"
                                    aria-label="breadcrumb"
                                >
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <span>
                                                <i className="icon dripicons-home"></i>
                                            </span>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            Subscription List
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <form className="card-header">
                                        <span>Subscription List</span>
                                        <input
                                            type="text"
                                            style={{ margin: "0 1rem" }}
                                            value={searchSubscription}
                                            onChange={(e) =>
                                                setSearchSubscription(e.target.value)
                                            }
                                        />
                                        <button
                                            type="submit"
                                            className="btn-primary"
                                            style={{ marginRight: "1rem" }}
                                            onClick={handleSubscriptionSearch}
                                        >
                                            Search
                                        </button>
                                        <button
                                            className="btn-secondary"
                                            onClick={handleReset}
                                        >
                                            Reset
                                        </button>
                                    </form>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>
                                                            Subscription Name
                                                        </th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subscription
                                                        ? subscription.map(
                                                              (data) => (
                                                                  <tr
                                                                      key={
                                                                          data._id
                                                                      }
                                                                  >
                                                                      <td>
                                                                          {
                                                                              data.id
                                                                          }
                                                                      </td>
                                                                      <td>
                                                                          {
                                                                              data.name
                                                                          }
                                                                      </td>
                                                                      <td>
                                                                          {data.status ==
                                                                          1 ? (
                                                                              <span class="badge badge-pill badge-success">
                                                                                  Active
                                                                              </span>
                                                                          ) : (
                                                                              <span class="badge badge-pill badge-danger">
                                                                                  Inactive
                                                                              </span>
                                                                          )}
                                                                      </td>
                                                                  </tr>
                                                              )
                                                          )
                                                        : "IDs Not Found"}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-light">
                            <button
                                type="submit"
                                className="btn btn-primary mr-1"
                                disabled={isDisable}
                                onClick={prevPagination}
                            >
                                Previous
                            </button>
                            <span className="btn btn-primary mr-1">
                                {currentPage}
                            </span>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isDisable}
                                onClick={nextPagination}
                            >
                                Next
                            </button>
                        </div>
                    </section>
                    
                </div>
            </LoadingOverlay>
        </>
    );
};

export default SubscriptionList;
