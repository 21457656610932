import React from 'react';
import { Message } from '../../../../helpers/Constant';
import { fetchParentUserList, fetchSubUsers } from '../../../../api/requests/user/UserRequest';
import { createNewAlert } from '../../../../api/requests/alert-service/AlertServiceRequest';
import Select from "react-select";
import {
  uploadPDFFile,
  uploadExcelFile,
  uploadPPTFile,
  uploadZIPFile,
  uploadDocFile,
} from '../../../../api/requests/file/FileRequest';
import { LoadingContext } from '../../../../../App';
import Notification from '../../../helpers/Notification';


const AlertServiceAdd = () => {
    const [user, setUser] = React.useState([]);
    const [subUser, setSubUser] = React.useState([]);
    const [selectedSubUser, setSelectedSubUser] = React.useState([]);
    const [validated, setValidated] = React.useState(false);
    const [notification, setNotification] = React.useState(false);
    const [notificationFlag, setNotificationFlag] = React.useState('');
    const [notificationMessage, setNotificationMessage] = React.useState('');
    const [isAllSubUsers, setIsAllSubUsers] = React.useState(false);
    const [pdfPercent, setPDFPercent] = React.useState(0);
    const [zipPercent, setZIPPercent] = React.useState(0);
    const [pptPercent, setPPTPercent] = React.useState(0);
    const [excelPercent, setExcelPercent] = React.useState(0);
    const [pdfUploading, setPdfUploading] = React.useState(false);
    const [zipUploading, setZipUploading] = React.useState(false);
    const [pptUploading, setPptUploading] = React.useState(false);
    const [excelUploading, setExcelUploading] = React.useState(false);
    const [title, setTitle] = React.useState(null);
    const [client, setClient] = React.useState(0);
    const [details, setDetails] = React.useState(null);
    const [pdf, setPdf] = React.useState(null);
    const [zip, setZip] = React.useState(null);
    const [ppt, setPpt] = React.useState(null);
    const [excel, setExcel] = React.useState(null);
    React.useEffect(() => {
        const loadClient = async () => {
        const user = await fetchParentUserList()
            if (user) {
                setTimeout(() => {
                
                const list = user.data.map(each => {
                    return {
                        label: `${each.full_name.first_name} ${each.full_name.last_name} - (${each.email})`,
                        value: each.uid
                    }
                })
                setUser(list)
                }, 2000)
            }
        }
        loadClient()
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (client === 0) {
            alert('Please select an client first.')
            return false
        }
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.stopPropagation()
            setNotification(false)
        } else {
            const alert = {
                title,
                client,
                details,
                pdf,
                zip,
                ppt,
                excel,
                subusers: isAllSubUsers ? 0 : selectedSubUser.map(each => each.value)
            }
            const result = await createNewAlert(alert)
            setTimeout(() => {
                setNotification(false)
            }, 5000)

            setTimeout(() => { }, 2500)
            if (result.code === 200) {
                setNotification(true)
                setNotificationFlag('success')
                setNotificationMessage(result.message)
            }
            else {
                setNotification(true)
                setNotificationFlag('danger')
                setNotificationMessage(result.message)
            }
        }
        setValidated(true)
        window.scrollTo(0,0)
    }

    const changeClient = async (selected) => {
        setClient(selected.value)
        const subusers = await fetchSubUsers(selected.value)
        const list = subusers?.data?.map(each => {
            return {
                value: each.uid,
                label: each.email
            }    
        })
        setSubUser(list)
        setSelectedSubUser([])
    }

    const changeSubUser = (value) => {
        setSelectedSubUser(value);
    }

    const handleAllSubUserSelection = (e) => {
        setIsAllSubUsers(e.target.checked);
        setSelectedSubUser([]);
    }

    const changeFileHandler = async (event) => {
        let file_object = event.target.files[0];
        if (client === 0) {
            alert('Please select an client first.');
            event.target.value = null;
            return false;
        }

        if (file_object === undefined) {
            return false;
        } else {
            let file_name_arr = typeof file_object === 'object' ? file_object.name.split('.') : '';
            let file_type = file_name_arr[file_name_arr.length - 1];
            if (file_type === 'pdf') {
                setPdfUploading(true);
                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setPDFPercent(percent);
                    },
                }
                const result = await uploadPDFFile(file_object, options, client)
                if (result.status === 'success') {
                    setPdf(result.data.filename);
                    setTimeout(() => {
                        setPdfUploading(false)
                    }, 5000);
                } else {
                    event.target.value = null;
                    setPDFPercent(0);
                    alert("File couldn't uploaded. please try again.");
                }
            } else if (file_type === 'zip') {
                setZipUploading(true);
                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setZIPPercent(percent);
                    },
                }
                const result = await uploadZIPFile(file_object, options, client)
                if (result.status === 'success') {
                    setZip(result.data.filename);
                    setTimeout(() => {
                        setZipUploading(false)
                    }, 5000);
                } else {
                    event.target.value = null
                    setZIPPercent(0)
                    alert("File couldn't uploaded. please try again.")
                }
            } else if (file_type === 'ppt' || file_type === 'pptx') {
                setPptUploading(true);
                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent
                        let percent = Math.floor((loaded * 100) / total)
                        setPPTPercent(percent)
                    },
                }
                const result = await uploadPPTFile(file_object, options, client)
                if (result.status === 'success') {
                    setPpt(result.data.filename)
                    setTimeout(() => {
                        setPptUploading(false)
                    }, 5000);
                } else {
                    event.target.value = null
                    setPPTPercent(0)
                    alert("File couldn't uploaded. please try again.")
                }
            } else if (file_type === 'xls' || file_type === 'xlsx') {
                setExcelUploading(true);
                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent
                        let percent = Math.floor((loaded * 100) / total)
                        setExcelPercent(percent)
                    },
                }
                const result = await uploadExcelFile(file_object, options, client)
                if (result.status === 'success') {
                    setExcel(result.data.filename)
                    setTimeout(() => {
                        setExcelUploading(false)
                    }, 5000);
                } else {
                    event.target.value = null
                    setExcel(0)
                    alert("File couldn't uploaded. please try again.")
                }
            } else if (file_type === 'doc' || file_type === 'docx') {
                const result = await uploadDocFile(file_object)
                console.log('RESULT DOC :: ', result)
            } else {
                console.log('Unknown file format. please select a valid file')
            }
        }
    }

    return(
    <> 

        <header className="page-header">
            <div className="d-flex align-items-center">
                <div className="mr-auto">
                    <h1 className="separator">Upload Alert/File</h1>
                    <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <span><i className="icon dripicons-home"></i></span>
                            </li>
                            <li className="breadcrumb-item">
                                <span>Alert Service</span>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Send New Alert
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </header>

        <section className="page-content container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <h5 className="card-header">Alert Service Form</h5>
                        <div className="card-body">
                            <div className="col-md-8">
                            <form onSubmit={handleSubmit}>   
                                { notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}

                                <div className="card-body">

                                    <div className="form-group">
                                        <label htmlFor="title">Alert Title</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="title"
                                            name="title"
                                            value={title}
                                            required
                                            onChange={(e) => {
                                                setTitle(e.target.value)
                                            }}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="inputAdvCode">Select Parent User</label>
                                        <Select
                                            options={user}
                                            onChange={changeClient}
                                            required
                                            placeholder="Select Parent User"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="inputAdvCode">Select Sub Users</label>
                                        <Select
                                            required
                                            isMulti
                                            options={subUser}
                                            value={selectedSubUser}
                                            onChange={changeSubUser}
                                            placeholder="Select Sub Users"
                                            isDisabled={isAllSubUsers}
                                        />
                                    </div>

                                    <div className='form-group'>
                                        <input type="checkbox" id='subusersall' onChange={handleAllSubUserSelection} />
                                        <label htmlFor='subusersall' style={{ marginLeft: "0.5rem" }}>Select All Sub Users</label>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="details">Alert Details</label>
                                        <textarea rows="3" className="form-control" id="details" value={details} required
                                            onChange={(e) => {
                                                setDetails(e.target.value)
                                            }}
                                        />
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="details">Upload PDF</label>
                                                <input 
                                                    className="form-control" 
                                                    type="file" 
                                                    id="pdf"
                                                    name="pdf"
                                                    accept="application/pdf"
                                                    onChange={changeFileHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            {pdfPercent > 0 ? (
                                                <div className="progress m-t-10">
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated"
                                                        role="progressbar"
                                                        style={{width: pdfPercent+"%"}}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100">
                                                        {pdfPercent}%
                                                    </div>
                                                </div>
                                            ) : ('')}
                                        </div>
                                        <div className="col-sm-1">
                                            {pdfPercent >= 100 ? <div className="btn btn-danger btn-sm">X</div> : ''}
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="details">Upload Zip</label>
                                                <input 
                                                    className="form-control" 
                                                    type="file" 
                                                    id="zip"
                                                    name="zip"
                                                    accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                                                    onChange={changeFileHandler}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            {zipPercent > 0 ? (
                                                <div className="progress m-t-10">
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated"
                                                        role="progressbar"
                                                        style={{width: zipPercent+"%"}}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100">
                                                        {zipPercent}%
                                                    </div>
                                                </div>
                                            ) : ('')}
                                        </div>
                                        <div className="col-sm-1">
                                            {zipPercent >= 100 ? <div className="btn btn-danger btn-sm">X</div> : ''}
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="details">Upload PPT</label>
                                                <input 
                                                    className="form-control" 
                                                    type="file" 
                                                    id="ppt"
                                                    name="ppt"
                                                    onChange={changeFileHandler}
                                                    accept=".ppt, .pptx"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            {pptPercent > 0 ? (
                                                <div className="progress m-t-10">
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated"
                                                        role="progressbar"
                                                        style={{width: pptPercent+"%"}}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100">
                                                        {pptPercent}%
                                                    </div>
                                                </div>
                                            ) : ('')}
                                        </div>
                                        <div className="col-sm-1">
                                            {pptPercent >= 100 ? <div className="btn btn-danger btn-sm">X</div> : ''}
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="details">Upload Excel</label>
                                                <input 
                                                    className="form-control" 
                                                    type="file" 
                                                    id="excel"
                                                    name="excel"
                                                    onChange={changeFileHandler}
                                                    accept=".xlsx, .xls"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            {excelPercent > 0 ? (
                                                <div className="progress m-t-10">
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated"
                                                        role="progressbar"
                                                        style={{width: excelPercent+"%"}}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100">
                                                        {excelPercent}%
                                                    </div>
                                                </div>
                                            ) : ('')}
                                        </div>
                                        <div className="col-sm-1">
                                            {excelPercent >= 100 ? <div className="btn btn-danger btn-sm">X</div> : ''}
                                        </div>
                                    </div>

                                </div>

                                <div className="card-footer bg-light">
                                    <button 
                                        type="submit" 
                                        disabled={ 
                                            (!pdfUploading && !zipUploading && !pptUploading && !excelUploading) ? false : true
                                        }
                                        className="btn btn-primary">
                                            Submit
                                    </button>
                                </div>

                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default AlertServiceAdd
