const EndPoints = {
  get_alert_service: 'alert-service/get',
  get_alert_services_by_date: 'alert-service/get-alerts-by-date',
  add_alert_service: 'alert-service/add',
  create_alert: 'alert-service/create',
  create_other_service: 'alert-service/create-other-service',
  admin_login: 'admin/admin/login',
  admin_check_password: 'admin/admin/change-password',
  create_company: 'admin/company/create',
  company_all_list: 'admin/company/all',
  company_search_q: 'admin/company/search_q',
  company_search_by: 'admin/company/search_by',
  update_company: 'admin/company/update-company',
  company_list: 'admin/company/list',
  tvc_company_list: 'admin/company-tvc/list',
  tv_media_list: 'admin/tv-media/list',
  tv_ad_type_list: 'admin/tv-ad-type/list',
  rdc_company_list: 'admin/company-rdc/list',
  rdc_media_list: 'admin/media-rdc/list',
  rdc_ad_type_list: 'admin/rdc-ad-type/list',
  print_online_company_list: 'admin/company-print-online/list',
  search_rdc_company: 'admin/company-rdc/search_q',
  search_rdc_brand: 'admin/brand-rdc/brand-by-company',
  search_rdc_product_type: 'admin/product-type-rdc/search_q',
  search_rdc_product_type_by_name: 'admin/product-type-rdc/search',
  search_rdc_product: 'admin/product-rdc/get-product-by-type',
  search_rdc_product_by_name: 'admin/product-rdc/search_q',
  search_tvc_company: 'admin/company-tvc/search_q',
  search_tvc_brand_by_company: 'admin/brand-tvc/find',
  search_tv_company: 'admin/tv-company/search_q',
  search_tv_brand: 'admin/tv-brand/search_q',
  search_tv_brand_by_name: 'admin/tv-brand/search',
  search_tv_product_type: 'admin/tv-product-type/search_q',
  search_tv_product: 'admin/tv-product/search_q',
  search_tv_product_by_name: 'admin/tv-product/search',
  search_print_online_company: 'admin/company-print-online/search_q',
  search_print_online_brand: 'admin/print-online-brand/find',
  search_print_online_brand_by_name: 'admin/print-online-brand/search',
  search_print_online_product_type: 'admin/product-type/print-online/search_q',
  search_news_company: 'admin/company/news/search/by_name',
  search_news_category: 'admin/category/news/search/by_name',
  search_news_sub_category: 'admin/sub-category/news/search/by_category',
  search_news_sub_category_by_name: 'admin/sub-category/news/search/by_name',
  print_online_product_find: 'admin/product/print-online/find',
  print_online_product_search_by_name: 'admin/product/print-online/search',
  get_subscription: 'admin/subscription/get-all',
  search_subscription: "admin/subscription/search_q",
  add_subscriber: 'admin/subscriber/add',
  search_subscriber: "admin/subscriber/group/search_q",
  list_subscriber: 'admin/subscriber/list',
  subscriber_group: 'admin/subscriber/group-list',
  subscriber_group_users: 'admin/subscriber/group-users',
  subscriber_remove_user: 'admin/subscriber/remove-user',
  subscriber_group_fetch_by_id: 'admin/subscriber/fetch-subscriber-group-by-id',
  subscriber_group_unselected_users: 'admin/subscriber/group-sub-users-unselect-list',
  add_more_user_to_subscriber: 'admin/subscriber/add-more-user-to-subscriber',
  user_email_validate: 'admin/user/email-validation',
  user_add: 'admin/user/create',
  user_update: 'admin/user/update',
  user_list: 'admin/user/get-all',
  user_data_permission: 'admin/data-access/create',
  advanced_user_data_permisssion: 'admin/data-access/create/advanced',
  advanced_user_data_permisssion_update: 'admin/data-access/update/advanced',
  access_permission_list: 'admin/data-access/list',
  access_permisssion_list_advanced: 'admin/data-access/list-advanced',
  access_permission_list_by_uid: 'admin/data-access/list-advanced/by-uid',
  access_permission_list_by_doc_id: 'admin/data-access/list-advanced/by-id',
  access_permission_search_advanced: 'admin/data-access/search/advanced',
  access_permission_delete_advanced: 'admin/data-access/delete/advanced',
  access_permission_details: 'admin/data-access/details',
  access_permission_delete: 'admin/data-access/delete',
  access_permission_update: 'admin/data-access/update',
  access_permission_search: 'admin/data-access/search',
  parent_user_list: 'admin/user/fetch-parent-user',
  parent_user_change_password: 'admin/user/change-password',
  search_parent_user: 'admin/user/search-parent-user',
  user_delete: 'admin/user/delete',
  user_get: 'admin/user/get-user-by-id',
  user_update_data: 'admin/user/update-user-info',
  user_sub_add: 'admin/user/create/sub-user',
  get_user_sub_id: 'admin/user/get-sub-users-by-parent-id',
  get_user_activities_history: 'admin/user/activities-history',
  get_user_service_access: 'admin/service/get',
  change_user_service_access: 'admin/service/change',
  media_search_q: 'media/search_q',
  media_rdc_search: 'admin/media-rdc/search',
  media_tvc_search: 'admin/media-tvc/search',
  product_search_q: 'product/search_q',
  radio_product_search_q: 'product/radio/search_q',
  print_online_product_search_q: 'product/print-online/search_q',
  brand_list: 'admin/brand/list',
  tvc_brand_list: 'admin/brand-tvc/list',
  rdc_brand_list: 'admin/brand-rdc/list',
  brand_search_q: 'admin/brand/search_q',
  radio_brand_search_q: 'brand/radio/search_q',
  print_online_brand_search_q: 'brand/print-online/search_q',
  rdc_brand_search_q: 'admin/brand-rdc/search_q',
  tvc_brand_search: 'admin/brand-tvc/search_q',
  sub_brand_search_q: 'sub-brand/search_q',
  sub_brand_radio_search_q: 'sub-brand/radio/search_q',
  sub_brand_printOnline_search_q: 'sub-brand/print-online/search_q',
  product_type_search_q: 'product-type/search_q',
  radio_product_type_search_q: 'radio/product-type/search_q',
  print_online_product_type_search_q: 'print-online/product-type/search_q',
  data_service_add: 'data-service/add',
  radio_data_service_add: 'radio/data-service/add',
  print_online_data_service_add: 'print-online/data-service/add',
  data_service_radio_add: 'data-service/radio/add',
  data_service_search: 'data-service/search_q',
  upload_zip: 'upload/file/zip',
  upload_pdf: 'upload/file/pdf',
  upload_excel: 'upload/file/excel',
  upload_ppt: 'upload/file/ppt',
  upload_doc: 'upload/file/doc',
  streaming_admin_add: 'admin/streaming/admin-add',
  streaming_admin_list: 'admin/streaming/admin-list',
}
export default EndPoints