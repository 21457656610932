import { getAdminToken } from '../../helpers/Cookies'
const axios = require('axios')
const customInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: ( getAdminToken() ) ? getAdminToken() : ''
  },
})

export default customInstance
