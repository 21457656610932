export const attributes = {
    start_date: {
        label: "Access Start Date",
        
    },
    end_date: {
        label: "Access End Date",
    },
    child_user: {
        label: "Select Child User",
        checkbox: [
            {
                value: 1,
                key: "all_child_user",
                label: "All Child User"
            }
        ],
    },
    select_company: {
        label: "Select Company",
    },
    select_category: {
        label: "Select Category",
        checkbox: [
            {
                value: 1,
                key: "category_all",
                label: "All Category"
                
            }
        ]
    },
    select_sub_category: {
        label: "Select Sub-Category",
        checkbox: [
            {
                value: 1,
                key: "sub_category_all", 
                label: "All Sub-Category"
            },
            {
                value: 2,
                key: "category_all_sub_category", 
                label: "All Sub-Category of Selected Category"
            },
            {
                value: 3,
                key: "select_category_and_sub_category_only", 
                label: "Select Category & Sub-Category Only"
            }
        ]
    }
}