import AxiosInstance from '../Index';
import EndPoints from '../../end-points/EndPoints';
import { SuccessResponse, ErrorResponse } from '../../helpers/Request';
export const brandList = async (page_no) => {
    const params = new URLSearchParams()
    params.append('page', page_no)
    const result = await AxiosInstance.post(EndPoints.brand_list, params)
                    .then((res) => {
                        return SuccessResponse(res)
                    })
                    .catch((err) => {
                        return ErrorResponse(err)
                    })
    return result
}
export const searchBrandByQuery = async (search_q) => {
    const params = new URLSearchParams()
    params.append('search_q', search_q)
    const result = await AxiosInstance.post(EndPoints.brand_search_q, params)
        .then((res) => {
            return SuccessResponse(res)
        })
        .catch((err) => {
            return ErrorResponse(err)
        })
    return result
}
 