export const binarySearch = (array, item, low, high) => {
  while (low <= high) {
    let mid = Math.floor((low + high) / 2)
    if (array[mid].id == item) {
      return array[mid]
    } else if (array[mid].id < item) {
      low = mid + 1
    } else {
      high = mid - 1
    }
  }
  return -1
}
  