import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import Notification from '../../../helpers/Notification'
import LoadingOverlay from 'react-loading-overlay'
import "react-datepicker/dist/react-datepicker.css"
import { fetchParentUserList, permissionStore } from '../../../../api/requests/user/UserRequest'
import { 
    searchTVBrandByCompany, 
    searchTvCompany,
    searchTvcProductType,
    findTvcProduct,
    tvcMediaList,
    tvcAdTypeList
} from '../../../../api/requests/company/TvcRequest'
import {
    searchRdcCompany,
    searchRdcBrandByCompany,
    searchRdcProductType,
    findRdcProduct,
    rdcMediaList,
    rdcAdTypeList
} from '../../../../api/requests/company/RdcRequest'
import {
    searchPrintOnlineCompany,
    searchPrintOnlineBrand,
    searchPrintOnlineProductType,
    findPrintOnlineProduct
} from '../../../../api/requests/company/PrintOnlineRequest'
import { 
    searchNewsCompany,
    searchNewsCategory,
    searchNewsSubCategory
} from '../../../../api/requests/company/NewsCompanyRequest'

const state_object = {
    company: [],
    company_all: false,
    from_date: '',
    to_date: '',
    setAll: false
}

const state_object_extends = {
    ...state_object,
    brand: [],
    brand_all: false,
    media: [],
    media_all: false,
}

const state_object_media_extends = {
    ...state_object,
    media: [],
    media_all: false
}
const common_state = {
    access_type: 0,
    from_date: '',
    to_date: '',
    setAll: false
}
const inner_state = {
    items:[],
    selected_all: false,
}
const state_object_of_ad_print = {
    ...common_state,
    company: {...inner_state},
    brand: {...inner_state},
    product_type: {...inner_state},
    product: {...inner_state}
}
const state_object_of_tvc_ad = {
    ...common_state,
    company: {...inner_state},
    brand: {...inner_state},
    product_type: {...inner_state},
    product: {...inner_state},
    media: { ...inner_state },
    ad_type: { ...inner_state }
}
const state_object_of_rdc_ad = {
    ...common_state,
    company: {...inner_state},
    brand: {...inner_state},
    product_type: {...inner_state},
    product: {...inner_state},
    media: { ...inner_state },
    ad_type: { ...inner_state }
}
const state_object_news = {
    ...common_state,
    company: {...inner_state},
    category: {...inner_state},
    sub_category: {...inner_state}
}
const PermissionAdd = () => {
    let [loading, setLoading] = React.useState(false)
    const [notification, setNotification] = React.useState(false)
    const [notificationFlag, setNotificationFlag] = React.useState('')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const [permissionType, setPermissionType] = React.useState(0)
    const [selectedValue, setSelectedValue] = React.useState([])
    const [brandSelectedValue, setBrandSelectedValue] = React.useState([])
    const [mediaSelectedValue, setMediaSelectedValue] = React.useState([])
    const [productTypeSelectedValue, setProductTypeSelectedValue] = React.useState([])
    const [newsCategory, setNewsCategory] = React.useState([])
    const [newsSubCategory, setNewsSubCategory] = React.useState([])
    const [showPrint, setShowPrint] = React.useState(false)
    const [showOnline, setShowOnline] = React.useState(false)
    const [showRDC, setShowRDC] = React.useState(false)
    const [showTVC, setShowTVC] = React.useState(false)
    const [showTVNews, setShowTVNews] = React.useState(false)
    const [showRadioNews, setShowRadioNews] = React.useState(false)
    const [showPrintNews, setShowPrintNews] = React.useState(false)
    const [showOnlineNews, setShowOnlineNews] = React.useState(false)
    const [adPrintFormData, setAdPrintFormData] = React.useState(state_object)
    const [adPrintData, setAdPrintData] = React.useState(state_object_of_ad_print)
    const [adRdcFormData, setAdRdcFormData] = React.useState(state_object)
    const [adTvcFormData, setAdTvcFormData] = React.useState(state_object)
    const [adRdcData, setAdRdcData] = React.useState(state_object_of_rdc_ad)
    const [adTvcData, setAdTvcData] = React.useState(state_object_of_tvc_ad)
    const [adOnlineFormData, setAdOnlineFormData] = React.useState(state_object)
    const [adOnlineData, setAdOnlineData] = React.useState(state_object_of_ad_print)
    const [newsPrintFormData, setNewsPrintFormData] = React.useState(state_object_news)
    const [newsOnlineFormData, setNewsOnlineFormData] = React.useState(state_object_news)
    const [newsTvFormData, setNewsTvFormData] = React.useState(state_object_news)
    const [newsRadioFormData, setNewsRadioFormData] = React.useState(state_object_news)
    const [brandList, setBrandList] = React.useState([])
    const [selectedBrand, setSelectedBrand] = React.useState([])
    const [productList, setProductList] = React.useState([])
    const [selectedProduct, setSelectedProduct] = React.useState([])
    const [mediaList, setMediaList] = React.useState([])
    const [selectedMedia, setSelectedMedia] = React.useState([])
    const [adTypeList, setAdTypeList] = React.useState([])
    const [selectedAdType, setSelectedAdType] = React.useState([])
    const [subCategoryList, setSubCategoryList] = React.useState([])
    const [parentUser, setParentUser] = React.useState([])
    const [modalCompany, setModalCompany] = React.useState([])
    const [modalCompanySelected, setModalCompanySelected] = React.useState([])
    const [expireStartDate, setExpireStartDate] = React.useState(new Date())
    const [expireEndDate, setExpireEndDate] = React.useState(new Date())
    const [modalFromDate, setModalFromDate] = React.useState(new Date())
    const [modalToDate, setModalToDate] = React.useState(new Date())
    const [modalTitle, setModalTitle] = React.useState('')
    const [show, setShow] = React.useState(false)
    const[showBrand, setShowBrand] = React.useState(false)
    const[showMedia, setShowMedia] = React.useState(false)
    const[companyChecked, setCompanyChecked] = React.useState(false)
    const[brandChecked, setBrandChecked] = React.useState(false)
    const[mediaChecked, setMediaChecked] = React.useState(false)
    const[productTypeChecked, setProductTypeChecked] = React.useState(false)
    const[productChecked, setProductChecked] = React.useState(false)
    const[adTypeChecked, setAdTypeChecked] = React.useState(false)
    const[newsSubCategoryChecked, setNewsSubCategoryChecked] = React.useState(false)
    const checkedPermissionChange = (type) => {
        if(type === 0){
            setPermissionType(0)
        }
        else if(type === 1) {
            setPermissionType(1)
        }
        else if(type === 2) {
            setPermissionType(2)
        }
    }
    const checkedAllOption = (option) => {
        if(option.category === 'print_ad') {
            if(option.type === 'company') setCompanyChecked(!companyChecked)
            if(option.type === 'brand') setBrandChecked(!brandChecked)
            if(option.type === 'product_type') setProductTypeChecked(!productTypeChecked)
            if(option.type === 'product') setProductChecked(!productChecked)
        } 
        else if(option.category === 'tv_ad') {
            if(option.type === 'company') setCompanyChecked(!companyChecked)
            if(option.type === 'brand') setBrandChecked(!brandChecked)
            if(option.type === 'product_type') setProductTypeChecked(!productTypeChecked)
            if(option.type === 'product') setProductChecked(!productChecked)
            if(option.type === 'ad_type') setAdTypeChecked(!adTypeChecked)
            if(option.type === 'media') setMediaChecked(!mediaChecked)
        }
        else if(['tv_news', 'radio_news', 'print_news', 'online_news'].includes(option.category)) {
            if(option.type === 'sub_category') setNewsSubCategoryChecked(!newsSubCategoryChecked)
        }
    }
    const stateControl = (action, section, data='') => {
        if (section === 'print_ad') {
            if (action === 'modal_closed') {
                setShowPrint(false)
                setAdPrintFormData(prevState => ({
                    ...prevState,
                    company: selectedValue,
                    company_all: companyChecked
                }))
                setAdPrintData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: companyChecked,
                    },
                    brand: {
                        ...prevState.brand,
                        items: selectedBrand,
                        selected_all: brandChecked,
                    },
                    product_type: {
                        ...prevState.product_type,
                        items: productTypeSelectedValue,
                        selected_all: productTypeChecked,
                    },
                    product: {
                        ...prevState.product,
                        items: selectedProduct,
                        selected_all: productChecked,
                    }
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(adPrintData.access_type)
                setSelectedValue(adPrintData.company.items)
                setCompanyChecked(adPrintData.company.selected_all)
                setSelectedBrand(adPrintData.brand.items)
                setBrandChecked(adPrintData.brand.selected_all)
                setProductTypeSelectedValue(adPrintData.product_type.items)
                setSelectedProduct(adPrintData.product.items)
                setShowPrint(true)
            }
            else if(action === 'show_active'){
                return `btn ${adPrintFormData.company_all || adPrintFormData.company.length > 0 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
        else if(section === 'online_ad'){
            if(action === 'modal_closed'){
                setShowOnline(false)
                setAdOnlineFormData(prevState => ({
                    ...prevState,
                    company: selectedValue,
                    company_all: companyChecked
                }))

                setAdOnlineData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: companyChecked,
                    },
                    brand: {
                        ...prevState.brand,
                        items: selectedBrand,
                        selected_all: brandChecked,
                    },
                    product_type: {
                        ...prevState.product_type,
                        items: productTypeSelectedValue,
                        selected_all: productTypeChecked,
                    },
                    product: {
                        ...prevState.product,
                        items: selectedProduct,
                        selected_all: productChecked,
                    }
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(adOnlineData.access_type)
                setSelectedValue(adOnlineData.company.items)
                setCompanyChecked(adOnlineData.company.selected_all)
                setSelectedBrand(adOnlineData.brand.items)
                setBrandChecked(adOnlineData.brand.selected_all)
                setProductTypeSelectedValue(adOnlineData.product_type.items)
                setSelectedProduct(adOnlineData.product.items)
                setShowOnline(true)
            }
            else if(action === 'show_active'){
                return `btn ${adOnlineFormData.company_all || adOnlineFormData.company.length > 0 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
        else if(section === 'rdc_ad'){
            if(action === 'modal_closed'){
                setShowRDC(false)
                setAdRdcFormData(prevState => ({
                    ...prevState,
                    brand: brandSelectedValue,
                    company: selectedValue,
                    media: mediaSelectedValue,
                    company_all: companyChecked,
                }))
                setAdRdcData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: companyChecked,
                    },
                    brand: {
                        ...prevState.brand,
                        items: selectedBrand,
                        selected_all: brandChecked,
                    },
                    product_type: {
                        ...prevState.product_type,
                        items: productTypeSelectedValue,
                        selected_all: productTypeChecked,
                    },
                    product: {
                        ...prevState.product,
                        items: selectedProduct,
                        selected_all: productChecked,
                    },
                    media: {
                        ...prevState.media,
                        items: selectedMedia,
                        selected_all: mediaChecked,
                    },
                    ad_type: {
                        ...prevState.ad_type,
                        items: selectedAdType,
                        selected_all: adTypeChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(adRdcData.access_type)
                setSelectedValue(adRdcData.company.items)
                setCompanyChecked(adRdcData.company.selected_all)
                setSelectedBrand(adRdcData.brand.items)
                setBrandChecked(adRdcData.brand.selected_all)
                setProductTypeSelectedValue(adRdcData.product_type.items)
                setSelectedProduct(adRdcData.product.items)
                setSelectedMedia(adRdcData.media.items)
                setMediaChecked(adRdcData.media.selected_all)
                setSelectedAdType(adRdcData.ad_type.items)
                setAdTypeChecked(adRdcData.ad_type.selected_all)
                setShowRDC(true)
            }
            else if(action === 'show_active'){
                return `btn ${adRdcFormData.company_all || adRdcFormData.company.length > 0 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
        else if(section === 'tvc_ad'){
            if(action === 'modal_closed'){
                setShowTVC(false)
                setAdTvcFormData(prevState => ({
                    ...prevState,
                    brand: brandSelectedValue,
                    company: selectedValue,
                    media: mediaSelectedValue,
                    company_all: companyChecked
                }))
                setAdTvcData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: companyChecked,
                    },
                    brand: {
                        ...prevState.brand,
                        items: selectedBrand,
                        selected_all: brandChecked,
                    },
                    product_type: {
                        ...prevState.product_type,
                        items: productTypeSelectedValue,
                        selected_all: productTypeChecked,
                    },
                    product: {
                        ...prevState.product,
                        items: selectedProduct,
                        selected_all: productChecked,
                    },
                    media: {
                        ...prevState.media,
                        items: selectedMedia,
                        selected_all: mediaChecked,
                    },
                    ad_type: {
                        ...prevState.ad_type,
                        items: selectedAdType,
                        selected_all: adTypeChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(adTvcData.access_type)
                setSelectedValue(adTvcData.company.items)
                setCompanyChecked(adTvcData.company.selected_all)
                setSelectedBrand(adTvcData.brand.items)
                setBrandChecked(adTvcData.brand.selected_all)
                setProductTypeSelectedValue(adTvcData.product_type.items)
                setSelectedProduct(adTvcData.product.items)
                setSelectedMedia(adTvcData.media.items)
                setMediaChecked(adTvcData.media.selected_all)
                setSelectedAdType(adTvcData.ad_type.items)
                setAdTypeChecked(adTvcData.ad_type.selected_all)
                setShowTVC(true)
            }
            else if(action === 'show_active'){
                return `btn ${  adTvcFormData.company_all || 
                                adTvcFormData.company.length > 0 || 
                                adTvcFormData.access_type === 2 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
        else if(section === 'tv_news'){
            if(action === 'modal_closed'){
                setShowTVNews(false)
                setNewsTvFormData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: 0,
                    },
                    category: {
                        ...prevState.category,
                        items: newsCategory,
                        selected_all: 0,
                    },
                    sub_category: {
                        ...prevState.category,
                        items: newsSubCategory,
                        selected_all: newsSubCategoryChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(newsTvFormData.access_type)
                setSelectedValue(newsTvFormData.company.items)
                setNewsCategory(newsTvFormData.category.items)
                setNewsSubCategory(newsTvFormData.sub_category.items)
                setModalFromDate(newsTvFormData.from_date)
                setModalToDate(newsTvFormData.to_date)
                setCompanyChecked(newsTvFormData.company_all)
                setNewsSubCategoryChecked(newsTvFormData.sub_category.selected_all)
                setShowTVNews(true)
                setShowMedia(true)
            }
            else if(action === 'show_active'){
                return `btn ${newsTvFormData.setAll || newsTvFormData.company.items.length > 0 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
        else if(section === 'radio_news'){
            if(action === 'modal_closed'){
                setShowRadioNews(false)
                setNewsRadioFormData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: 0,
                    },
                    category: {
                        ...prevState.category,
                        items: newsCategory,
                        selected_all: 0,
                    },
                    sub_category: {
                        ...prevState.category,
                        items: newsSubCategory,
                        selected_all: newsSubCategoryChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(newsRadioFormData.access_type)
                setSelectedValue(newsRadioFormData.company.items)
                setNewsCategory(newsRadioFormData.category.items)
                setNewsSubCategory(newsRadioFormData.sub_category.items)
                setModalFromDate(newsRadioFormData.from_date)
                setModalToDate(newsRadioFormData.to_date)
                setCompanyChecked(newsRadioFormData.company_all)
                setNewsSubCategoryChecked(newsRadioFormData.sub_category.selected_all)
                setShowRadioNews(true)
                setShowMedia(true)
            }
            else if(action === 'show_active'){
                return `btn ${newsRadioFormData.setAll || newsRadioFormData.company.items.length > 0 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
        else if(section === 'print_news'){
            if(action === 'modal_closed'){
                setShowPrintNews(false)
                setNewsPrintFormData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: 0,
                    },
                    category: {
                        ...prevState.category,
                        items: newsCategory,
                        selected_all: 0,
                    },
                    sub_category: {
                        ...prevState.category,
                        items: newsSubCategory,
                        selected_all: newsSubCategoryChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(newsPrintFormData.access_type)
                setSelectedValue(newsPrintFormData.company.items)
                setNewsCategory(newsPrintFormData.category.items)
                setNewsSubCategory(newsPrintFormData.sub_category.items)
                setModalFromDate(newsPrintFormData.from_date)
                setModalToDate(newsPrintFormData.to_date)
                setCompanyChecked(newsPrintFormData.company_all)
                setNewsSubCategoryChecked(newsPrintFormData.sub_category.selected_all)
                setShowPrintNews(true)
                setShowMedia(true)
            }
            else if(action === 'show_active'){
                return `btn ${newsPrintFormData.setAll || newsPrintFormData.company.items.length > 0 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
        else if(section === 'online_news'){
            if(action === 'modal_closed'){
                setShowOnlineNews(false)
                setNewsOnlineFormData(prevState => ({
                    ...prevState,
                    access_type: permissionType,
                    company: {
                        ...prevState.company,
                        items: selectedValue,
                        selected_all: 0,
                    },
                    category: {
                        ...prevState.category,
                        items: newsCategory,
                        selected_all: 0,
                    },
                    sub_category: {
                        ...prevState.category,
                        items: newsSubCategory,
                        selected_all: newsSubCategoryChecked,
                    },
                }))
            }
            else if(action === 'modal_open'){
                setPermissionType(newsOnlineFormData.access_type)
                setSelectedValue(newsOnlineFormData.company.items)
                setNewsCategory(newsOnlineFormData.category.items)
                setNewsSubCategory(newsOnlineFormData.sub_category.items)
                setModalFromDate(newsOnlineFormData.from_date)
                setModalToDate(newsOnlineFormData.to_date)
                setCompanyChecked(newsOnlineFormData.company_all)
                setNewsSubCategoryChecked(newsOnlineFormData.sub_category.selected_all)
                setShowOnlineNews(true)
            }
            else if(action === 'show_active'){
                return `btn ${newsOnlineFormData.setAll || newsOnlineFormData.company.items.length > 0 ?
                                'btn-success' :
                                'btn-danger btn-outline'}`
            }
        }
    }
    const handleClose = (section) => {
        setShow(false)
        stateControl('modal_closed', section, modalCompanySelected)
        setModalCompanySelected([])
        setSelectedValue([])
        setShowBrand(false)
        setShowMedia(false)
    }

    const handleShow = (section) => {
        setShow(true)
        setModalTitle(section)
        stateControl('modal_open', section)
    }

    const selectValue = (tag, event) => {
        if(tag === 'company') {
            setSelectedCompany(event.value)
        } else if(tag === 'parent') {
            setSelectedParent(event)
        }
    }
    const handleInputChange = value => {}
    const handleChange = value => {
        setSelectedValue(value)
    }
    const handleChangeBrand = value => {
        setSelectedBrand(value)
    }
    const handleChangeProduct = value => {
        setSelectedProduct(value)
    }
    const handleChangeMedia = value => {
        setSelectedMedia(value)
    }
    const handleChangeAdType = value => {
        setSelectedAdType(value)
    }
    const handleChangeProductType = value => {
        setProductTypeSelectedValue(value)
    }
    const handleChangeCategory = value => {
        setNewsCategory(value)
    }
    const handleChangeSubCategory = value => {
        setNewsSubCategory(value)
    }
    const wordSplit = (search) => {
        let word = search.split(" ")
        let word_len = word.length
        if(word[word_len-1].length == 0)
            return true
        else
            return false
    }
    const loadPrintOnlineCompanyOption = async (search) => {
        if(wordSplit(search)){
            return await searchPrintOnlineCompany(search)
        }
    }
    const loadPrintOnlineBrandOption = async () => {
        const data = await searchPrintOnlineBrand({company: selectedValue})
        let brand = data.map((item) => {
            return {value: item.brand_id, label: item.brand_name}
        })
        setBrandList(brand)
    }
    const loadPrintOnlineProductOption = async () => {
        const data = await findPrintOnlineProduct({product_type: productTypeSelectedValue})
        let product = data.map((item) => {
            return {value: item.id, label: item.product_name}
        })
        setProductList(product)
    }
    const loadPrintOnlineProductType = async (search) => {
        if(wordSplit(search)){
            return await searchPrintOnlineProductType(search)
        }
    }
    const loadTVCompanyOption = async (search) => {
        if(wordSplit(search)){
            return await searchTvCompany(search)
        }
    }
    const loadMediaOption = async () => {
        const data = await tvcMediaList()
        let media = data.map((item) => {
            return {value: item.media_id, label: item.media_name}
        })
        setMediaList(media)
    }
    const loadAdTypeOption = async () => {
        const data = await tvcAdTypeList()
        let ad_type = data.map((item) => {
            return {value: item.ad_type_id, label: item.ad_type_name}
        })
        setAdTypeList(ad_type)
    }
    const loadTVBrandOption = async () => {
        if(selectedValue.length <= 0){
            console.log('You have to select company first.')
        }
        const data = await searchTVBrandByCompany({company: selectedValue})
        let brand = data.map((item) => {
            return {value: item.brand_id, label: item.brand_name}
        })
        setBrandList(brand)
    }
    const loadTvcProductType = async (search) => {
        if(wordSplit(search)){
            return await searchTvcProductType(search)
        }
    }   
    const loadTvcProductOption = async () => {
        if(productTypeSelectedValue.length <= 0){
            console.log('You have to select product type first.')
        }
        const data = await findTvcProduct({product_type: productTypeSelectedValue})
        let product = data.map((item) => {
            return {value: item.product_id, label: item.product_name}
        })
        setProductList(product)
    }
    const loadRdcCompanyOption = async (search) => {
        if(wordSplit(search)){
            return await searchRdcCompany(search)
        }
    }
    const loadRdcBrandOption = async () => {
        if(selectedValue.length <= 0){
            console.log('You have to select company first.')
        }
        const data = await searchRdcBrandByCompany({company: selectedValue})
        let brand = data.map((item) => {
            return {value: item.brand_id, label: item.brand_name}
        })
        setBrandList(brand)
    }
    const loadRdcProductType = async (search) => {
        if(wordSplit(search)){
            return await searchRdcProductType(search)
        }
    }
    const loadRdcProductOption = async () => {
        if(productTypeSelectedValue.length <= 0){
            console.log('You have to select product type first.')
        }
        const data = await findRdcProduct({product_type: productTypeSelectedValue})
        let product = data.map((item) => {
            return {value: item.product_id, label: item.product_name}
        })
        setProductList(product)
    }
    const displayAccessBtn = (btn) => {
        return stateControl('show_active', btn, '')
    }
    const loadRdcMediaOption = async () => {
        const data = await rdcMediaList()
        let media = data.map((item) => {
            return {value: item.media_id, label: item.media_name}
        })
        setMediaList(media)
    }
    const loadRdcAdTypeOption = async () => {
        const data = await rdcAdTypeList()
        let ad_type = data.map((item) => {
            return {value: item.ad_type_id, label: item.ad_type_name}
        })
        setAdTypeList(ad_type)
    }
    const loadNewsCompanyOption = async (search) => {
        if(wordSplit(search)){
            return await searchNewsCompany(search)
        }
    }
    const loadNewsCategoryOption = async (search) => {
        if(wordSplit(search)){
            return await searchNewsCategory(search)
        }
    }

    const loadNewsSubCategoryOption = async (search) => {
        if(newsCategory.length <= 0){
            console.log('You have to select category first.')
        }
        const data = await searchNewsSubCategory(newsCategory)
        let sub_cat = data.map((item) => {
            return {value: item.id, label: item.name}
        })
        setSubCategoryList(sub_cat)
    }
    const [selectedCompany, setSelectedCompany] = React.useState(-1)
    const [selectedParent, setSelectedParent] = React.useState([])
    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        if(selectedParent.length == 0) {
            setNotification(true)
            setNotificationFlag('danger')
            setNotificationMessage("Must be selected an user.")
            setTimeout(() => {
                setNotification(false)
                setLoading(false)
            }, 1500)
            return false
        }        

        const permission_data = {
            parent_id: selectedParent,
            start_date: expireStartDate,
            end_date: expireEndDate,
            ad_print: adPrintData,
            ad_online: adOnlineData,
            ad_rdc: adRdcData,
            ad_tvc: adTvcData,
            news_print: newsPrintFormData,
            news_online: newsOnlineFormData,
            news_rdc: newsRadioFormData,
            news_tvc: newsTvFormData
        }
        const result = await permissionStore(permission_data)
        if (result) {
            setNotification(true)
            setNotificationMessage(result.message)
            if(result.status === 'fail'){
              setNotificationFlag('danger')
            } else {
              setNotificationFlag('success')
            }
            setTimeout(() => {
                setLoading(false)
                setNotification(false)
            }, 800)
        }
        return false
    }

    React.useEffect(() => {
        const fetchParentUser = async () => {
            let opt = []
            setLoading(true)
            const result = await fetchParentUserList()
            if (result) {
                result.data.map((data, index) => {
                    let arr = {
                        value: data.uid,
                        label: `${data.full_name.first_name} ${data.full_name.last_name} -- ${data.email}`,
                    }
                    opt.push(arr)
                })
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            }
            setParentUser(opt)
        }
        fetchParentUser()
    }, [])

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Loading Data ... ...'>
                <div style={{minHeight:90+'vh'}}>

                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Data Permission</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <span><i className="icon dripicons-home"></i></span>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span>Data Service</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Data Access Permission
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <h5 className="card-header">
                                        Set Data Access Permission
                                    </h5>

                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            {notification ? <Notification flag={notificationFlag} msg={notificationMessage} /> : ''}
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="parent">Select Parent ID</label>
                                                            <Select
                                                                options={parentUser}
                                                                onChange={(e) => selectValue('parent', e) }
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="firstName">Data Access Start Date</label>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={expireStartDate}
                                                                onChange={(date) => setExpireStartDate(date)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="firstName">Data Access End Date</label>
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={expireEndDate}
                                                                onChange={(date) => setExpireEndDate(date)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="dashed"/>

                                                <div className="row">
                                                    <div className="col-md-2">
                                                        Control For - Ad
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown ">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('tvc_ad')}>
                                                                TV
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('tvc_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('rdc_ad')}>
                                                                Radio
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('rdc_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('print_ad')}>
                                                                    Print
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('print_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('online_ad')}>
                                                                Online
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('online_ad')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="dashed"/>

                                                <div className="row">
                                                    <div className="col-md-2">
                                                        Control For - News
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown ">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('tv_news')}>
                                                                TV
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('tv_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('radio_news')}>
                                                                Radio
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('radio_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('print_news')}>
                                                                Print
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('print_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="btn-group dropdown">
                                                            <button
                                                                type="button"
                                                                className={displayAccessBtn('online_news')}>
                                                                Online
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark dropdown-toggle dropdown-toggle-split"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <span
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShow('online_news')}>
                                                                    Edit Settings
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card-footer bg-light">
                                                <button type="submit" className="btn btn-primary">Save Access</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <Modal show={show} onHide={() => handleClose(modalTitle)}>
                            <Modal.Header>
                                <Modal.Title>
                                    Access Permission For - {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            { showTVC ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Company - Brand
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="type_product"
                                                                checked={(permissionType === 1)?true:false}
                                                                onChange={() => checkedPermissionChange(1)}
                                                            />
                                                            <label className="form-check-label" htmlFor="type_product">
                                                                Product Type - Product
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2)?true:false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="company">Select Company</label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadTVCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="brand">
                                                                Select Brand
                                                            </label>
                                                            <Select
                                                                value={selectedBrand}
                                                                onFocus={loadTVBrandOption}
                                                                defaultValue={selectedBrand}
                                                                onChange={handleChangeBrand}
                                                                options={brandList}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_brand"
                                                                checked={brandChecked}
                                                                onChange={() => checkedAllOption({category:'tv_ad',type:'brand'})}
                                                                disabled={(permissionType !== 0)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_brand">
                                                                All Brand
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product_type">
                                                                Select Product Type
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={productTypeSelectedValue}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                getOptionLabel={e => e.product_type_name}
                                                                getOptionValue={e => e.product_type_id}
                                                                loadOptions={loadTvcProductType}
                                                                onChange={handleChangeProductType}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product">
                                                                Select Product
                                                            </label>
                                                            <Select
                                                                onFocus={loadTvcProductOption}
                                                                defaultValue={selectedProduct}
                                                                onChange={handleChangeProduct}
                                                                options={productList}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_product"
                                                                checked={productChecked}
                                                                onChange={() => checkedAllOption({category:'tv_ad',type:'product'})}
                                                                disabled={(permissionType !== 1)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_product">
                                                                All Product
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="media">
                                                                Select Media
                                                            </label>
                                                            <Select
                                                                onFocus={loadMediaOption}
                                                                defaultValue={selectedMedia}
                                                                onChange={handleChangeMedia}
                                                                options={mediaList}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_media"
                                                                checked={mediaChecked}
                                                                onChange={() => checkedAllOption({category:'tv_ad',type:'media'})}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_media">
                                                                All Media
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="media">
                                                                Select Ad Type
                                                            </label>
                                                            <Select
                                                                onFocus={loadAdTypeOption}
                                                                defaultValue={selectedAdType}
                                                                onChange={handleChangeAdType}
                                                                options={adTypeList}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_ad_type"
                                                                checked={adTypeChecked}
                                                                onChange={() => checkedAllOption({category:'tv_ad',type:'ad_type'})}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_ad_type">
                                                                All Ad Type
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            ) : ('') }

                                            { showRDC ? (
                                                <div className="row">

                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Company - Brand
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="type_product"
                                                                checked={(permissionType === 1) ? true : false}
                                                                onChange={() => checkedPermissionChange(1)}
                                                            />
                                                            <label className="form-check-label" htmlFor="type_product">
                                                                Product Type - Product
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2) ? true : false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All
                                                            </label>
                                                        </div>

                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="company">Select Company</label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadRdcCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="brand">
                                                                Select Brand
                                                            </label>
                                                            <Select
                                                                value={selectedBrand}
                                                                onFocus={loadRdcBrandOption}
                                                                defaultValue={selectedBrand}
                                                                onChange={handleChangeBrand}
                                                                options={brandList}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_brand"
                                                                checked={brandChecked}
                                                                onChange={() => checkedAllOption({category:'tv_ad',type:'brand'})}
                                                                disabled={(permissionType !== 0) ? true : false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_brand">
                                                                All Brand
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product_type">
                                                                Select Product Type
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={productTypeSelectedValue}
                                                                isDisabled={(permissionType !== 1) ? true : false}
                                                                getOptionLabel={e => e.product_type_name}
                                                                getOptionValue={e => e.product_type_id}
                                                                loadOptions={loadRdcProductType}
                                                                onChange={handleChangeProductType}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product">
                                                                Select Product
                                                            </label>
                                                            <Select
                                                                onFocus={loadRdcProductOption}
                                                                defaultValue={selectedProduct}
                                                                onChange={handleChangeProduct}
                                                                options={productList}
                                                                isDisabled={(permissionType !== 1) ? true : false}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_product"
                                                                checked={productChecked}
                                                                onChange={() => checkedAllOption({category:'tv_ad',type:'product'})}
                                                                disabled={(permissionType !== 1) ? true : false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_product">
                                                                All Product
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="media">
                                                                Select Media
                                                            </label>
                                                            <Select
                                                                onFocus={loadRdcMediaOption}
                                                                defaultValue={selectedMedia}
                                                                onChange={handleChangeMedia}
                                                                options={mediaList}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_media"
                                                                checked={mediaChecked}
                                                                onChange={() => checkedAllOption({category:'tv_ad',type:'media'})}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_media">
                                                                All Media
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="media">
                                                                Select Ad Type
                                                            </label>
                                                            <Select
                                                                onFocus={loadRdcAdTypeOption}
                                                                defaultValue={selectedAdType}
                                                                onChange={handleChangeAdType}
                                                                options={adTypeList}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_ad_type"
                                                                checked={adTypeChecked}
                                                                onChange={() => checkedAllOption({category:'tv_ad',type:'ad_type'})}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_ad_type">
                                                                All Ad Type
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            ) : ('') }

                                            { showPrint ? (
                                                <div className="row">

                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0)?true:false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Company - Brand
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="type_product"
                                                                checked={(permissionType === 1)?true:false}
                                                                onChange={() => checkedPermissionChange(1)}
                                                            />
                                                            <label className="form-check-label" htmlFor="type_product">
                                                                Product Type - Product
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2)?true:false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadPrintOnlineCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="brand">
                                                                Select Brand
                                                            </label>
                                                            <Select
                                                                value={selectedBrand}
                                                                onFocus={loadPrintOnlineBrandOption}
                                                                defaultValue={selectedBrand}
                                                                onChange={handleChangeBrand}
                                                                options={brandList}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_brand"
                                                                checked={brandChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'brand'})}
                                                                disabled={(permissionType !== 0)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_brand">
                                                                Select All Brand
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product_type">
                                                                Select Product Type
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={productTypeSelectedValue}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                getOptionLabel={e => e.product_type}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadPrintOnlineProductType}
                                                                onChange={handleChangeProductType}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product">
                                                                Select Product
                                                            </label>
                                                            <Select
                                                                onFocus={loadPrintOnlineProductOption}
                                                                defaultValue={selectedProduct}
                                                                onChange={handleChangeProduct}
                                                                options={productList}
                                                                isDisabled={(permissionType !== 1)?true:false}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_product"
                                                                checked={productChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'product'})}
                                                                disabled={(permissionType !== 1)?true:false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_product">
                                                                All Product
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>
                                                </div>
                                            ) : ('') }

                                            { showOnline ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Company - Brand
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="type_product"
                                                                checked={(permissionType === 1) ? true : false}
                                                                onChange={() => checkedPermissionChange(1)}
                                                            />
                                                            <label className="form-check-label" htmlFor="type_product">
                                                                Product Type - Product
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2) ? true : false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadPrintOnlineCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="brand">
                                                                Select Brand
                                                            </label>
                                                            <Select
                                                                value={selectedBrand}
                                                                onFocus={loadPrintOnlineBrandOption}
                                                                defaultValue={selectedBrand}
                                                                onChange={handleChangeBrand}
                                                                options={brandList}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_brand"
                                                                checked={brandChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'brand'})}
                                                                disabled={(permissionType !== 0) ? true : false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_brand">
                                                                Select All Brand
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product_type">
                                                                Select Product Type
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={productTypeSelectedValue}
                                                                isDisabled={(permissionType !== 1) ? true : false}
                                                                getOptionLabel={e => e.product_type}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadPrintOnlineProductType}
                                                                onChange={handleChangeProductType}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product">
                                                                Select Product
                                                            </label>
                                                            <Select
                                                                onFocus={loadPrintOnlineProductOption}
                                                                defaultValue={selectedProduct}
                                                                onChange={handleChangeProduct}
                                                                options={productList}
                                                                isDisabled={(permissionType !== 1) ? true : false}
                                                                isMulti
                                                            />
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all_product"
                                                                checked={productChecked}
                                                                onChange={() => checkedAllOption({category:'print_ad',type:'product'})}
                                                                disabled={(permissionType !== 1) ? true : false}
                                                            />
                                                            <label className="form-check-label" htmlFor="all_product">
                                                                All Product
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>
                                                </div>
                                            ) : ('') }

                                            { showTVNews ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Custom
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2) ? true : false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadNewsCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Category
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={newsCategory}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.name}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadNewsCategoryOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChangeCategory}
                                                                isMulti
                                                            />
                                                        </div>

                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Sub-Category
                                                            </label>
                                                            <Select
                                                                value={newsSubCategory}
                                                                onFocus={loadNewsSubCategoryOption}
                                                                onChange={handleChangeSubCategory}
                                                                defaultValue={newsSubCategory}
                                                                options={subCategoryList}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                isMulti
                                                            />
                                                           
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="sub_category"
                                                                    checked={newsSubCategoryChecked}
                                                                    onChange={() => checkedAllOption({category:'tv_news',type:'sub_category'})}
                                                                    disabled={(permissionType !== 0)?true:false}
                                                                />
                                                                <label className="form-check-label" htmlFor="sub_category">
                                                                    All sub-category
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            ) : ('') }


                                            { showRadioNews ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Custom
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2) ? true : false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadNewsCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Category
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={newsCategory}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.name}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadNewsCategoryOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChangeCategory}
                                                                isMulti
                                                            />
                                                        </div>

                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Sub-Category
                                                            </label>
                                                            <Select
                                                                value={newsSubCategory}
                                                                onFocus={loadNewsSubCategoryOption}
                                                                onChange={handleChangeSubCategory}
                                                                defaultValue={newsSubCategory}
                                                                options={subCategoryList}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                isMulti
                                                            />
                                                           
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="sub_category"
                                                                    checked={newsSubCategoryChecked}
                                                                    onChange={() => checkedAllOption({category:'radio_news',type:'sub_category'})}
                                                                    disabled={(permissionType !== 0)?true:false}
                                                                />
                                                                <label className="form-check-label" htmlFor="sub_category">
                                                                    All sub-category
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            ) : ('') }


                                            { showPrintNews ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Custom
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2) ? true : false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadNewsCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Category
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={newsCategory}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.name}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadNewsCategoryOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChangeCategory}
                                                                isMulti
                                                            />
                                                        </div>

                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Sub-Category
                                                            </label>
                                                            <Select
                                                                value={newsSubCategory}
                                                                onFocus={loadNewsSubCategoryOption}
                                                                onChange={handleChangeSubCategory}
                                                                defaultValue={newsSubCategory}
                                                                options={subCategoryList}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                isMulti
                                                            />
                                                           
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="sub_category"
                                                                    checked={newsSubCategoryChecked}
                                                                    onChange={() => checkedAllOption({category:'print_news',type:'sub_category'})}
                                                                    disabled={(permissionType !== 0)?true:false}
                                                                />
                                                                <label className="form-check-label" htmlFor="sub_category">
                                                                    All sub-category
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            ) : ('') }

                                            { showOnlineNews ? (
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Set Permission By
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="company_brand"
                                                                checked={(permissionType === 0) ? true : false}
                                                                onChange={() => checkedPermissionChange(0)}
                                                            />
                                                            <label className="form-check-label" htmlFor="company_brand">
                                                                Custom
                                                            </label>
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="all"
                                                                checked={(permissionType === 2) ? true : false}
                                                                onChange={() => checkedPermissionChange(2)}
                                                            />
                                                            <label className="form-check-label" htmlFor="all">
                                                                All Data
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Company
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={selectedValue}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.company_name}
                                                                getOptionValue={e => e.company_id}
                                                                loadOptions={loadNewsCompanyOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChange}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Category
                                                            </label>
                                                            <AsyncSelect
                                                                cacheOptions
                                                                defaultOptions
                                                                value={newsCategory}
                                                                isDisabled={(permissionType !== 0)?true:false}
                                                                getOptionLabel={e => e.name}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={loadNewsCategoryOption}
                                                                onInputChange={handleInputChange}
                                                                onChange={handleChangeCategory}
                                                                isMulti
                                                            />
                                                        </div>

                                                    </div>

                                                    <span className="draw-hr"/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="company">
                                                                Select Sub-Category
                                                            </label>
                                                            <Select
                                                                value={newsSubCategory}
                                                                onFocus={loadNewsSubCategoryOption}
                                                                onChange={handleChangeSubCategory}
                                                                defaultValue={newsSubCategory}
                                                                options={subCategoryList}
                                                                isDisabled={(permissionType !== 0) ? true : false}
                                                                isMulti
                                                            />
                                                           
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="sub_category"
                                                                    checked={newsSubCategoryChecked}
                                                                    onChange={() => checkedAllOption({category:'online_news',type:'sub_category'})}
                                                                    disabled={(permissionType !== 0)?true:false}
                                                                />
                                                                <label className="form-check-label" htmlFor="sub_category">
                                                                    All sub-category
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            ) : ('') }

                                        </div>
                                    </div>

                                </div>
                            </Modal.Body>
                            
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => handleClose(modalTitle)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => handleClose(modalTitle)}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>

                        </Modal>

                    </section>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default PermissionAdd